// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div[class*="newspacesetup"] div[class*="p-sidebar-content"] {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

div[class*="newspacesetup"] span[class*="p-dropdown-label"] {
  font-size: small;
}
div[class*="customer-insights-header"] div button:first-child span {
  color: #495057;
  margin-left: 1vw;
}

div[class*="customer-insights-header"] button:first-child span {
  color: #495057;
  font-weight: 400;
  margin-left: 0.3vw;
}

.customerInsightsTable {
  .p-datatable-header {
    background-color: #ffffff;
    border-top: 0px;
    padding-inline: 0px;
  }
}

.newspacesetup {
  .p-dropdown .p-dropdown-trigger {
    width: 2rem;
  }
}
div[class*="newspacesetup"] span[class*="p-dropdown-label"] {
  padding-inline: 0.5rem !important;
}

.data-panel {
  display: none;
  .p-dialog {
    border-radius: 20rem !important;
  }
}

.custom-colorpicker {
  border-radius: 0.5rem;
  padding: 0.2rem;
  border: 1px solid #e0e0e0;
  input {
    border-radius: 60%;
  }
}

.p-colorpicker-overlay-panel {
  display: none !important;
}

.p-chips .p-chips-multiple-container {
  width: 100% !important;
  .p-chips-token {
    margin-bottom: 0.5rem !important;
    font-size: small !important;
    font-weight: 300 !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Spaces/index.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,8BAA8B;AAChC;;AAEA;EACE,gBAAgB;AAClB;AACA;EACE,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE;IACE,yBAAyB;IACzB,eAAe;IACf,mBAAmB;EACrB;AACF;;AAEA;EACE;IACE,WAAW;EACb;AACF;AACA;EACE,iCAAiC;AACnC;;AAEA;EACE,aAAa;EACb;IACE,+BAA+B;EACjC;AACF;;AAEA;EACE,qBAAqB;EACrB,eAAe;EACf,yBAAyB;EACzB;IACE,kBAAkB;EACpB;AACF;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,sBAAsB;EACtB;IACE,gCAAgC;IAChC,2BAA2B;IAC3B,2BAA2B;EAC7B;AACF","sourcesContent":["div[class*=\"newspacesetup\"] div[class*=\"p-sidebar-content\"] {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n}\n\ndiv[class*=\"newspacesetup\"] span[class*=\"p-dropdown-label\"] {\n  font-size: small;\n}\ndiv[class*=\"customer-insights-header\"] div button:first-child span {\n  color: #495057;\n  margin-left: 1vw;\n}\n\ndiv[class*=\"customer-insights-header\"] button:first-child span {\n  color: #495057;\n  font-weight: 400;\n  margin-left: 0.3vw;\n}\n\n.customerInsightsTable {\n  .p-datatable-header {\n    background-color: #ffffff;\n    border-top: 0px;\n    padding-inline: 0px;\n  }\n}\n\n.newspacesetup {\n  .p-dropdown .p-dropdown-trigger {\n    width: 2rem;\n  }\n}\ndiv[class*=\"newspacesetup\"] span[class*=\"p-dropdown-label\"] {\n  padding-inline: 0.5rem !important;\n}\n\n.data-panel {\n  display: none;\n  .p-dialog {\n    border-radius: 20rem !important;\n  }\n}\n\n.custom-colorpicker {\n  border-radius: 0.5rem;\n  padding: 0.2rem;\n  border: 1px solid #e0e0e0;\n  input {\n    border-radius: 60%;\n  }\n}\n\n.p-colorpicker-overlay-panel {\n  display: none !important;\n}\n\n.p-chips .p-chips-multiple-container {\n  width: 100% !important;\n  .p-chips-token {\n    margin-bottom: 0.5rem !important;\n    font-size: small !important;\n    font-weight: 300 !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
