import { useEffect, useState, useRef } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { Timeline } from 'primereact/timeline';
import { Calendar } from 'primereact/calendar';
import { Divider } from 'primereact/divider';
import { Toast } from 'primereact/toast';
import TemplateRuleNotificationSidebar from './TemplateRuleNotificationSidebar';
import { useWorkspaceApi } from 'hooks/workspaceConfig';
import GetUsersList from 'hooks/getUsers';

const NotificationTemplateSidebar = ({ visible, setVisible, setManageTemplatesVisible }) => {
  const [allUserData, setAllUserData] = useState({
    userList: [],
    knowledgeSpace: []
  });
  const [allRuleData, setAllRuleData] = useState([]);
  const [updateSidebarData, setUpdateSidebarData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [getNamespaceAsset, setNamespaceAsset] = useState({
    namespace_id: "",
    asset_id: ""
  });
  const toast = useRef(null);
  const { getAllRootKnowledgeSpaces } = useWorkspaceApi();
  const { getUsers } = GetUsersList();

  const fetchUsers = async () => {
    try {
      const userData = await getUsers();
      const userList = userData?.results?.users
        ?.filter(item => item.firstname && item.firstname.trim() !== "")
        .map(item => ({
          label: `${item.firstname} ${item.lastname}`,
          value: item.id,
        })) || [];
      return userList;
    } catch (error) {
      console.error('Error fetching users:', error);
      return [];
    }
  };

  const fetchKnowledgeSpace = async () => {
    try {
      const knowledgeSpaceData = await getAllRootKnowledgeSpaces();
      return knowledgeSpaceData;
    } catch (error) {
      console.error('Error fetching knowledge space:', error);
      return [];
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      // Fetch both knowledge space and users data concurrently
      const [knowledgeSpaceData, usersList] = await Promise.all([
        fetchKnowledgeSpace(),
        fetchUsers()
      ]);
      
      const knowledgeSpaceOptions = knowledgeSpaceData.map(item => ({
        label: item.space_name,
        value: item.id
      }));

      setAllUserData({
        userList: usersList,
        knowledgeSpace: knowledgeSpaceOptions
      });

      // Mock data for rules - replace with your actual API call
      const rulesData = await fetchRules();
      setAllRuleData(rulesData);

      setNamespaceAsset({
        namespace_id: "mock_namespace",
        asset_id: "mock_asset"
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSuccess = (success) => {
    if (success) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: success
      });
      fetchData();
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Something Went Wrong Try After Some Time"
      });
    }
  };

  const handleClose = () => {
    setVisible(false);
    setManageTemplatesVisible(true);
  };

  const fetchRules = async () => {
    // Replace with your actual API call
    try {
      const response = await fetch('/api/rules');
      const data = await response.json();
      return data.map(rule => ({
        label: `Rule ID ${rule.id}`,
        value: rule.id
      }));
    } catch (error) {
      console.error('Error fetching rules:', error);
      return [];
    }
  };

  return (
    <>
      <TemplateRuleNotificationSidebar
        visible={visible}
        setVisible={setVisible}
        onSuccess={handleSuccess}
        allUserData={allUserData}
        allRuleData={allRuleData}
        updateSidebarData={updateSidebarData}
        setUpdateSidebarData={setUpdateSidebarData}
        getNamespaceAsset={getNamespaceAsset}
      />
      <Toast ref={toast} position="top-right" />
    </>
  );
};

export default NotificationTemplateSidebar;