import { useEffect, useState, useMemo, useRef } from "react";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
import { NEIO_ORG } from "constants";
import { getSession } from "utils/utils";

function useApi() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { keycloak } = useKeycloak();
  const { authenticated, token, tokenParsed } = keycloak;
  const session = getSession();
  const organization = useMemo(() => {
    const orgData = keycloak?.tokenParsed?.organization;
    if (orgData && typeof orgData === 'object' && Object.keys(orgData).length > 0) {
      const [firstOrgId] = Object.keys(orgData);
      const orgName = orgData[firstOrgId]?.name;
      return orgName || '';
    }

    if (session?.tokenParsed?.groups) {
      const sessionGroups = session.tokenParsed.groups;
      if (!Array.isArray(sessionGroups) || sessionGroups.length === 0) {
        return '';
      }
      return sessionGroups[0].replace(/^\//, '') || '';
    }

    if (tokenParsed?.groups) {
      const groups = tokenParsed.groups;
      if (!Array.isArray(groups) || groups.length === 0) {
        return '';
      }
      return groups[0].replace(/^\//, '') || '';
    }

    return '';
  }, [keycloak?.tokenParsed, session?.tokenParsed, tokenParsed?.groups]);
  const abortControllerRef = useRef(null);
  useEffect(() => {
    if (session) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${session.token}`;
    } else {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
    axios.defaults.headers.common["Organization"] = organization;
  }, [authenticated, token, organization, session]);

  const fetchData = async (
    url,
    method = "GET",
    body = null,
    contentType = { "Content-Type": "application/json" },
    onUploadProgress = null,
  ) => {
    abortControllerRef.current = new AbortController();

    try {
      setLoading(true);
      setError(null);

      const options = {
        method,
        body: body != null ? JSON.stringify(body) : null,
      };
      const response = await axios(url, {
        headers: contentType,
        method: method,
        data: body,
        onUploadProgress: onUploadProgress || null,
        signal: abortControllerRef.current.signal, // Use the AbortController's signal
      });

      const result = response.data;
      setData(result);
      return result;
    } catch (err) {
      if (err?.response?.status === 401) {
        // keycloak.logout();
      } else if (axios.isCancel(err)) {
        console.log("Request canceled:", err.message);
        setError("Request was cancelled");
        return { cancelled: true };
      }
      setError(err?.response?.data);
      return Promise.reject(err);
    } finally {
      setLoading(false);
    }
  };

  const get = (url, body) => fetchData(url, "GET", body);
  const post = (url, body) => fetchData(url, "POST", body);
  const del = (url, body) => fetchData(url, "DELETE", body);
  const patch = (url, body) => fetchData(url, "PATCH", body);
  const put = (url, body) => fetchData(url, "PUT", body);
  const upload = (url, body, contentType, onUploadProgress) =>
    fetchData(url, "POST", body, contentType, onUploadProgress);

  const cancelRequest = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
  };

  return { data, loading, error, get, post, del, patch, put, upload, cancelRequest };
}
export default useApi;
