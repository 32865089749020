import React, { useState, useRef, useEffect } from "react";
import { useDebounce } from "primereact/hooks";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { Menu } from "primereact/menu";
import { MegaMenu } from "primereact/megamenu";
import InnerSidebar from "layouts/InnerSidebar";
import { extractErrorMessage, isObjectWithNonEmptyValues } from "../StructuredSources/utils";
import SourcePulseBreadcrumb from "components/SourcePulseBreadcrumb";
import { GET_SOURCE_DETAILS } from "../StructuredSources/pulseApi";
import PlusIcon from "./PlusIcon";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { formatDateWithTime, toTitleCase } from "utils/helper";
import AddRuleSidebar from "../StreamDetailsRuleList/addRuleSidebar";
import HighAlertInfo from "./icons/HighAlertInfo";
import MediumAlertInfo from "./icons/MediumAlertInfo";
import LowAlertInfo from "./icons/LowAlertInfo";
import "./index.css";
import CriticalAlertInfo from "./icons/CriticalAlertInfo";
import { usePulseApi } from "hooks/pulseConfig";
import { Dialog } from "primereact/dialog";
import useAliasConfiguration from "hooks/useAliasConfiguration";
import ManageTemplatesSidebar from "./ManageTemplatesSidebar";

const StreamDataSourcesDetails = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [loading, setLoading] = useState(false); // Simulate loading state
  const [innerLoading, setInnerLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10); // Items per page
  const [selectedRow, setSelectedRow] = useState(null);
  const [viewSource, setViewSource] = useState(null);
  const [isDeleteModalOpen, setDeleteModal] = useState(false);
  const [isFilerModalOpen, setFilterModal] = useState(false);
  const [isControlModalOpen, setControlModal] = useState(false);
  const [isUsingInModalOpen, setUsingInModal] = useState(false);
  const [sourceData, setSourceData] = useState(GET_SOURCE_DETAILS);
  const [sortField, setSortField] = useState("updatedAt desc"); // Assigning GET_SOURCES to state
  const [searchText, searchDebouncedValue, setSearchInput] = useDebounce("", 300);
  const sortFieldSplit = sortField.split(" ");
  const tableSortField = sortField ? sortFieldSplit[0] : "";
  const tableSortOrder = sortField ? (sortFieldSplit[1] === "asc" ? 1 : -1) : 0;
  const [filterOption, setFilterOption] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [visible, setVisible] = useState(false);
  const [error, setError] = useState("");
  const [getNamespaceAsset, setNamespaceAsset] = useState({
    namespace_id: "",
    asset_id: "",
  });
  const [activeTab, setActiveTab] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [templatesSidebarVisible, setTemplatesSidebarVisible] = useState(false);

  const toast = useRef(null);
  const { getPulseSourceDetailList } = usePulseApi();

  useEffect(() => {
    setInnerLoading(true);
    fetchData();
  }, [page, limit, searchDebouncedValue, filterOption, sortField, searchDebouncedValue]);

  const onPageChange = (event) => {
    const newPage = event.first;
    const newLimit = event.rows;
    const actualPage = Math.floor(newPage / newLimit);
    setPage(actualPage);
    setLimit(newLimit);
  };

  const onSort = (event) => {
    setSortField(`${event.sortField} ${event.sortOrder === 1 ? "asc" : "desc"}`);
  };

  const handleDeleteSourceId = () => {
    setDeleteModal(false); // Close modal after deleting
    toast.current.show({
      severity: "success",
      summary: "Deleted",
      detail: "Source deleted successfully",
    });
  };

  const fetchData = async () => {
    try {
      const payload = {
        page: page + 1, // Convert 0-based to 1-based page number
        limit: limit,
        search: searchDebouncedValue || "",
        sort: sortField,
        dataSourceId: location.state.source.id,
      };
      if (searchDebouncedValue) {
        payload.filters = {
          stream_id: searchDebouncedValue,
        };
      }
      const response = await getPulseSourceDetailList(payload);
      setSourceData(response);
      setFilterModal(false);
    } catch (error) {
      if (isFilerModalOpen) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: extractErrorMessage(error),
          life: 3000,
        });
        return;
      }
      setError(error);
    } finally {
      setInnerLoading(false);
      setLoading(false);
    }
  };

  // Table header
  const header = (
    <div className="flex flex-col bg-[#F8F9FA]">
      <div className="flex flex-wrap align-items-center justify-end gap-2 border-t-2 border-[#e5e7eb] py-3 pr-[1rem]">
        <Button
          label={`${innerLoading ? "Refreshing" : "Refresh"}`}
          icon="pi pi-refresh"
          className={`refresh-streams pl-5 pr-5 pt-3 pb-3 place-self-end ${
            innerLoading ? "text-gray-700 " : "text-[#495057]"
          }`}
          style={{ fontWeight: "400", fontStyle: "normal" }}
          disabled={innerLoading}
          iconPos="left"
          size="large"
          severity="info"
          // outlined
          onClick={() => {
            setInnerLoading(true);
            // const isFilter = isObjectWithNonEmptyValues(filterOption);
            fetchData();
          }}
        />
        <div
          className="flex align-items-center justify-center"
          style={{ border: "1px solid #ced4da", backgroundColor: "white", borderRadius: "5px" }}
        >
          <span className="p-input-icon-left">
            <i className="pi pi-search pl-3 font-extralight text-[#6C757D] opacity-80" />
          </span>
          <InputText
            type="search"
            className="w-[270px] pl-10 pr-5 pt-3 pb-3 placeholder:font-normal text-[#CED4DA] placeholder:text-[#ced4da] ml-5 pulse-search-component border-0"
            placeholder="Search Asset"
            value={searchText}
            onChange={(e) => setSearchInput(e.target.value)}
          />
        </div>
      </div>

      {/* <hr className="w-full mt-5 border-t border-[#CED4DA]" /> */}
    </div>
  );

  const start = (
    <div className="flex justify-between items-center w-full">
      <span className="font-semibold text-xl text-[#191A1C]">{location?.state?.source?.name}</span>
      {/* <Button 
        label="Manage Templates" 
        className="border border-blue-400 text-blue-500 bg-white font-semibold"
        onClick={() => setTemplatesSidebarVisible(true)}
      /> */}
    </div>
  );

  const megaMenu = () => {
    return <MegaMenu start={start} className="bg-transparent border-none border-noround py-3" />;
  };

  const renderStatus = (status) => {
    switch (status) {
      case "in-progress":
        return (
          <span className="border-2 border-[#D97706] text-[#D97706] bg-[#fffbf3] pt-2 pb-2 pl-3 pr-3 rounded-3xl text-xs whitespace-nowrap">
            In Progress
          </span>
        );
      case "active":
      case "running":
        return (
          <span className="border-2 border-[#326FD1] text-[#326FD1] bg-[#f5f9ff] pt-2 pb-2 pl-3 pr-3 rounded-3xl text-xs whitespace-nowrap">
            {toTitleCase(status)}
          </span>
        );
      case "inactive":
      case "pending":
        return (
          <span className="border-2 border-[#D97706] text-[#D97706] bg-[#fffbf3] pt-2 pb-2 pl-3 pr-3 rounded-3xl text-xs whitespace-nowrap">
            {toTitleCase(status)}
          </span>
        );
      case "error":
      case "stopped":
        return (
          <div className="flex flex-row max-w-20 items-baseline border-2 border-[#B32B23] text-[#B32B23] bg-[#fff6f5] pt-2 pb-2 pl-3 pr-3 rounded-3xl text-xs">
            {toTitleCase(status)}
            <div
              className="inactive-notification"
              data-pr-tooltip="This source has encountered an error"
              data-pr-at="left-0 top-25"
              data-pr-my="left center-2"
              data-pr-classname="custom-tooltip-inactive"
            >
              {status === "error" && (
                <svg
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="mt-[5px] ml-1.5 cursor-pointer"
                >
                  <path
                    d="M5 5L5 7.25M5 3.33228V3.3125M0.5 5C0.5 2.51472 2.51472 0.5 5 0.5C7.48528 0.5 9.5 2.51472 9.5 5C9.5 7.48528 7.48528 9.5 5 9.5C2.51472 9.5 0.5 7.48528 0.5 5Z"
                    stroke="#BC1C1C"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              )}
            </div>
            <Tooltip target=".inactive-notification" />
          </div>
        );
      case "completed":
        return (
          <span className="border-2 bg-green-50 border-green-600 text-green-600 pt-2 pb-2 pl-3 pr-3 rounded-3xl text-xs whitespace-nowrap">
            {toTitleCase(status)}
          </span>
        );
      default:
        return status;
    }
  };

  const handleClick = (item) => {
    setNamespaceAsset({
      namespace_id: item.namespace_id,
      asset_id: item.asset_id,
    });
    setVisible(true);
  };

  const { aliasConfiguration, fetchAliasConfiguration } = useOutletContext();
  const [isEditAssetName, setIsEditAssetName] = useState(false);
  const [assetName, setAssetName] = useState(aliasConfiguration?.asset_alias || "Asset ID");
  const { createAliasConfiguration, updateAliasConfiguration } = useAliasConfiguration();
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);

  //get configuration from context

  const handleUpdateAlias = async () => {
    if (assetName.length > 20 || !/^[a-zA-Z0-9_ -]+$/.test(assetName)) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail:
          "Alias Label must be up to 20 characters and can only contain letters, numbers, spaces, and underscores.",
      });
      return;
    }

    if (!assetName) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Asset name cannot be empty.",
      });
      return;
    }
    try {
      setIsUpdateLoading(true);
      if (!aliasConfiguration?.asset_alias) {
        await createAliasConfiguration({
          asset_alias: assetName,
        });
      } else {
        await updateAliasConfiguration({
          asset_alias: assetName,
        });
      }
      await fetchAliasConfiguration();
      setIsEditAssetName(false);
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to save the new label. Please try again later",
      });
    } finally {
      setIsUpdateLoading(false);
    }
  };
  const columns = [
    {
      field: "stream_id",
      sortable: true,
      header: () => (
        <div className="flex items-center">
          <div className="flex items-center">
            <span>{aliasConfiguration?.asset_alias || "Asset ID"}</span>
            <Button
              icon="pi pi-pencil"
              className="p-button-text p-button-rounded p-button-plain ml-2"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setIsEditAssetName(true);
              }}
            />
            {isEditAssetName && (
              <ChangeLabelDialog
                isEditAssetName={isEditAssetName}
                setIsEditAssetName={setIsEditAssetName}
                aliasConfiguration={aliasConfiguration}
                handleUpdateAlias={handleUpdateAlias}
                isUpdateLoading={isUpdateLoading}
                setAssetName={setAssetName}
                assetName={assetName}
              />
            )}
          </div>
        </div>
      ),
    },
    {
      field: "rulesCount",
      header: "Rules",
      sortable: true,
      body: (rowData) => {
        return (
          <div className="flex flex-row items-center">
            <div>{rowData.rulesCount}</div>
            <div className="stream-details-button">
              <Button
                label={<PlusIcon />}
                type="button"
                onClick={() => handleClick(rowData)}
                className="bg-[#FAFAFA] text-2xl text-[#64748B] font-extralight ml-4 p-[5px] border border-[#DFE7EF] rounded-[20%]"
              />
            </div>
          </div>
        );
      },
    },
    // {
    //   field: "status",
    //   header: "Status",
    //   sortable: true,
    //   body: (rowData) => {
    //     return renderStatus(rowData.status);
    //   },
    // },
    {
      field: "alertCounts",
      header: "Active Alerts",
      sortable: true,
      body: (rowData) => {
        const { alertCounts } = rowData;

        return (
          <div className="flex flex-row font-medium text-sm">
            {alertCounts && alertCounts.CRITICAL > 0 && (
              <div className="flex flex-row w-fit items-center bg-[#F5F5F5] px-2 rounded-xl mr-2">
                <CriticalAlertInfo />
                <span className="ml-2">Critical-{alertCounts.CRITICAL}</span>
              </div>
            )}
            {alertCounts && alertCounts.HIGH > 0 && (
              <div className="flex flex-row w-fit items-center bg-[#F5F5F5] px-2 rounded-xl">
                <HighAlertInfo />
                <span className="ml-2">High-{alertCounts.HIGH}</span>
              </div>
            )}
            {alertCounts && alertCounts.MEDIUM > 0 && (
              <div className="flex flex-row w-fit items-center bg-[#F5F5F5] px-2 mx-2 rounded-xl">
                <MediumAlertInfo />
                <span className="ml-2 ">Medium-{alertCounts.MEDIUM}</span>
              </div>
            )}
            {alertCounts && alertCounts.LOW > 0 && (
              <div className="flex flex-row w-fit items-center bg-[#F5F5F5] px-2 rounded-xl mr-2">
                <LowAlertInfo />
                <span className="ml-2">Low-{alertCounts.LOW}</span>
              </div>
            )}
          </div>
        );
      },
    },
    {
      field: "lastAlertRaisedAt",
      header: "Last Alert Raised at",
      body: (rowData) => {
        if (rowData?.lastAlertRaisedAt) return formatDateWithTime(rowData?.lastAlertRaisedAt);
        return null;
      },
      sortable: true,
    },
    {
      header: "View & Edit",
      body: (rowData) => (
        <Button
          label={<i className="pi pi-eye" />}
          type="button"
          onClick={() => {
            setNamespaceAsset({});
            navigate(`${location?.pathname}/stream-detail-rules`, {
              state: {
                sourceRowData: rowData,
                sourceId: location?.state?.source?.id,
              },
            });
          }}
          className="pulse-view-button p-3 text-xs text-[#3B82F6] mr-5"
        />
      ),
    },
  ];
  const handleSuccess = (success) => {
    if (success?.type) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: success?.message,
      });
      setNamespaceAsset({});
      fetchData();
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: success?.message,
      });
    }
  };

  return (
    <div className="new-source-setup">
      <div className="w-full h-screen overflow-hidden bg-sidebar flex">
        {loading ? (
          <div
            style={{ height: isMobile ? "100%" : "calc(100% - 32px)" }}
            className="transition-all duration-500 relative md:ml-[2px] md:mr-[8px] md:my-[16px] md:rounded-[26px] bg-main-gradient p-[18px] h-full animate-pulse"
          >
            <div className="w-full h-full flex justify-center items-center">
              {/* <PreLoader />  */}
            </div>
          </div>
        ) : (
          <div
            style={{ height: isMobile ? "100%" : "calc(100% - 32px)" }}
            className="flex flex-col items-around transition-all duration-500 relative md:rounded-[26px] bg-main-gradient w-full h-full"
          >
            {/* <SourcePulseBreadcrumb
              routes={[
                "Pulse",
                "Stream Data Sources",
                location?.state?.source?.name,
              ]}
            />
            <div
              style={{ borderBottom: "2px solid #DFE7EF", margin: "4px 0" }}
            ></div> */}
            <div className="source-list">
              {megaMenu()}

              {/* {isUsingInModalOpen && (
                <SourceUsingInModal
                  source={selectedRow}
                  onClose={() => setUsingInModal(false)}
                  visible={isUsingInModalOpen}
                />
              )} */}
              <AddRuleSidebar
                visible={visible}
                setVisible={setVisible}
                onSuccess={handleSuccess}
                getNamespaceAsset={getNamespaceAsset}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
              <div className="mt-3 sticky-table-header mr-2">
                {header}
                <DataTable
                  scrollable
                  scrollHeight="calc(100vh - 45vh)"
                  value={sourceData?.items} // Accessing rows from sourceData
                  // header={header}
                  sortField={tableSortField} // Initial sort field
                  sortOrder={tableSortOrder} // Default sort order (1 = ascending, -1 = descending)
                  onSort={onSort}
                  className="stream-data-sources-details border-t-2 border-[#e5e7eb]"
                  tableStyle={{ minWidth: "50rem" }}
                  loading={innerLoading}
                  emptyMessage={
                    "Data not found." // Display this message when no data is found
                  }
                >
                  {columns.map((col, index) => (
                    <Column
                      key={index}
                      field={col.field}
                      header={col.header}
                      body={col.body}
                      style={col.style}
                      className=" border-b-2"
                      sortable={col.sortable}
                      headerClassName="border-b-2"
                    />
                  ))}
                </DataTable>
                <Paginator
                  first={
                    (sourceData?.pagination?.currentPage - 1) * sourceData?.pagination?.pageSize
                  }
                  rows={sourceData?.pagination?.pageSize}
                  totalRecords={sourceData?.pagination?.totalItems}
                  rowsPerPageOptions={[5, 10, 20]}
                  onPageChange={onPageChange}
                  className="justify-end"
                  template={{
                    layout:
                      "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
                  }}
                  currentPageReportTemplate={
                    sourceData?.pagination?.totalItems
                      ? `Showing ${(sourceData.pagination.currentPage - 1) * sourceData.pagination.pageSize + 1} to ${Math.min(sourceData.pagination.currentPage * sourceData.pagination.pageSize, sourceData.pagination.totalItems)} of ${sourceData.pagination.totalItems} entries`
                      : "No entries to display"
                  }
                />
                <Toast ref={toast} position="top-right" />
              </div>
            </div>
            <Divider />
            <ManageTemplatesSidebar
              visible={templatesSidebarVisible}
              setVisible={setTemplatesSidebarVisible}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const ChangeLabelDialog = ({
  isEditAssetName,
  setIsEditAssetName,
  aliasConfiguration,
  handleUpdateAlias,
  isUpdateLoading,
  setAssetName,
  assetName,
}) => {
  return (
    <Dialog
      visible={isEditAssetName}
      onHide={() => setIsEditAssetName(false)}
      header="Change Label"
      className="w-4"
      pt={{
        content: {
          className: "pb-3 pt-4 px-4",
        },
      }}
    >
      <div className="flex flex-column align-items-end justify-content-center">
        <InputText
          className={`w-full ${assetName.length > 20 || assetName.length === 0 ? "border-red-500" : ""} `}
          value={assetName}
          defaultValue={aliasConfiguration?.asset_alias || "Asset ID"}
          onInput={(e) => {
            const value = e.target.value;
            if (value.length <= 20) {
              setAssetName(value);
            }
          }}
        />
        {assetName.length === 0 && (
          <p className="m-0 mt-2 text-sm text-red-500 align-self-start">Label cannot be empty</p>
        )}
        <p className="text-sm m-0 mb-4">Character {assetName.length}/20</p>
      </div>
      <div className="flex justify-content-end mt-2">
        <Button
          label="Cancel"
          link
          className="text-secondary"
          onClick={() => setIsEditAssetName(false)}
        />
        <Button
          label="Update"
          onClick={handleUpdateAlias}
          loading={isUpdateLoading}
          disabled={!assetName.length}
        />
      </div>
    </Dialog>
  );
};

export default StreamDataSourcesDetails;
