import { Outlet } from "react-router-dom";
import React, { useEffect, useState } from "react";
import useAliasConfiguration from "hooks/useAliasConfiguration";
const PulseLayout = () => {
  const { getAliasConfiguration } = useAliasConfiguration();
  const [aliasConfiguration, setAliasConfiguration] = useState([]);
  const fetchAliasConfiguration = async () => {
    const data = await getAliasConfiguration();
    setAliasConfiguration(data?.items?.[0] || {});
  };

  useEffect(() => {
    fetchAliasConfiguration();
  }, []);

  return (
    <Outlet context={{ aliasConfiguration, setAliasConfiguration, fetchAliasConfiguration }} />
  );
};

export default PulseLayout;
