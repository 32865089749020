import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import ChatNow from "assets/SpaceIcons/ChatNow";
import { formatDateWithTime } from "utils/helper";
import useSpace from "hooks/useSpace";
import { Skeleton } from "primereact/skeleton"; // Import Skeleton component

const SourceInfoHeader = ({ spaceId, isMobile }) => {
  const [sourceData, setSourceData] = useState({});
  const { getSpaceById } = useSpace();

  useEffect(() => {
    const fetchSpaceInfo = async () => {
      try {
        const response = await getSpaceById(spaceId);
        setSourceData(response);
      } catch (error) {
        console.error("Error fetching space info:", error);
      }
    };
    if (spaceId) {
      fetchSpaceInfo();
    }
  }, [spaceId]);

  const { name, description, created_at } = sourceData;

  // Check if sourceData is empty to show skeleton
  const isLoading = Object.keys(sourceData).length === 0;

  return (
    <>
      <div
        className={`flex ${!isMobile && "align-items-center"} flex-column lg:justify-content-between lg:flex-row`}
        style={{ marginLeft: "1vw" }}
      >
        <div className="templatesHeader" style={{ width: "70vw" }}>
          {isLoading ? (
            <>
              <Skeleton width="50%" height="1.5rem" />
              <div className="mt-2"> 
                <Skeleton width="50%" height="1.5rem" />
              </div>
            </>
          ) : (
            <>
              <p className="font-semibold" style={{ fontSize: "1.313rem" }}>
                {name}
              </p>
              <p className="font-thin" style={{ fontWeight: 300, lineHeight: "4vh" }}>
                {description}
              </p>
            </>
          )}
        </div>
        <div className="mt-3 lg:mt-0 chat-now-btn">
          <Button
            label="Chat Now"
            icon={<ChatNow />}
            className="text-sm"
            disabled={isLoading} // Disable button while loading
          />
          <span className="pi pi-ellipsis-v text-500 cursor-pointer text-lg ml-5" />
        </div>
      </div>
      <span style={{ color: "#6C757D", marginLeft: "1vw" }}>
        {isLoading ? <div style={{marginLeft:'1vw'}}><Skeleton width="30%" height="1rem" /></div> : `Created at: ${formatDateWithTime(created_at, false)}`}
      </span>
    </>
  );
};

export default SourceInfoHeader;