import React, { useEffect, useState, useRef } from "react";
import { Sidebar } from "primereact/sidebar";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { Accordion, AccordionTab } from "primereact/accordion";
import { InputText } from "primereact/inputtext";
import { Menu } from "primereact/menu";
import { Divider } from "primereact/divider";
import { useLocation } from "react-router-dom";
import { Toast } from "primereact/toast";
import { usePulseApi } from "hooks/pulseConfig";
import './index.css'

const AddRuleSidebar = ({
  visible,
  setVisible,
  onSuccess,
  allRulesListData,
  activeTab = "",
  getNamespaceAsset = {},
  setActiveTab,
}) => {
  const [ruleType, setRuleType] = useState("");
  const [priority, setPriority] = useState("");
  const [dates, setDates] = useState(null);
  const location = useLocation();
  const [conditions, setConditions] = useState([
    {
      type: "Condition 1",
      logic: "",
      subtype: "",
      operator: "",
      unit: "",
      thresholdValue: "",
    },
  ]); // Dynamic conditions with state

  const [menuVisible, setMenuVisible] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const menuRef = React.useRef(null);
  const toast = React.useRef(null);
  const {postRulesFor, patchRulesFor} = usePulseApi()
  const [isLoading, setIsLoading] = useState(false);

  const items = [
    {
      label: "OR",
      command: () => handleConditionSelect("OR"),
    },
    {
      label: "AND",
      command: () => handleConditionSelect("AND"),
    },
  ];

  const toggleMenu = (event) => {
    setMenuVisible((prev) => !prev);
    if (menuRef.current) {
      menuRef.current.toggle(event);
    }
  };

  const handleConditionSelect = (value) => {
    addCondition(value);
    setMenuVisible(false);
  };

  // Dropdown options
  const ruleTypes = [
    { label: "Threshold Based", value: "Threshold Based" },
    { label: "Rate of Change Based", value: "Rate of Change Based" },
    { label: "Anomaly Detection Based", value: "Anomaly Detection Based" },
    { label: "Heartbeat Based", value: "Heartbeat Based" },
    { label: "Count of Breach Based", value: "Count of Breach Based" },
  ];

  const priorityOptions = [
    { label: "Low", value: "LOW" },
    { label: "Medium", value: "MEDIUM" },
    { label: "High", value: "HIGH" },
  ];

  const subtypeOptions = [
    { label: "Latest Value", value: "Latest Value" },
    { label: 'Sum of Last "X" Values', value: 'Sum of Last "X" Values' },
    {
      label: 'Moving Avg. of Last "X" Values',
      value: 'Moving Avg. of Last "X" Values',
    },
    { label: "Moving Median", value: "Moving Median" },
    { label: "Deviation from Base Line", value: "Deviation from Base Line" },
  ];

  const operatorOptions = [
    { label: "Greater Than", value: "Greater Than" },
    { label: "Less Than", value: "Less Than" },
    { label: "Equal To", value: "Equal To" },
  ];

  const formatDate = (dateString) => {
    const [day, month, year] = dateString.split("/");
    return new Date(`${year}-${month}-${day}`);
  };

  useEffect(() => {
    if (activeTab) {
      setRuleType(allRulesListData?.ruleDefinationData?.typeOfRule || "");
      setPriority(allRulesListData?.severity?.toUpperCase() || "");
      setDates([
        formatDate(
          allRulesListData?.ruleDefinationData?.setDuration?.startDate
        ),
        formatDate(allRulesListData?.ruleDefinationData?.setDuration?.endDate),
      ]);
      const formattedConditions =
        allRulesListData?.ruleDefinationData?.conditions?.map(
          (condition, index) => {
            if (condition.logic) {
              return {
                type: `Condition ${index + 1} (${condition.logic})`,
                logic: condition.logic,
                subtype: condition.condition?.subtype || "",
                operator: condition.condition?.operator || "",
                thresholdValue: condition.condition?.thresholdValue || "",
                unit: condition.condition?.unit || "",
                defineX: condition.condition?.defineX || "",
              };
            } else {
              return {
                type: `Condition ${index + 1}`,
                logic: "",
                subtype: condition.subtype || "",
                operator: condition.operator || "",
                thresholdValue: condition.thresholdValue || "",
                unit: condition.unit || "",
                defineX: condition?.defineX || "",
              };
            }
          }
        );
      setConditions(formattedConditions);
    }
  }, [allRulesListData, activeTab]);

  const updateCondition = (index, field, value) => {
    setConditions((prevConditions) =>
      prevConditions?.map((condition, i) =>
        i === index ? { ...condition, [field]: value } : condition
      )
    );
  };

  const clearForm = () => {
    setRuleType("");
    setPriority("");
    setDates(null);
    setVisible(false);
    setActiveTab("");
    setIsSubmitted(false);
    setConditions([
      {
        type: "Condition 1",
        logic: "",
        subtype: "",
        operator: "",
        unit: "",
        thresholdValue: "",
      },
    ]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitted(true);
    setIsLoading(true);
    
    if (
      !dates ||
      !Array.isArray(dates) ||
      dates?.length !== 2 ||
      dates?.includes(null)
    ) {
      setIsLoading(false);
      return;
    }
    if (ruleType && priority && dates && conditions.every(isConditionValid)) {
      let setDuration = {};
      const [startDate, endDate] = dates;
      setDuration = {
        startDate: startDate.toLocaleDateString("en-US", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        }),
        endDate: endDate.toLocaleDateString("en-US", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        }),
      };

      const formattedConditions = conditions?.map((condition) => {
        if (condition.logic) {
          return {
            logic: condition.logic,
            condition: {
              subtype: condition.subtype,
              operator: condition.operator,
              thresholdValue: condition.thresholdValue,
              unit: condition.unit,
              defineX: condition?.defineX || "",
            },
          };
        } else {
          return {
            subtype: condition.subtype,
            operator: condition.operator,
            thresholdValue: condition.thresholdValue,
            unit: condition.unit,
            defineX: condition?.defineX || "",
          };
        }
      });

      const payload = {
        ...(activeTab
          ? { rule_id: allRulesListData?.rule_id }
          : {
              namespace_id: `${
                getNamespaceAsset?.namespace_id
                  ? getNamespaceAsset?.namespace_id
                  : location?.state?.sourceRowData?.namespace_id
                  ? location?.state?.sourceRowData?.namespace_id
                  : location?.state?.sourceId
              }`,
              tenant_id: "1ba0542f-4caa-4244-a48d-13b5f825597e",
              // tenant_id: String(localStorage.getItem('tenantId')),
              asset_id: `${
                getNamespaceAsset?.asset_id
                  ? getNamespaceAsset?.asset_id
                  : location?.state?.sourceRowData?.asset_id
                  ? location?.state?.sourceRowData?.asset_id
                  : location?.state?.sourceRowData?.id
              }`,
            }),
        active: true,
        threshold: "60",
        condition: "UP",
        unit: "Kelvin",
        aspect: "temprature",
        severity: priority.toUpperCase(),
        description: "RULE CREATION",
        meta_details: {
          typeOfRule: ruleType,
          conditions: formattedConditions,
          setDuration: setDuration,
        },
      };
      let ruleData;
      try {
        if (activeTab) {
          ruleData = await patchRulesFor(payload);
        } else {
          ruleData = await postRulesFor(payload);
        }
        if (ruleData?.status === 200) {
          setVisible(false);
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: activeTab ? "Rule Updated Successfully" : "Rule Added Successfully",
            life: 3000
          });
          if (onSuccess) {
            onSuccess({
              type: true,
              message: ruleData?.message,
            });
            clearForm();
            setIsSubmitted(false);
          }
        } else {
          onSuccess({
            type: false,
            message: "An error occurred. Please try again.",
          });
        }
      } catch (error) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: error?.response?.data?.message || "Something went wrong",
          life: 3000
        });
        setIsSubmitted(false);
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  const addCondition = (logic) => {
    setConditions([
      ...conditions,
      {
        type: `Condition ${conditions.length + 1} (${logic})`,
        logic,
        subtype: "",
        operator: "",
        unit: "",
        thresholdValue: "",
      },
    ]);
  };

  const removeCondition = (indexToRemove) => {
    setConditions(conditions.filter((_, index) => index !== indexToRemove));
  };

  const isConditionValid = (condition) => {
    if (condition.subtype === 'Moving Avg. of Last "X" Values' || 
        condition.subtype === 'Sum of Last "X" Values') {
      return (
        condition.subtype &&
        condition.operator &&
        condition.thresholdValue &&
        condition.unit &&
        condition.defineX // Make defineX required for these subtypes
      );
    }
    return (
      condition.subtype &&
      condition.operator &&
      condition.thresholdValue &&
      condition.unit
    );
  };

  const customHeader = (
    <>
      <span className="text-xl font-bold">
        {activeTab ? "Edit Rule Definition" : "Add Rule Definition"}
      </span>
    </>
  );

  return (
    <div>
      <Sidebar
        visible={visible}
        onHide={() => {
          clearForm();
          setVisible(false);
        }}
        header={customHeader}
        position="right"
        className="p-sidebar-md add-rule-sidebar"
        dismissable={false}
      >
        <form onSubmit={handleSubmit} className="p-fluid mt-4">
          <div className="field">
            <label
              htmlFor="ruleType"
              className="block text-title-black-light text-sm font-normal mb-2"
            >
              Type of Rule
            </label>
            <Dropdown
              id="ruleType"
              value={ruleType}
              options={ruleTypes}
              onChange={(e) => setRuleType(e.value)}
              placeholder="Select a Rule Type"
              className={`w-full border ${
                isSubmitted && !ruleType ? "border-red-500" : ""
              }`} // Red border if invalid
            />
            {isSubmitted && !ruleType && (
              <small className="p-error block">This field is required.</small>
            )}
          </div>

          {/* Dynamic Accordions for Conditions */}
          <Accordion className="add-rule-sidebar-accordion mt-4" multiple activeIndex={[0]}>
            {conditions?.map((condition, index) => (
              <AccordionTab
                key={index}
                className="border border-[#e1e5e9] rounded-b-sm rounded-t-md overflow-hidden"
                header={
                  <div className="flex h-5 justify-between items-center w-full" style={{height:'0px', padding:'5px'}}>
                    <span>{condition.type}</span>
                    {condition.logic && (
                      <Button
                        icon="pi pi-trash"
                        className="p-button-rounded text-red-500 p-button-danger p-button-text"
                        onClick={() => removeCondition(index)}
                        tooltip="Delete condition"
                        tooltipOptions={{ position: "top" }}
                        style={{padding:'0px'}}
                      />
                    )}
                  </div>
                }
              >
                <div>
                  {/* Subtype Dropdown */}
                  <div className="field">
                    <label
                      htmlFor={`subtype-${index}`}
                      className="block text-title-black-light text-sm mb-2"
                    >
                      Subtype
                    </label>
                    <Dropdown
                      id={`subtype-${index}`}
                      options={subtypeOptions}
                      value={condition.subtype}
                      onChange={(e) =>
                        updateCondition(index, "subtype", e.value)
                      }
                      placeholder="Select Subtype"
                      className={`w-full border ${
                        isSubmitted && !condition.subtype
                          ? "border-red-500"
                          : " border-[#dfe3e7]"
                      }`}
                    />
                    {isSubmitted && !condition.subtype && (
                      <small className="p-error block">
                        This field is required.
                      </small>
                    )}
                  </div>

                  {/* Operator Dropdown */}
                  <div className="w-full mt-2 flex items-center threshold-unit-box">
                    {/* Show Define X only if condition meets specific criteria */}
                    {(condition.subtype === 'Moving Avg. of Last "X" Values' ||
                    condition.subtype === 'Sum of Last "X" Values') && (
                      <div className="w-1/2 mr-2">
                        <label
                          htmlFor={`defineX-${index}`}
                          className="block text-title-black-light text-sm mb-2"
                        >
                          Define "X"
                        </label>
                        <InputText
                          id={`defineX-${index}`}
                          value={condition.defineX || ""}
                          onChange={(e) =>
                            updateCondition(index, "defineX", e.target.value)
                          }
                          placeholder="Enter X"
                          className={`w-full border h-12 ${
                            isSubmitted && 
                            (condition.subtype === 'Moving Avg. of Last "X" Values' || 
                             condition.subtype === 'Sum of Last "X" Values') && 
                            !condition.defineX ? "border-red-500" : ""
                          }`}
                        />
                        {isSubmitted && 
                         (condition.subtype === 'Moving Avg. of Last "X" Values' || 
                          condition.subtype === 'Sum of Last "X" Values') && 
                         !condition.defineX && (
                          <small className="p-error block">This field is required.</small>
                        )}
                      </div>
                    )}
                    <div
                      className={`w-${
                        condition.subtype ===
                          'Moving Avg. of Last "X" Values' ||
                        condition.subtype === 'Sum of Last "X" Values'
                          ? "1/2"
                          : "full"
                      }`}
                    >
                      <label
                        htmlFor={`operator-${index}`}
                        className="block text-title-black-light text-sm mb-2"
                      >
                        Operator
                      </label>
                      <Dropdown
                        id={`operator-${index}`}
                        options={operatorOptions}
                        value={condition.operator}
                        onChange={(e) =>
                          updateCondition(index, "operator", e.value)
                        }
                        placeholder="Select Operator"
                        className={`w-full border ${
                          isSubmitted && !condition.operator
                            ? "border-red-500"
                            : ""
                        }`}
                      />
                      {isSubmitted && !condition.operator && (
                        <small className="p-error block">
                          This field is required.
                        </small>
                      )}
                    </div>
                  </div>

                  {/* Threshold Input & Unit Dropdown */}
                  <div className="field flex flex-row w-auto mt-4 threshold-unit-box">
                    <div className="w-1/2">
                      <label
                        htmlFor={`threshold-${index}`}
                        className="block text-title-black-light text-sm mb-2"
                      >
                        Threshold Value
                      </label>
                      <InputText
                        id={`threshold-${index}`}
                        value={condition.thresholdValue}
                        onChange={(e) =>
                          updateCondition(
                            index,
                            "thresholdValue",
                            e.target.value
                          )
                        }
                        placeholder=" Enter Threshold"
                        onKeyDown={(e) => {
                          // Allow backspace, delete, arrow keys, and tab
                          if (
                            !/[0-9]/.test(e.key) &&
                            e.key !== "Backspace" &&
                            e.key !== "Delete" &&
                            e.key !== "ArrowLeft" &&
                            e.key !== "ArrowRight" &&
                            e.key !== "Tab"
                          ) {
                            e.preventDefault(); // Restrict non-numeric input
                          }
                        }}
                        className={`w-[20vw] border h-12 ${
                          isSubmitted && !condition.thresholdValue
                            ? "border-red-500"
                            : ""
                        }`}
                        // style={{width:'100%'}}
                      />
                      {isSubmitted && !condition.thresholdValue && (
                        <small className="p-error block">
                          This field is required.
                        </small>
                      )}
                    </div>

                    <div className="w-[50%] pl-2">
                      <label
                        htmlFor={`unit-${index}`}
                        className="block text-title-black-light text-sm mb-2"
                      >
                        Unit
                      </label>
                      <InputText
                        id={`unit-${index}`}
                        value={condition.unit}
                        onChange={(e) => {
                          let inputValue = e.target.value;
                          if (inputValue.length > 10) {
                            return;
                          }
                          if (inputValue.trim() === "") {
                            inputValue = "";
                          }
                          updateCondition(index, "unit", inputValue);
                        }}
                        placeholder=" Enter Unit"
                        className={`w-[20vw] border h-12 ${
                          isSubmitted && !condition.unit ? "border-red-500" : ""
                        }`}
                      />
                      {isSubmitted && !condition.unit && (
                        <small className="p-error block">
                          This field is required.
                        </small>
                      )}
                    </div>
                  </div>
                </div>
              </AccordionTab>
            ))}
          </Accordion>

          {/* Dropdown to Add New Condition */}
          <div className="mt-5 flex justify-end add-condition-section">
            <div
              className="inline-block  my-4 items-center cursor-pointer "
              onClick={toggleMenu}
            >
              <span className="text-blue-600 font-medium mr-2">
                Add Condition
              </span>
              <i
                className={`pi pi-chevron-down ${
                  menuVisible ? "rotate-180" : ""
                }`}
              />
            </div>

            {/* Menu component for conditions */}
            <Menu
              model={items}
              ref={menuRef}
              popup
              onHide={() => setMenuVisible(false)}
              className="mt-2"
            />
          </div>

          <Divider className="border" />

          {/* Date Duration Selection */}
          <div className="field mt-4">
            <label
              htmlFor="duration"
              className="block text-title-black-light text-sm mb-2"
            >
              Set Duration
            </label>
            <div className="relative">
              <i className="pi pi-calendar absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500"></i>
              {console.log('dates are ',(!dates || dates.length !== 2 || dates.includes(null)))}
              <Calendar
                id="duration"
                value={dates} // Controlled by state
                onChange={(e) => setDates(e.value)} // Updates state with selected date
                selectionMode="range"
                placeholder="Start Date-End Date"
                readOnlyInput
                minDate={new Date()}
                className={`w-full pl-10 border h-12 rounded-lg ${
                  isSubmitted &&
                  (!dates || dates.length !== 2 || dates.includes(null))
                    ? "no-dates-selected border-red-500"
                    : ""
                }`}
              />
              {isSubmitted &&
                (!dates || dates.length !== 2 || dates.includes(null)) && (
                  <small className="p-error block">
                    Please select both a start date and an end date.
                  </small>
                )}
            </div>

            <p className="text-gray-400">
              Rules will be deactivated once the duration is over
            </p>
          </div>

          {/* Priority Dropdown */}
          <div className="field mt-4 mb-8">
            <label
              htmlFor="priority"
              className="block text-title-black-light text-sm mb-2"
            >
              Priority
            </label>
            <Dropdown
              id="priority"
              value={priority} // Controlled by state
              options={priorityOptions}
              onChange={(e) => setPriority(e.value)} // Updates state when option selected
              placeholder="Select Priority"
              className={`w-fit border ${
                isSubmitted && !priority ? "border-red-500" : ""
              }`}
            />
            {isSubmitted && !priority && (
              <small className="p-error block">This field is required.</small>
            )}
          </div>

          {/* Footer with Clear and Apply Buttons */}
          <div className="pulse-sidebar-footer flex absolute bottom-0 bg-white left-0 right-0 px-5" style={{justifyContent:'end',paddingTop:'1vh', width:'100%',paddingBottom:'3vh'}}>
            <Button
              onClick={clearForm}
              className="h-12 px-3 mr-2 w-fit text-gray-500 pulse-footer-cancel-button"
              style={{backgroundColor:'white', border:'0px'}}
              label="Cancel"
              type="button"
              disabled={isLoading}
            />
            <Button
              label={activeTab ? "Update Rule" : "Add Rule"}
              className="border px-3 w-fit bg-blue-500 text-white"
              type="submit"
              loading={isLoading}
              disabled={isLoading}
            />
          </div>
        </form>
      </Sidebar>
      <Toast ref={toast} position="top-right" />
    </div>
  );
};

export default AddRuleSidebar;
