import { useCallback, useEffect, useRef, useState } from "react";
import { Sidebar } from "primereact/sidebar";
import { Calendar } from "primereact/calendar";
import { MultiSelect } from "primereact/multiselect";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { Timeline } from "primereact/timeline";
import { convertDateTimeWithSeconds } from "utils/helper";
import TemplateEscalationLevel from "./TemplateEscalationLevel";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { useWorkspaceApi } from "hooks/workspaceConfig";
import { usePulseApi } from "hooks/pulseConfig";
import "./index.css";

const ScheduleTypeDropdown = ({ formData, onChange }) => {
  const scheduleOptions = [
    { label: "Specific Date Range", value: "date_range" },
    { label: "Occurs Every Week", value: "weekly" },
    { label: "Occurs Every Month", value: "monthly" },
    { label: "No End Date", value: "no_end" },
  ];

  return (
    <div className="space-y-1">
      <label htmlFor="schedule_type" className="block text-sm font-normal text-title-black-light">
        Schedule Type
      </label>
      <Dropdown
        id="schedule_type"
        value={formData.schedule_type}
        options={scheduleOptions}
        onChange={(e) => onChange("schedule_type", e.value)}
        placeholder="Select Schedule Type"
        className="w-full border rounded-md h-12"
      />

      {formData.schedule_type === "date_range" && (
        <div className="schedule-type-date-range mt-4 flex gap-4 items-center border">
          <i className="pi pi-calendar ml-3" style={{ color: "#A6ADB3" }}></i>
          <Calendar
            value={formData.date_range.start_date}
            onChange={(e) =>
              onChange("date_range", {
                ...formData.date_range,
                start_date: e.value,
              })
            }
            placeholder="Start Date"
            className="w-1/2"
          />
          <span>-</span>
          <Calendar
            value={formData.date_range.end_date}
            onChange={(e) =>
              onChange("date_range", {
                ...formData.date_range,
                end_date: e.value,
              })
            }
            placeholder="End Date"
            className="w-1/2"
          />
        </div>
      )}

      {formData.schedule_type === "weekly" && (
        <div className="schedule-type-weekly mt-4 flex gap-2">
          {["S", "M", "T", "W", "T", "F", "S"].map((day, index) => (
            <Button
              key={index}
              className={`schedule-type-weekly-button p-0 ${formData.weekly_days.includes(index)
                  ? "bg-blue-500 text-white"
                  : "bg-white text-gray-700 border border-gray-300"
                }`}
              onClick={() => {
                const days = formData.weekly_days.includes(index)
                  ? formData.weekly_days.filter((d) => d !== index)
                  : [...formData.weekly_days, index];
                onChange("weekly_days", days);
              }}
            >
              {day}
            </Button>
          ))}
        </div>
      )}

      {formData.schedule_type === "monthly" && (
        <div className="mt-4 space-y-4">
          {formData.monthly_dates.map((dateRange, index) => (
            <div key={index} className="schedule-type-monthly flex items-center gap-4">
              <Calendar
                value={dateRange.start_date}
                onChange={(e) => {
                  const newDates = [...formData.monthly_dates];
                  newDates[index].start_date = e.value;
                  onChange("monthly_dates", newDates);
                }}
                view="date"
                dateFormat="dd"
                placeholder="Date of the month"
                className="w-32"
              />
              <span>till</span>
              <Checkbox
                checked={!!dateRange.end_date}
                onChange={(e) => {
                  const newDates = [...formData.monthly_dates];
                  newDates[index].end_date = e.checked ? new Date() : null;
                  onChange("monthly_dates", newDates);
                }}
              />
              {dateRange.end_date && (
                <Calendar
                  value={dateRange.end_date}
                  onChange={(e) => {
                    const newDates = [...formData.monthly_dates];
                    newDates[index].end_date = e.value;
                    onChange("monthly_dates", newDates);
                  }}
                  view="date"
                  dateFormat="dd"
                  placeholder="Date of the month"
                  className="w-32"
                />
              )}
            </div>
          ))}
          <Button
            label="+ Add another date"
            className="p-button-text text-blue-500"
            onClick={() => {
              onChange("monthly_dates", [
                ...formData.monthly_dates,
                { start_date: null, end_date: null },
              ]);
            }}
          />
        </div>
      )}
    </div>
  );
};

const TemplateRuleNotificationSidebar = ({
  visible,
  setVisible,
  allUserData,
  onSuccess,
  allRuleData,
  updateSidebarData,
  setUpdateSidebarData,
  getNamespaceAsset,
}) => {
  // Local state for notification form data
  const [formDataNotification, setFormDataNotification] = useState({
    template_title: "",
    alert_message: "",
    notification_channels: [],
    alert_rule_ids: "",
    definition_id: 0,
    knowledge_space: "",
    primary_spoc: [],
    secondary_spoc: { escalation_time: "", users: [] },
    tertiary_spoc: { escalation_time: "", users: [] },
    schedule_type: "",
    date_range: {
      start_date: null,
      end_date: null,
    },
    weekly_days: [],
    monthly_dates: [
      {
        start_date: null,
        end_date: null,
      },
    ],
    phone_numbers: {},
  });

  const [isValidationEnable, setIsValidationEnable] = useState(false);
  const [showSecondarySpoc, setShowSecondarySpoc] = useState(false);
  const [showTertiarySpoc, setShowTertiarySpoc] = useState(false);
  const { getTenantId } = useWorkspaceApi();
  const { postRulesForNotification, patchRulesForNotification } = usePulseApi();
  useEffect(() => {
    if (updateSidebarData) {
      setFormDataNotification({
        alert_message: updateSidebarData?.alert_message || "",
        alert_rule_ids: updateSidebarData?.alert_rules?.map((item) => item.id) || [],
        definition_id: updateSidebarData?.id,
        knowledge_space: updateSidebarData?.knowledge_space?.map((item) => item.value) || [],
        primary_spoc: updateSidebarData?.primary_spoc?.map((item) => item.value) || [],
        secondary_spoc: {
          escalation_time: convertDateTimeWithSeconds(
            updateSidebarData?.secondary_spoc?.escalation_time || 0,
          ),
          users: updateSidebarData?.secondary_spoc?.users?.map((item) => item.value) || [],
        },
        tertiary_spoc: {
          escalation_time: convertDateTimeWithSeconds(
            updateSidebarData?.tertiary_spoc?.escalation_time || 0,
          ),
          users: updateSidebarData?.tertiary_spoc?.users?.map((item) => item.value) || [],
        },
        template_title: updateSidebarData?.template_title || "",
      });
      if (
        updateSidebarData?.secondary_spoc?.escalation_time > 0 ||
        updateSidebarData?.secondary_spoc?.users?.length > 0
      ) {
        setShowSecondarySpoc(true);
      }

      if (
        updateSidebarData?.tertiary_spoc?.escalation_time > 0 ||
        updateSidebarData?.tertiary_spoc?.users?.length > 0
      ) {
        setShowTertiarySpoc(true);
      }
    }
  }, [updateSidebarData]);

  // Handle MultiSelect change for primary, secondary, tertiary, and knowledge_space
  const handleSelectChange = (e, field) => {
    setFormDataNotification((prev) => ({
      ...prev,
      [field]: e.value,
    }));
  };

  const handleCancelForm = () => {
    setVisible(false);
    setFormDataNotification({
      template_title: "",
      alert_message: "",
      notification_channels: [],
      alert_rule_ids: "",
      definition_id: 0,
      knowledge_space: "",
      primary_spoc: [],
      secondary_spoc: { escalation_time: "", users: [] },
      tertiary_spoc: { escalation_time: "", users: [] },
      schedule_type: "",
      date_range: {
        start_date: null,
        end_date: null,
      },
      weekly_days: [],
      monthly_dates: [
        {
          start_date: null,
          end_date: null,
        },
      ],
      phone_numbers: {},
    });
    setUpdateSidebarData(null);
    setIsValidationEnable(false);
  };

  const createTemplateNotificationPayload = async (formData, namespace_id, asset_id) => {
    return {
      description: "Template Notification CREATION",
      alert_message: formData.alert_message || "Timeout error",
      definition_id: formData?.definition_id || 0,
      additional_info: {},
      active: true,
      asset_id,
      namespace_id,
      template_title: formData.template_title,
      notification_channels: formData.notification_channels,
      primary_spoc: formData.primary_spoc || [],
      secondary_spoc: {
        escalation_time: formData.secondary_spoc.escalation_time
          ? convertDateTimeWithSeconds(formData.secondary_spoc.escalation_time)
          : 0,
        users: formData.secondary_spoc.users?.length > 0 ? formData.secondary_spoc.users : [],
      },
      tertiary_spoc: {
        escalation_time: formData.tertiary_spoc.escalation_time
          ? convertDateTimeWithSeconds(formData.tertiary_spoc.escalation_time)
          : 0,
        users: formData.tertiary_spoc.users?.length > 0 ? formData.tertiary_spoc.users : [],
      },
      knowledge_space: formData.knowledge_space || [],
      alert_rule_ids: formData.alert_rule_ids || [],
      schedule_config: {
        type: formData.schedule_type,
        date_range:
          formData.schedule_type === "date_range"
            ? {
              start_date: formData.date_range.start_date,
              end_date: formData.date_range.end_date,
            }
            : null,
        weekly_days: formData.schedule_type === "weekly" ? formData.weekly_days : [],
        monthly_dates: formData.schedule_type === "monthly" ? formData.monthly_dates : [],
      },
      phone_numbers: formData.phone_numbers || {},
      region: "westus",
      tenant_id: await getTenantId(),
    };
  };

  const validateTemplateForm = (formData, showSecondarySpoc, showTertiarySpoc) => {
    const validations = {
      isPrimarySpocValid: formData.primary_spoc?.length > 0,
      isAlertMessageValid: formData.alert_message !== "",
      isTitleValid: formData.template_title !== "",
      isChannelValid: formData.notification_channels.length > 0,
      isSecondarySpocValid: showSecondarySpoc
        ? formData.secondary_spoc.escalation_time !== "" &&
        formData.secondary_spoc.users.length > 0 &&
        formData.secondary_spoc.escalation_time !== null &&
        convertDateTimeWithSeconds(formData?.secondary_spoc?.escalation_time) > 0
        : true,
      isTertiarySpocValid: showTertiarySpoc
        ? formData.tertiary_spoc.escalation_time !== "" &&
        formData.tertiary_spoc.users.length > 0 &&
        formData.tertiary_spoc.escalation_time !== null &&
        convertDateTimeWithSeconds(formData?.tertiary_spoc?.escalation_time) > 0
        : true,
      isScheduleValid: validateScheduleConfig(formData),
    };

    return Object.values(validations).every(Boolean);
  };

  const validateScheduleConfig = (formData) => {
    switch (formData.schedule_type) {
      case "date_range":
        return formData.date_range.start_date && formData.date_range.end_date;
      case "weekly":
        return formData.weekly_days.length > 0;
      case "monthly":
        return formData.monthly_dates.some((date) => date.start_date);
      case "no_end":
        return true;
      default:
        return false;
    }
  };

  const resetTemplateForm = () => ({
    alert_message: "",
    alert_rule_id: "",
    definition_id: 0,
    knowledge_space: "",
    template_title: "",
    notification_channels: [],
    primary_spoc: [],
    secondary_spoc: { escalation_time: "", users: [] },
    tertiary_spoc: { escalation_time: "", users: [] },
    schedule_type: "no_end",
    date_range: { start_date: null, end_date: null },
    weekly_days: [],
    monthly_dates: [{ start_date: null }],
    phone_numbers: {},
  });

  // Handle form submission
  const handleSubmit = async () => {
    setIsValidationEnable(true);

    const namespace_id = `${getNamespaceAsset?.namespace_id}`;
    const asset_id = `${getNamespaceAsset?.asset_id}`;

    if (!validateTemplateForm(formDataNotification, showSecondarySpoc, showTertiarySpoc)) {
      return;
    }

    try {
      const payload = await createTemplateNotificationPayload(
        formDataNotification,
        namespace_id,
        asset_id,
      );

      const templateNotificationData =
        formDataNotification?.definition_id === 0 || !formDataNotification?.definition_id
          ? await postRulesForNotification(payload)
          : await patchRulesForNotification(payload);

      console.log("templateNotificationData", templateNotificationData);
      if (templateNotificationData?.status === 200) {
        setVisible(false);

        if (onSuccess) {
          onSuccess(templateNotificationData?.message);
          setFormDataNotification(resetTemplateForm());
          console.log("templateNotificationData", payload);
        }

        setUpdateSidebarData(null);
        setShowSecondarySpoc(false);
        setShowTertiarySpoc(false);
      }
    } catch (error) {
      console.error("Error submitting template notification:", error);
      if (onSuccess) {
        onSuccess(false);
      }
      setVisible(false);
    }
  };

  const handleChangeNotification = (e) => {
    const { name, value } = e.target;
    if ((name === "alert_message" || name === "template_title") && value.length <= 60) {
      setFormDataNotification((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleUserChange = (e, level) => {
    if (level === "primary") {
      setFormDataNotification((prev) => ({
        ...prev,
        primary_spoc: e,
      }));
    } else {
      setFormDataNotification((prev) => ({
        ...prev,
        [`${level}_spoc`]: {
          ...prev[`${level}_spoc`],
          users: e.value,
        },
      }));
    }
  };

  const handleTimeChange = (e, type) => {
    const newTime = e.value;
    setFormDataNotification((prev) => ({
      ...prev,
      [`${type}_spoc`]: {
        ...prev[`${type}_spoc`],
        escalation_time: newTime,
      },
    }));
  };

  const handleDelete = (level) => {
    setFormDataNotification((prev) => ({
      ...prev,
      [`${level}_spoc`]: { escalation_time: "", users: [] },
    }));
    if (level === "secondary") {
      setShowSecondarySpoc(false);
    } else if (level === "tertiary") {
      setShowTertiarySpoc(false);
    }
  };

  const characterCount = formDataNotification.alert_message?.length;

  const handleChannelChange = (e) => {
    let selectedChannels = [...formDataNotification.notification_channels];
    if (e.checked) selectedChannels.push(e.value);
    else selectedChannels = selectedChannels.filter((channel) => channel !== e.value);

    setFormDataNotification((prev) => ({
      ...prev,
      notification_channels: selectedChannels,
    }));
  };

  const handleScheduleChange = (field, value) => {
    setFormDataNotification((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handlePhoneNumberChange = (userId, value) => {
    setFormDataNotification((prev) => ({
      ...prev,
      phone_numbers: {
        ...prev.phone_numbers,
        [userId]: value,
      },
    }));
  };

  return (
    <Sidebar
      visible={visible}
      position="right"
      className="p-sidebar-md rule-notification-sidebar"
      dismissable={false}
      onHide={() => handleCancelForm()}
      content={({ closeIconRef, hide }) => (
        <div style={{ overflowY: "auto" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "12px",
              paddingTop: "16px",
              position: "sticky",
              top: 0,
              backgroundColor: "white",
              zIndex: 10,
              height: "8.5vh",
            }}
            className="rule-notification-sidebar-header"
          >
            <span
              style={{
                fontSize: "1.25rem",
                fontWeight: "700",
                color: "#495057",
                paddingLeft: "20px",
              }}
              className="heading"
            >
              {updateSidebarData ? "Edit Notification Template" : "Add Notification Template"}
            </span>
            <button
              type="button"
              onClick={() => handleCancelForm()}
              ref={closeIconRef}
              className="p-2 rounded-full transition-colors"
              style={{ backgroundColor: "white", border: "0px", marginRight: "2vw" }}
              aria-label="Close"
            >
              <i className="pi pi-times text-[#6b7280] hover:text-[#212121] text-lg font-light"></i>
            </button>
          </div>

          {/* Form Content */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1.5rem",
              padding: "1.5rem",
              backgroundColor: "white",
              borderRadius: "0.5rem",
            }}
          >
            {/* Template Title */}
            <div className="space-y-1">
              <label
                htmlFor="template_title"
                className="block text-sm font-normal text-title-black-light"
              >
                Template Title
              </label>
              <InputText
                id="template_title"
                name="template_title"
                placeholder="Define Template Title"
                className={`w-full border rounded-md py-2 px-3 mt-2 focus:outline-none ${formDataNotification.template_title === "" && isValidationEnable
                    ? "border-red-500"
                    : "border-gray-300"
                  }`}
                value={formDataNotification.template_title}
                onChange={handleChangeNotification}
                style={{ height: "6vh", border: "1px solid #d8d4dc" }}
              />
              <div className="flex flex-col">
                {formDataNotification.template_title === "" && isValidationEnable && (
                  <p
                    className="text-red-500 text-xs"
                    style={{ marginTop: "2px", marginBottom: "2px" }}
                  >
                    Template Title field is required
                  </p>
                )}
              </div>
              <div className="text-left text-gray-500 text-sm mr-auto" style={{ marginTop: "2px" }}>
                Character limit : {formDataNotification.template_title?.length || 0}/60
              </div>
            </div>

            {/* Notification Message */}
            <div className="space-y-1">
              <label
                htmlFor="alert_message"
                className="block text-sm font-normal text-title-black-light"
              >
                Notification Message
              </label>
              <InputText
                id="alert_message"
                name="alert_message"
                className={`w-full border rounded-md py-2 px-3 mt-2 focus:outline-none ${formDataNotification.alert_message === "" && isValidationEnable
                    ? "border-red-500"
                    : "border-gray-300"
                  }`}
                value={formDataNotification.alert_message}
                onChange={handleChangeNotification}
                style={{ height: "6vh", border: "1px solid #d8d4dc" }}
              />
              <div className="flex flex-col">
                {formDataNotification.alert_message === "" && isValidationEnable && (
                  <p
                    className="text-red-500 text-xs"
                    style={{ marginTop: "2px", marginBottom: "2px" }}
                  >
                    Notification Message field is required
                  </p>
                )}
              </div>
              <div className="text-left text-gray-500 text-sm mr-auto" style={{ marginTop: "2px" }}>
                Character limit : {characterCount}/60
              </div>
            </div>

            {/* Notification Channel */}
            <div className="space-y-1">
              <label
                htmlFor="notification_channel"
                className="block text-sm font-normal text-title-black-light"
              >
                Notification Channel
              </label>
              <div className="flex flex-row gap-4 mt-2">
                <div className="flex align-items-center">
                  <Checkbox
                    inputId="pulse"
                    name="channel"
                    value="pulse"
                    onChange={handleChannelChange}
                    checked={formDataNotification.notification_channels.includes("pulse")}
                  />
                  <label htmlFor="pulse" className="ml-2 text-sm text-gray-600">
                    Pulse
                  </label>
                </div>
                <div className="flex align-items-center">
                  <Checkbox
                    inputId="email"
                    name="channel"
                    value="email"
                    onChange={handleChannelChange}
                    checked={formDataNotification.notification_channels.includes("email")}
                  />
                  <label htmlFor="email" className="ml-2 text-sm text-gray-600">
                    Email
                  </label>
                </div>
              </div>
              <div className="flex flex-col">
                {formDataNotification.notification_channels.length === 0 && isValidationEnable && (
                  <p
                    className="text-red-500 text-xs"
                    style={{ marginTop: "2px", marginBottom: "2px" }}
                  >
                    Please select at least one notification channel
                  </p>
                )}
              </div>
            </div>

            {/* Escalation Levels */}
            <div className="sidebar-tab flex justify-start items-start">
              <Timeline
                value={[
                  {
                    label: "L1 Respondent",
                    content: (
                      <TemplateEscalationLevel
                        level="primary"
                        formData={formDataNotification}
                        onTimeChange={handleTimeChange}
                        onUserChange={handleUserChange}
                        onDelete={() => { }}
                        showNextLevel={showSecondarySpoc}
                        onAddNextLevel={() => setShowSecondarySpoc(true)}
                        allUserData={allUserData}
                        isValidationEnable={isValidationEnable}
                        convertDateTimeWithSeconds={convertDateTimeWithSeconds}
                        isPrimary={true}
                        phoneNumbers={formDataNotification.phone_numbers}
                        onPhoneNumberChange={handlePhoneNumberChange}
                      />
                    ),
                  },
                  showSecondarySpoc && {
                    label: "L2 Escalation",
                    content: (
                      <TemplateEscalationLevel
                        level="secondary"
                        formData={formDataNotification}
                        onTimeChange={handleTimeChange}
                        onUserChange={handleUserChange}
                        onDelete={handleDelete}
                        showNextLevel={showTertiarySpoc}
                        onAddNextLevel={() => setShowTertiarySpoc(true)}
                        allUserData={allUserData}
                        isValidationEnable={isValidationEnable}
                        convertDateTimeWithSeconds={convertDateTimeWithSeconds}
                        phoneNumbers={formDataNotification.phone_numbers}
                        onPhoneNumberChange={handlePhoneNumberChange}
                      />
                    ),
                  },
                  showTertiarySpoc && {
                    label: "L3 Escalation",
                    content: (
                      <TemplateEscalationLevel
                        level="tertiary"
                        formData={formDataNotification}
                        onTimeChange={handleTimeChange}
                        onUserChange={handleUserChange}
                        onDelete={handleDelete}
                        showNextLevel={true}
                        onAddNextLevel={() => { }}
                        allUserData={allUserData}
                        isValidationEnable={isValidationEnable}
                        convertDateTimeWithSeconds={convertDateTimeWithSeconds}
                        phoneNumbers={formDataNotification.phone_numbers}
                        onPhoneNumberChange={handlePhoneNumberChange}
                      />
                    ),
                  },
                ].filter(Boolean)}
                align="left"
                content={(item) => item.content}
                className="flex justify-start items-stretch left-aligned"
                marker={(item) => (
                  <span className="w-2 h-2 border-2 rounded-full inline-block"></span>
                )}
              />
            </div>

            {/* Associated Rule */}
            <div className="space-y-1">
              <label
                htmlFor="alert_rule_ids"
                className="block text-sm font-normal text-title-black-light"
              >
                Associated Rule (Optional)
              </label>
              <MultiSelect
                value={formDataNotification.alert_rule_ids}
                filter
                className="w-full border rounded-md h-12 placeholder:font-normal text-[#6C757D] opacity-60 border-gray-400"
                options={allRuleData}
                placeholder="Select"
                selectedItemTemplate={() => null}
                onChange={(e) => handleSelectChange(e, "alert_rule_ids")}
                style={{ height: "6vh", marginTop: "1vh", border: "1px solid #d8d4dc" }}
              />
              <div className="mt-2 flex flex-wrap gap-2">
                {formDataNotification.alert_rule_ids?.length > 0 && (
                  <div className="flex flex-wrap gap-2 mt-2">
                    {formDataNotification.alert_rule_ids?.map((item, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "0.25rem",
                          padding: "0.25rem",
                          paddingLeft: "0.75rem",
                          paddingRight: "0.75rem",
                          border: "1px solid #E5E7EB",
                          backgroundColor: "#F3F4F6",
                          borderRadius: "1rem",
                          fontSize: "0.875rem",
                          fontWeight: "600",
                          color: "#1F2937",
                        }}
                      >
                        <span>{allRuleData.find((u) => u.value === item)?.label}</span>
                        <button
                          type="button"
                          onClick={() => {
                            setFormDataNotification({
                              ...formDataNotification,
                              alert_rule_ids: formDataNotification.alert_rule_ids?.filter(
                                (spoc) => spoc !== item,
                              ),
                            });
                          }}
                          className="text-gray-600 hover:text-gray-700"
                          style={{
                            backgroundColor: "#F3F4F6",
                            border: "0px",
                            fontStyle: "normal",
                            fontWeight: "300",
                            cursor: "pointer",
                          }}
                        >
                          <i
                            style={{
                              fontSize: "0.7rem",
                              paddingTop: "0.25rem",
                            }}
                            className="pi pi-times"
                          ></i>
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>

            {/* Schedule Type Dropdown */}
            <ScheduleTypeDropdown formData={formDataNotification} onChange={handleScheduleChange} />

            {/* Knowledge Space */}
            <div className="space-y-1 mb-10" style={{ marginBottom: "15vh" }}>
              <label
                htmlFor="knowledge_space"
                className="block text-sm font-normal text-title-black-light"
              >
                Provide Context for Queries (Optional)
              </label>
              <MultiSelect
                value={formDataNotification.knowledge_space}
                filter
                selectedItemTemplate={() => null}
                className="w-full border rounded-md h-12 placeholder:font-normal text-[#6C757D] opacity-60 border-gray-400"
                options={allUserData?.knowledgeSpace}
                placeholder="Select"
                onChange={(e) => handleSelectChange(e, "knowledge_space")}
                style={{ height: "6vh", marginTop: "1vh" }}
              />
              {formDataNotification.knowledge_space.length > 0 && (
                <div className="flex flex-wrap gap-2 mt-2">
                  {formDataNotification.knowledge_space?.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "0.25rem",
                        padding: "0.25rem",
                        paddingLeft: "0.75rem",
                        paddingRight: "0.75rem",
                        border: "1px solid #E5E7EB",
                        backgroundColor: "#F3F4F6",
                        borderRadius: "1rem",
                        fontSize: "0.875rem",
                        fontWeight: "600",
                        color: "#1F2937",
                      }}
                    >
                      <span>
                        {allUserData?.knowledgeSpace.find((u) => u.value === item)?.label}
                      </span>
                      <button
                        type="button"
                        onClick={() => {
                          setFormDataNotification({
                            ...formDataNotification,
                            knowledge_space: formDataNotification.knowledge_space?.filter(
                              (spoc) => spoc !== item,
                            ),
                          });
                        }}
                        className="text-gray-600 hover:text-gray-700"
                        style={{
                          backgroundColor: "#F3F4F6",
                          border: "0px",
                          fontStyle: "normal",
                          fontWeight: "300",
                          cursor: "pointer",
                        }}
                      >
                        <i
                          style={{
                            fontSize: "0.7rem",
                            paddingTop: "0.25rem",
                          }}
                          className="pi pi-times"
                        ></i>
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>

            {/* Footer Buttons */}
            <div
              className="flex absolute bg-white bottom-0 left-0 ml-auto right-0 px-5 justify-end rule-notification-sidebar-footer"
              style={{ marginTop: "5vh", justifyContent: "end" }}
            >
              <div style={{ paddingTop: "1vh", paddingBottom: "0.5vh" }}>
                <Button
                  onClick={handleCancelForm}
                  className="h-12 px-3 mr-2 w-fit text-gray-500"
                  style={{ backgroundColor: "white", border: "0px" }}
                  label="Cancel"
                  type="button"
                />
                <Button
                  label={updateSidebarData ? "Update" : "Configure"}
                  className="border px-4 bg-blue-500 text-white"
                  onClick={handleSubmit}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    ></Sidebar>
  );
};

export default TemplateRuleNotificationSidebar;
