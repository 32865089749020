import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { MultiSelect } from "primereact/multiselect";
import { Divider } from "primereact/divider";
import React, { useState } from "react";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import "../StreamDataSourcesDetails/index.css";

const EscalationLevel = ({
  level,
  formData,
  onTimeChange,
  onUserChange,
  onDelete,
  showNextLevel,
  onAddNextLevel,
  allUserData,
  isValidationEnable,
  convertDateTimeWithSeconds,
  isPrimary = false,
  phoneNumbers,
  onPhoneNumberChange,
}) => {
  const [callBeforeEscalation, setCallBeforeEscalation] = useState(false);
  const levelNumber = level === "primary" ? 1 : level === "secondary" ? 2 : 3;
  const levelData = formData.detail_levels.find((l) => l.level === levelNumber) || {
    level: levelNumber,
    users: [],
    escalation_time: 0,
  };
  const handlePhoneNumberChange = (userId, e) => {
    onPhoneNumberChange(userId, e.target.value);
  };

  const CallBeforeEscalationHeader = ({ callBeforeEscalation, setCallBeforeEscalation }) => {
    return (
      <div className="flex flex-col">
        <div className="call-before-escalation-label mt-4 pb-1">
          <Checkbox
            inputId="callBeforeEscalation"
            checked={callBeforeEscalation}
            onChange={(e) => setCallBeforeEscalation(e.checked)}
          />
          <label htmlFor="callBeforeEscalation" className="ml-2 text-md text-gray-600">
            Call before escalation
          </label>
        </div>
        <div className="call-before-escalation-label-subtitle mt-6 mr-2">
          <span className="text-xs text-gray-400">
            {" "}
            At least one person's phone number should be available for Calling before escalation{" "}
          </span>
        </div>
      </div>
    );
  };

  const UncheckedCallBeforeEscalationHeader = ({
    callBeforeEscalation,
    setCallBeforeEscalation,
  }) => {
    return (
      <div className="flex flex-col">
        <div className="call-before-escalation-label-unchecked mt-4 pb-1">
          <Checkbox
            inputId="callBeforeEscalation"
            checked={callBeforeEscalation}
            onChange={(e) => setCallBeforeEscalation(e.checked)}
          />
          <label htmlFor="callBeforeEscalation" className="ml-2 text-md text-gray-600">
            Call before escalation
          </label>
        </div>
      </div>
    );
  };

  const CallBeforeEscalationContent = ({
    levelData,
    allUserData,
    phoneNumbers,
    handlePhoneNumberChange,
    callBeforeEscalation,
    isValidationEnable,
  }) => {
    return (
      <div className="call-before-escalation-container relative border border-gray-300 rounded-md p-4 mt-5 pt-8">
        <div>
          {levelData.users.map((userId, index) => {
            const user = allUserData?.userList.find((u) => u.value === userId);
            return (
              <div key={index} className="call-before-escalation-pair">
                <span className="text-sm font-medium text-gray-700 mb-2">{user?.label}</span>
                <InputText
                  placeholder="Enter phone number"
                  value={phoneNumbers[userId] || ""}
                  onChange={(e) => handlePhoneNumberChange(userId, e)}
                  className={`w-1/2 h-8 text-sm ${callBeforeEscalation && !phoneNumbers[userId] && isValidationEnable
                    ? "border-red-500"
                    : "border-gray-300"
                    }`}
                />
              </div>
            );
          })}
        </div>
        {callBeforeEscalation &&
          isValidationEnable &&
          Object.keys(phoneNumbers).length < levelData.users.length && (
            <p className="text-red-500 text-xs mt-4">
              Phone numbers are required for all selected users
            </p>
          )}
      </div>
    );
  };
  const renderCallBeforeEscalation = () => {
    if (level === "primary") {
      return (
        <>
          {levelData.users.length > 0 && callBeforeEscalation ? (
            <CallBeforeEscalationHeader
              callBeforeEscalation={callBeforeEscalation}
              setCallBeforeEscalation={setCallBeforeEscalation}
            />
          ) : (
            <UncheckedCallBeforeEscalationHeader
              callBeforeEscalation={callBeforeEscalation}
              setCallBeforeEscalation={setCallBeforeEscalation}
            />
          )}
          {callBeforeEscalation && levelData.users.length > 0 && (
            <CallBeforeEscalationContent
              levelData={levelData}
              allUserData={allUserData}
              phoneNumbers={phoneNumbers}
              handlePhoneNumberChange={handlePhoneNumberChange}
              callBeforeEscalation={callBeforeEscalation}
              isValidationEnable={isValidationEnable}
            />
          )}
        </>
      );
    }
    return null;
  };

  return (
    <>
      <div className={`multi-select-data-${level}`}>
        <div
          className="flex justify-between items-center"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "baseline",
          }}
        >
          <h4 className="text-sm font-semibold mb-3">
            Level {levelNumber} {isPrimary ? "Respondent" : "Escalation"}
          </h4>
          {!isPrimary && formData.detail_levels.length === levelNumber && (
            <Button
              icon="pi pi-trash"
              className="p-button-rounded p-button-danger text-red-500"
              style={{ backgroundColor: "white", border: "0px" }}
              onClick={() => onDelete(level)}
            />
          )}
        </div>

        {!isPrimary && (
          <div>
            <span className="text-sm font-normal mb-1">
              L{levelNumber} Escalation Time{" "}
              <span className="text-xs text-gray-400">(HH:MM:SS)</span>
            </span>
            <Calendar
              id={`${level}-spoc-calendar`}
              style={{ marginTop: "1vh", width: "25%" }}
              placeholder="HH : MM : SS"
              value={levelData.escalation_time || new Date(0, 0, 0, 0, 0, 0)}
              className={`border w-32 h-12 flex justify-center rounded ${isValidationEnable && (levelData.escalation_time === "" ||
                levelData.escalation_time === null)
                ? "border-red-500 rule-notification-sidebar-border-red"
                : "border-gray-400"
                }`}
              onChange={(e) => onTimeChange(e, level)}
              timeOnly
              showSeconds
            />
            {isValidationEnable && (levelData.escalation_time === "" ||
              levelData.escalation_time === null) && (
                <p className="text-red-500 text-xs mt-1">
                  L{levelNumber} Escalation Time field is required
                </p>
              )}
          </div>
        )}

        <div style={{ marginTop: "2vh" }}>
          <span className="text-sm font-normal mb-1">L{levelNumber} Respondent</span>
          <MultiSelect
            value={levelData.users}
            filter
            options={allUserData?.userList}
            onChange={(e) => (isPrimary ? onUserChange(e.value, level) : onUserChange(e, level))}
            placeholder="Select"
            className={`w-full border rounded-md h-12 focus:outline-none focus:ring-2 placeholder:font-normal text-[#6C757D] opacity-60 ${isValidationEnable && levelData.users.length === 0
              ? "border-red-500"
              : "border-gray-400"
              }`}
            selectedItemTemplate={() => null}
            style={{ height: "6vh", marginTop: "1vh", border: "1px solid #d8d4dc" }}
          />
          {isValidationEnable && levelData.users.length === 0 && (
            <p className="text-red-500 text-xs mt-1">
              Level {levelNumber} Respondent field is required
            </p>
          )}
          <div className="flex flex-wrap gap-2">
            {levelData.users.length > 0 && (
              <div className="flex flex-wrap gap-2 mt-2">
                {levelData.users.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0.25rem",
                      padding: "0.25rem",
                      paddingLeft: "0.75rem",
                      paddingRight: "0.75rem",
                      border: "1px solid #E5E7EB",
                      backgroundColor: "#F3F4F6",
                      borderRadius: "1rem",
                      fontSize: "0.875rem",
                      fontWeight: "600",
                      color: "#1F2937",
                    }}
                  >
                    <span>{allUserData?.userList.find((u) => u.value === item)?.label}</span>
                    <button
                      type="button"
                      onClick={() => {
                        const newUsers = levelData.users.filter((spoc) => spoc !== item);
                        isPrimary
                          ? onUserChange(newUsers, level)
                          : onUserChange({ value: newUsers }, level);
                      }}
                      className="text-gray-600 hover:text-gray-700"
                      style={{
                        backgroundColor: "#F3F4F6",
                        border: "0px",
                        fontStyle: "normal",
                        fontWeight: "300",
                        cursor: "pointer",
                      }}
                    >
                      <i
                        style={{
                          fontSize: "0.7rem",
                          paddingTop: "0.25rem",
                        }}
                        className="pi pi-times"
                      ></i>
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        {renderCallBeforeEscalation()}

        <Divider
          style={{
            margin: "0.5rem 0",
            backgroundColor: "#d1d5db",
          }}
        />
      </div>
      {!showNextLevel && levelNumber !== 3 && (
        <Button
          label={`Add L${parseInt(levelNumber) + 1} Respondent`}
          icon="pi pi-plus"
          onClick={onAddNextLevel}
          className="text-title-blue text-sm mt-2 text-blue-500 self-end bg-white border-0 p-0"
        />
      )}
    </>
  );
};

export default EscalationLevel;
