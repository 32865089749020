import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Chip } from "primereact/chip";
import "./index.css";
import React from "react";
import GetUsersList from "hooks/getUsers";
import { useRefresh } from "context/ContextWrapper";
import useSpaces from "hooks/useSpace";
import { Skeleton } from "primereact/skeleton";
import { Toast } from 'primereact/toast';
import { spacesConstants } from "./Constants/SpacesConstants";

/**
 * Component for setting up a new space.
 * Allows users to input space details, select users, and assign permissions.
 *
 * @param {Object} props - Component props
 * @param {boolean} props.visible - Controls the visibility of the sidebar
 * @param {Function} props.setVisible - Function to toggle the visibility of the sidebar
 * @param {Object} props.toast - Reference to the toast notification component
 * @param {string} props.spaceId - The ID of the space being edited
 * @returns {JSX.Element} The NewSpaceSetup component
 */
const NewSpaceSetup = ({ visible, setVisible, spaceId }) => {
  const [spaceName, setSpaceName] = useState("");
  const [description, setDescription] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]); // MultiSelect for users
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [filterText, setFilterText] = useState("");
  const [previousUsers, setPreviousUsers] = useState([]);
  const [tempSelectedUsers, setTempSelectedUsers] = useState([]); // Temporary state for selections
  const [permission, setPermission] = useState(spacesConstants.spaceAccessTypes.READ); // Default can be 'user' or 'editor'
  const [permissionsMap, setPermissionsMap] = useState({}); // Map for user permissions
  const multiSelectRef = useRef(null); // Initialize the ref
  const navigate = useNavigate();
  const { refresh, setRefresh } = useRefresh();
  const {
    createSpace,
    getSpaceById,
    updateSpace,
    addUsersToSpace,
    deleteUsersFromSpace,
    updateUsersInSpace,
  } = useSpaces();
  const [isEditMode, setIsEditMode] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [originalState, setOriginalState] = useState({
    name: "",
    description: "",
  });
  const [newlyAddedUsers, setNewlyAddedUsers] = useState([]);
  const [deletedUsers, setDeletedUsers] = useState([]);
  const [modifiedUsers, setModifiedUsers] = useState([]);
  const [originalUsers, setOriginalUsers] = useState([]);
  const toast = useRef(null);
  const [usersList, setUsersList] = useState([]); // Add state for usersList
  const { getUsers } = GetUsersList(); // Get the getUsers function

  useEffect(() => {
    if (spaceId && visible) {
      setIsEditMode(true);
      fetchSpaceDetails();
    } else {
      setIsEditMode(false);
      resetFields();
    }
  }, [spaceId, visible]);

  // Add useEffect to fetch users on initial render
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await getUsers();
        if (response?.results?.users) {
          setUsersList(response.results.users);
        }
      } catch (error) {
        console.error("Error fetching users:", error);
        toast.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to fetch users list'
        });
      }
    };

    fetchUsers();
  }, []);

  const fetchSpaceDetails = async () => {
    setIsFetching(true);
    try {
      const spaceDetails = await getSpaceById(spaceId);
      setSpaceName(spaceDetails.name);
      setDescription(spaceDetails.description);
      setOriginalState({
        name: spaceDetails.name,
        description: spaceDetails.description,
      });

      const mappedUsers = spaceDetails.users.map((access) => ({
        ...usersList.find((user) => user.id === access.user_id),
        access_type: access.access_type,
      }));
      setOriginalUsers(mappedUsers);
      setSelectedUsers(mappedUsers);

      const newPermissionsMap = {};
      spaceDetails.users.forEach((access) => {
        newPermissionsMap[access.user_id] = access.access_type;
      });
      setPermissionsMap(newPermissionsMap);
    } catch (error) {
      console.error("Error fetching space details:", error);
      toast.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed to fetch space details',
      });
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    if (isEditMode && originalUsers.length > 0) {
      const deletedUsersList = originalUsers
        .filter(
          (originalUser) =>
            !selectedUsers.some((currentUser) => currentUser.id === originalUser.id),
        )
        .map((user) => user.id);
      setDeletedUsers(deletedUsersList);

      const newUsers = selectedUsers
        .filter(
          (currentUser) =>
            !originalUsers.some((originalUser) => originalUser.id === currentUser.id),
        )
        .map((user) => ({
          user_id: user.id,
          access_type: permissionsMap[user.id],
        }));
      setNewlyAddedUsers(newUsers);

      const modifiedUsersList = selectedUsers
        .filter((currentUser) => {
          const originalUser = originalUsers.find((ou) => ou.id === currentUser.id);
          return originalUser && originalUser.access_type !== permissionsMap[currentUser.id];
        })
        .map((user) => ({
          user_id: user.id,
          access_type: permissionsMap[user.id],
        }));
      setModifiedUsers(modifiedUsersList);
    }
  }, [selectedUsers, permissionsMap, isEditMode, originalUsers]);

  /**
   * Handles the submission of the new space form.
   * Validates inputs and sends a request to create a new space.
   */
  const handleSubmit = async () => {
    if (!isFormValid) return;

    setLoading(true);
    try {
      if (isEditMode) {
        // Check if name or description changed
        const nameChanged = originalState.name !== spaceName;
        const descChanged = originalState.description !== description;

        if (nameChanged || descChanged) {
          const updateData = {
            ...(nameChanged ? { name: spaceName } : {}),
            ...(descChanged ? { description } : {}),
          };
          await updateSpace(spaceId, updateData);
        }

        // Handle deleted users
        if (deletedUsers.length > 0) {
          await deleteUsersFromSpace(spaceId, deletedUsers);
        }

        // Handle modified users
        if (modifiedUsers.length > 0) {
          await updateUsersInSpace(spaceId, modifiedUsers);
        }

        // Handle newly added users
        if (newlyAddedUsers.length > 0) {
          const usersToAdd = newlyAddedUsers.map((user) => ({
            user_id: user.user_id,
            access_type: user.access_type,
          }));
          await addUsersToSpace(spaceId, usersToAdd);
        }
        // Show success message for edit
        toast.current?.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Space updated successfully'
        });
      } else {
        // Create new space
        const newSpace = await createSpace({
          name: spaceName,
          description: description,
        });

        // If space creation is successful, proceed with adding users
        if (newSpace?.results?.id) {
          try {
            // Add users to the newly created space
            if (selectedUsers.length > 0) {
              const usersToAdd = selectedUsers.map((user) => ({
                user_id: user.id,
                access_type: permissionsMap[user.id],
              }));

              await addUsersToSpace(newSpace?.results?.id, usersToAdd);
              console.log("Users successfully added to space:", newSpace.id);
            }

            // Show success message for create
            toast.current?.show({
              severity: 'success',
              summary: 'Success',
              detail: 'Space created successfully'
            });
          } catch (userError) {
            console.error("Error adding users to space:", userError);
          }
        } else {
          console.error("Space creation succeeded but no ID was returned");
        }
      }

      setVisible(false);
      setRefresh(!refresh);
      resetFields();
    } catch (error) {
      console.error("error ", error);
      toast.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: error?.response?.data?.message || `Failed to ${isEditMode ? 'update' : 'create'} space`
      });
    } finally {
      setLoading(false);
    }
  };

  /**
   * Updates the permission for a specific user.
   *
   * @param {string} userId - The ID of the user
   * @param {string} newPermission - The new permission to assign
   */
  const updatePermission = (userId, newPermission) => {
    if (isEditMode) {
      const user = selectedUsers.find((u) => u.id === userId);
      if (user?.access_type && user.access_type !== newPermission) {
        setModifiedUsers((prev) => {
          const filtered = prev.filter((u) => u.user_id !== userId);
          return [
            ...filtered,
            {
              user_id: userId,
              access_type: newPermission,
            },
          ];
        });
      }
    }

    setPermissionsMap((prev) => ({
      ...prev,
      [userId]: newPermission,
    }));
  };

  // Checks if the form is valid
  const isFormValid = spaceName.trim() && description.trim();

  // Filter out already selected users
  const availableUsers = usersList?.filter(
    (user) => !selectedUsers.some((selectedUser) => selectedUser.id === user.id)
  );

  const resetFields = () => {
    setTempSelectedUsers([]);
    setSelectedUsers([]);
    setSpaceName("");
    setDescription("");
    setErrors({});
    setPermissionsMap({});
  };

  const handleAddUsers = () => {
    const newUsers = tempSelectedUsers.filter(
      (tempUser) => !selectedUsers.some((selectedUser) => selectedUser.id === tempUser.id),
    );
    if (isEditMode) {
      setNewlyAddedUsers((prev) => [
        ...prev,
        ...newUsers.map((user) => ({
          user_id: user.id,
          access_type: permission,
        })),
      ]);
    }

    setSelectedUsers(tempSelectedUsers);
    setPermissionsMap((prev) => {
      const updatedMap = { ...prev };
      newUsers.forEach((user) => {
        if (!updatedMap[user.id]) {
          updatedMap[user.id] = permission;
        }
      });
      return updatedMap;
    });

    multiSelectRef.current.hide();
  };

  const handleRemoveUser = (userId) => {
    if (isEditMode) {
      const user = selectedUsers.find((u) => u.id === userId);
      if (user?.access_type) {
        setDeletedUsers((prev) => [...prev, userId]);
      }
    }

    setSelectedUsers(selectedUsers.filter((u) => u.id !== userId));
    setPermissionsMap((prevMap) => {
      const newMap = { ...prevMap };
      delete newMap[userId];
      return newMap;
    });
  };

  return (
    <>
      <Toast ref={toast} />
      <Sidebar
        visible={visible}
        position="right"
        className="bg-white w-26rem newspacesetup"
        onHide={() => {
          setVisible(false);
          resetFields();
        }}
        pt={{
          header: { className: "pb-0" },
          content: { className: "p-0" },
        }}
        header={
          <h2 className="text-2xl font-semibold m-0">
            {isEditMode ? "Edit Space" : "New Space Set up"}
          </h2>
        }
      >
        <div>
          <Divider />
          <div className="p-4" style={{ height: "75vh", overflow: "auto", overflowX: "hidden" }}>
            {/* Space Name */}
            <p className="text-sm m-0 mb-2">Space Name</p>
            {isFetching ? (
              <Skeleton height="2.5rem" className="mb-2" />
            ) : (
              <input
                type="text"
                className="p-inputtext w-full"
                value={spaceName}
                maxLength={50}
                onChange={(e) => setSpaceName(e.target.value)}
              />
            )}
            <p className="text-xs text-500 text-right">Character {spaceName.length}/50</p>
            {errors.spaceName && <small className="p-error">{errors.spaceName}</small>}

            {/* Description */}
            <p className="text-sm m-0 mb-2 mt-4">Description</p>
            {isFetching ? (
              <Skeleton height="6rem" className="mb-2" />
            ) : (
              <textarea
                className="p-inputtext w-full"
                rows="4"
                value={description}
                maxLength={250}
                onChange={(e) => setDescription(e.target.value)}
              />
            )}
            <p className="text-xs text-500 text-right">Character {description.length}/250</p>
            {errors.description && <small className="p-error">{errors.description}</small>}

            {/* User Access */}
            <p className="text-sm m-0 mb-2 mt-4">User Access</p>

            <div className="flex flex-col">
              <div className="flex w-full">
                {/* MultiSelect occupying 70% */}
                <div className="w-full">
                  {isFetching ? (
                    <Skeleton height="2.5rem" className="mb-2" />
                  ) : (
                    <MultiSelect
                      ref={multiSelectRef}
                      style={{ height: "6vh", fontSize: "small" }}
                      value={tempSelectedUsers}
                      options={availableUsers}
                      onChange={(e) => setTempSelectedUsers(e.value)}
                      fixedPlaceholder={true}
                      placeholder="Search Users"
                      className="w-full text-sm"
                      filter
                      filterBy="email"
                      optionLabel="email"
                      onShow={() => {
                        setTempSelectedUsers(selectedUsers);
                      }}
                      panelFooterTemplate={
                        <div className="p-2 flex justify-content-end">
                          <Button
                            label="Cancel"
                            className="p-button-sm p-button-text"
                            onClick={() => {
                              resetFields();
                              multiSelectRef.current.hide();
                            }}
                          />
                          <Button label="Add" className="p-button-sm" onClick={handleAddUsers} />
                        </div>
                      }
                    />
                  )}
                </div>
                {/* Dropdown occupying 30% */}
                <div className="w-1/10 ml-2">
                  {isFetching ? (
                    <Skeleton height="2.5rem" className="mb-2" />
                  ) : (
                    <Dropdown
                      options={[
                        { label: "Can Use", value: spacesConstants.spaceAccessTypes.READ },
                        { label: "Can Edit", value: spacesConstants.spaceAccessTypes.WRITE },
                      ]}
                      value={permission} // Manage selected permission state
                      onChange={(e) => setPermission(e.value)} // Update selected permission
                      className="w-full" // Full width inside this container
                      style={{ height: "6vh", fontSize: "small" }}
                      placeholder="Select Permission"
                    />
                  )}
                </div>
              </div>
            </div>

            {errors.selectedUsers && <small className="p-error">{errors.selectedUsers}</small>}

            <div className="flex flex-wrap mt-4 w-full">
              <div className="flex flex-wrap w-full">
                {selectedUsers?.length > 0 && <p className="m-0 text-sm">Users</p>}
                {selectedUsers.map((user) => (
                  <div
                    key={user.id}
                    className="text-sm w-full"
                    style={{
                      display: "grid",
                      gridTemplateColumns: "60% 30% 10%",
                      gap: "0.5rem",
                      alignItems: "center",
                      padding: "0.5rem",
                    }}
                  >
                    <span
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        display: "block",
                      }}
                      className="hover:cursor-default"
                      title={user.email}
                    >
                      {user.email}
                    </span>
                    <Dropdown
                      value={permissionsMap[user.id]}
                      options={[
                        { label: "Can Use", value: spacesConstants.spaceAccessTypes.READ },
                        { label: "Can Edit", value: spacesConstants.spaceAccessTypes.WRITE },
                      ]}
                      onChange={(e) => updatePermission(user.id, e.value)}
                      style={{ height: "6vh", fontSize: "small" }}
                      size="small"
                    />
                    <button
                      onClick={() => {
                        handleRemoveUser(user.id);
                      }}
                      className="p-button p-button-danger p-button-text text-xs px-2"
                    >
                      <i className="pi pi-times"></i>
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <Divider />
        <div className=" flex justify-content-end gap-2 mb-5 mr-3">
          <Button
            label="Cancel"
            className="p-button-text"
            onClick={() => {
              setVisible(false);
              resetFields();
            }}
          />
          <Button
            label={isEditMode ? "Update" : "Set up"}
            className="p-button"
            onClick={handleSubmit}
            loading={loading}
            disabled={!isFormValid}
          />
        </div>
      </Sidebar>
    </>
  );
};

export default NewSpaceSetup;
