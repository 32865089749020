import React, { useState } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { TabView, TabPanel } from 'primereact/tabview';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import NotificationTemplateSidebar from './NotificationTemplateSidebar';
import './index.css';

const ManageTemplatesSidebar = ({ visible, setVisible }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [notificationSidebarVisible, setNotificationSidebarVisible] = useState(false);

  const headerContent = (
    <div>
      <div className="text-lg font-medium text-gray-900 pb-2">
        Templates
      </div>
    </div>
  );

  const RulesTabContent = () => (
    <div className="p-4">
      <div className="flex justify-end mb-4">
        <Button 
          label="Add New Rule Template" 
          icon="pi pi-plus"
          className="bg-blue-500 text-white"
          style={{
            padding: '0.75rem 1rem',
            fontSize: '0.875rem',
            fontWeight: '400'
          }}
          onClick={() => {
            // Add your rule template creation logic here
          }}
        />
      </div>
      <div className="mt-4">
        {/* Rule templates list will go here */}
        <p className="text-gray-500">No rule templates available</p>
      </div>
    </div>
  );

  const NotificationsTabContent = () => (
    <div className="p-0 mt-5">
      <div className="template-content flex justify-end mb-4">
        <Button 
            label="Add New Notification Template" 
            className="template-button border border-blue-400 text-blue-500 bg-white font-semibold"
            onClick={() => {
                setVisible(false); // Close ManageTemplatesSidebar first
                setNotificationSidebarVisible(true);
              }}
        />
      </div>
      <div className="mt-4">
        <p className="text-gray-500">No notification templates available</p>
      </div>
    </div>
  );

  return (
    <>
      <Sidebar
        visible={visible}
        position="right"
        onHide={() => setVisible(false)}
        className="manage-templates-sidebar"
        header={headerContent}
      >
        <div className="mt-4">
          <TabView 
            activeIndex={activeIndex} 
            onTabChange={(e) => setActiveIndex(e.index)}
            className="template-tabs"
          >
            <TabPanel header="Rules">
              <RulesTabContent />
            </TabPanel>
            <TabPanel header="Notifications">
              <NotificationsTabContent />
            </TabPanel>
          </TabView>
        </div>
      </Sidebar>

      <NotificationTemplateSidebar
        visible={notificationSidebarVisible}
        setVisible={setNotificationSidebarVisible}
        setManageTemplatesVisible={setVisible}
      />
    </>
  );
};

export default ManageTemplatesSidebar; 