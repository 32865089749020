import React, { useState, useRef, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { InputText } from "primereact/inputtext";
import { getFileIcon } from "utils/helper";
import { ReactComponent as AddCategory } from "assets/SpaceIcons/AddCategory.svg";
import { RadioButton } from "primereact/radiobutton";
import { ProgressSpinner } from 'primereact/progressspinner';
import "./index.css";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
// import CustomPDFRenderer from "components/DocumentEngine/FileViewer";
import PDFViewerComponent from "components/DocumentEngine/FileViewer/FileViewerComponent";
import useApi  from "services/api";
import { useParams } from "react-router-dom";
import  useSpaces  from "hooks/useSpace";
import LocalDrive from "assets/SpaceIcons/LocalDrive.svg";
const CategoryList = ({ selectedCategory, onCategorySelect }) => {
  const categories = [
    { id: "all", name: "All Documents", total: 10 },
    { id: "onboarding", name: "Onboarding", total: 2 },
    { id: "leave", name: "Leave Policies", total: 3 },
  ];

  return (
    <div className="flex flex-column gap-2">
      {categories.map((category) => (
        <div className="flex flex-column">
          <Button
            key={category.id}
            className={`text-left list-category-button flex align-items-center ${selectedCategory === category.id ? "p-button-primary" : "p-button-text"}`}
            onClick={() => onCategorySelect(category.id)}
          >
            <div className="flex flex-column">
              <div className="flex align-items-center">
                <RadioButton
                  variant="filled"
                  inputId={category.id}
                  checked={selectedCategory === category.id}
                  onChange={() => onCategorySelect(category.id)}
                  className="mr-2"
                  unstyled={true}
                />
                {category.name}
              </div>
              <span className="text-sm text-gray-500 mt-2">{category.total} Files</span>
            </div>
          </Button>
        </div>
      ))}
      {/* <Button
        className="p-button-outlined mt-3 add-category-button"
        icon={<AddCategory />}
        label="Add/Edit Categories"
      ></Button> */}
    </div>
  );
};

const tableStyles = {
  wrapper: {
    position: "relative",
    maxHeight: "fit-content",
  },
  header: {
    position: "sticky",
    top: 0,
    // backgroundColor: 'white',
    zIndex: 1,
  },
  body: {
    overflowY: "auto",
    maxHeight: "calc(400px - 43px)",
  },
};

const DocumentTable = ({ documents, selectedCategory, setDocuments, loading }) => {
  const { spaceId } = useParams();
  const { getFilesFolders } = useSpaces();
  const [expandedRows, setExpandedRows] = useState(null);
  const [showSummaryDialog, setShowSummaryDialog] = useState(false);
  const [selectedSummary, setSelectedSummary] = useState("");
  const [hoveredRowId, setHoveredRowId] = useState(null);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [documentToDelete, setDocumentToDelete] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const toast = useRef(null);
  const api = useApi();
  const [expandedLoading, setExpandedLoading] = useState({});

  const handlePreviewClick = async (rowData) => {
    try {
      // First fetch the file to ensure it exists
      // const response = await api.get(rowData.fileUrl);
      // console.log(response, "response");
      setSelectedDocument([
        { 
          // uri: rowData.fileUrl,
          uri: 'https://pdfobject.com/pdf/sample.pdf',
          fileName: rowData.fileName || 'document.pdf'
        }
      ]);
      setShowPreview(true);
    } catch (error) {
      if (!error.cancelled) {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to load document preview',
          life: 3000
        });
      }
    }
  };

  const allowExpansion = (rowData) => {
    // Allow expansion if the item is a folder AND has any direct children
    return rowData.isFolder;
  };

  const onRowExpand = async (event) => {
    try {
      // Set loading state for this specific row
      setExpandedLoading(prev => ({...prev, [event.data.id]: true}));
      
      const expandedData = await getFilesFolders(spaceId, event.data.id);
      setDocuments(prevData => {
        const filteredData = prevData.filter(item => item.parentFolder !== event.data.id);
        return [...filteredData, ...expandedData];
      });
    } catch (error) {
      console.error("Error fetching folder contents:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to fetch folder contents",
        life: 3000,
      });
    } finally {
      // Clear loading state for this row
      setExpandedLoading(prev => ({...prev, [event.data.id]: false}));
    }
  };

  const onRowCollapse = (event) => {
    toast.current.show({
      severity: "success",
      summary: "Folder Collapsed",
      detail: event.data.fileName,
      life: 3000,
    });
  };
  
  const header = (
    <div className="data-content-header flex flex-col justify-content-end">
      <div className="search-bar flex align-items-center justify-center bg-white border border-gray-300 rounded-lg">
        <span className="p-input-icon-left">
          <i className="pi pi-search pl-3 font-extralight text-[#6C757D] opacity-80" />
        </span>
        <InputText
          type="search"
          className="pl-10 pr-5 pt-3 pb-3 placeholder:font-normal text-[#ced4da] ml-5 space-search-component border-0"
          placeholder="Search"
          // value={searchText}
          // onChange={(e) => setSearchInput(e.target.value)}
        />
      </div>
      <Button
        icon="pi pi-folder bg-white color-[#0ea5e9]"
        severity="info"
        label="New Folder"
        iconPos="right"
      />
      <Button
        icon="pi pi-refresh bg-white color-[#0ea5e9]"
        severity="info"
        label="Categorize Now"
        iconPos="right"
      />
    </div>
  );

  const documentCategoryTemplate = (rowData) => {
    if (rowData?.isFolder) {
      return null;
    }

    return rowData?.documentCategory?.includes("uncategorized") ? (
      <div className="flex flex-row align-items-center">
        <div>
          <DottedSpinner />
        </div>
        <span className="text-sm ml-2">Uncategorized</span>
      </div>
    ) : (
      rowData.documentCategory.map((category, index) => (
        <span key={index} className="text-sm ml-4">
          {category}
          {index < rowData.documentCategory.length - 1 ? ", " : ""}
        </span>
      ))
    );
  };
  const sourceBodyTemplate = (rowData) => {
    return !rowData?.isFolder ? (
      <div className="flex align-items-center">
        <img src={LocalDrive} alt="source" className="w-2 h-2" />
        <div className='ml-2'>{rowData?.source?.type}</div>
      </div>
    ) : null;
  };

  const statusBodyTemplate = (rowData) => {
    if(rowData?.isFolder) return null;
    
    if (!rowData.isFolder) {
      return (
        <div>
          <div>{rowData?.status?.state}</div>
          <div className="text-sm text-gray-500">{rowData?.status?.date}</div>
        </div>
      );
    }

    const isHovered = hoveredRowId === rowData.id;

    return (
      <div className="status-cell flex justify-content-between align-items-center">
        {isHovered ? (
          <div className="row-hovered ml-1">
            <i className="pi pi-pencil"></i>
            <i className="pi pi-eye ml-2" onClick={() => handlePreviewClick(rowData)}></i>
            <i className="pi pi-file ml-2"></i>
            <i
              className="pi pi-trash ml-4 text-red-500 cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                setDocumentToDelete(rowData);
                setDeleteDialogVisible(true);
              }}
            ></i>
          </div>
        ) : (
          <div>
            <div>{rowData?.status?.state}</div>
            <div className="text-sm text-gray-500">{rowData?.status?.date}</div>
          </div>
        )}
      </div>
    );
  };

  const fileNameTemplate = (rowData) => {
    return (
      <div className="flex align-items-center">
        {/* <i className={`pi ${!rowData?.isFolder && "pi-file"} mr-2`}></i> */}
        {!rowData?.isFolder && <i className={`${getFileIcon(rowData?.fileName)} mr-2`}></i>}
        <span className={`summary-text ${rowData?.isFolder && "font-bold"}`}>{rowData?.fileName}</span>
      </div>
    );
  };

  const summaryBodyTemplate = (rowData) => {
    const handleShowFullSummary = (e) => {
      e.stopPropagation(); // Prevent row expansion when clicking the icon
      setSelectedSummary(rowData.summary);
      setShowSummaryDialog(true);
    };

    return !rowData.isFolder ? (
      <div className="flex align-items-center">
        <div style={{ width: "80%" }}>
          <span className="summary-text">{rowData?.summary}</span>
        </div>
        <i
          className="pi pi-chevron-down ml-2 cursor-pointer"
          onClick={handleShowFullSummary}
          style={{ flexShrink: 0 }}
        ></i>
      </div>
    ) : (
      <></>
    );
  };

  const dialogFooter = (
    <div>
      <Divider className="text-[#DEE2E6]" />
      <Button label="Okay" onClick={() => setShowSummaryDialog(false)} />
    </div>
  );

  const SkeletonRow = () => {
    return (
      <div className="flex align-items-center gap-3 p-2">
        <div className="w-3rem h-2rem bg-gray-200 border-round"></div>
        <div className="w-8 h-2rem bg-gray-200 border-round"></div>
        <div className="w-8 h-2rem bg-gray-200 border-round"></div>
        <div className="w-8 h-2rem bg-gray-200 border-round"></div>
        <div className="w-3rem h-2rem bg-gray-200 border-round"></div>
      </div>
    );
  };

  const rowExpansionTemplate = (data) => {
    const childDocuments = documents.filter((doc) => doc.parentFolder === data.id);
    const filteredChildren = selectedCategory === "all"
      ? childDocuments
      : childDocuments.filter((doc) => {
          if (doc.isFolder) {
            return documents.some(
              (child) =>
                child.parentFolder === doc.id &&
                child.documentCategory.includes(selectedCategory),
            );
          }
          return doc.documentCategory.includes(selectedCategory);
        });

    return expandedLoading[data.id] ? (
      <div className="p-2">
        <SkeletonRow />
        <SkeletonRow />
      </div>
    ) : (
      <DataTable
        value={filteredChildren}
        className="p-datatable-sm"
        showHeaders={false}
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        onRowExpand={onRowExpand}
        onRowCollapse={onRowCollapse}
        rowExpansionTemplate={rowExpansionTemplate}
        dataKey="id"
        selectionMode="single"
        onRowMouseEnter={(e) => setHoveredRowId(e.data.id)}
        onRowMouseLeave={() => setHoveredRowId(null)}
      >
        <Column expander={allowExpansion} style={{ width: "3em" }} />
        <Column body={fileNameTemplate} />
        <Column body={sourceBodyTemplate} />
        <Column body={summaryBodyTemplate} />
        <Column
          header="Document Category"
          body={documentCategoryTemplate}
          headerStyle={tableStyles.header}
        />
        <Column body={statusBodyTemplate} />
      </DataTable>
    );
  };

  const DocumentPreviewDialog = () => {
    return (
      <>
        {showPreview && selectedDocument && (
          <PDFViewerComponent 
            fileUrl={selectedDocument[0].uri}
            fileName={selectedDocument[0].fileName}
            onClose={() => {
              setShowPreview(false);
              setSelectedDocument(null);
            }}
          />
        )}
      </>
    );
  };

  const parentDocuments = documents.filter((doc) => {
    if (doc.parentFolder !== null) return false;
    if (selectedCategory === "all") return true;

    // Check if this parent folder has any matching descendants
    return documents.some(
      (child) =>
        (child.parentFolder === doc.id && child.documentCategory.includes(selectedCategory)) ||
        documents.some(
          (grandChild) =>
            grandChild.parentFolder === child.id &&
            grandChild.documentCategory.includes(selectedCategory),
        ),
    );
  });

  const rowClassName = (rowData) => {
    return {
      "cursor-pointer": !rowData.isFolder,
      "hoverable-row": !rowData.isFolder,
    };
  };

  // const actionBodyTemplate = (rowData) => {
  //   return (
  //     <div className="flex gap-2">
  //       <Button
  //         icon="pi pi-eye"
  //         rounded
  //         outlined
  //         className="p-button-text"
  //         onClick={() => handlePreviewClick(rowData)}
  //       />
  //       {/* ... other action buttons ... */}
  //     </div>
  //   );
  // };

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        header="Summary"
        visible={showSummaryDialog}
        style={{ width: "50vw" }}
        onHide={() => setShowSummaryDialog(false)}
        footer={dialogFooter}
        draggable={false}
      >
        <p className="m-0">{selectedSummary}</p>
      </Dialog>
      <Dialog
        visible={deleteDialogVisible}
        onHide={() => setDeleteDialogVisible(false)}
        header="Confirmation"
        modal
        draggable={false}
        style={{ width: "50vw" }}
        footer={
          <div className="flex justify-content-end">
            <Button
              label="Yes"
              icon="pi pi-check"
              severity="danger"
              onClick={() => {
                // Add delete logic here
                setDeleteDialogVisible(false);
                setDocumentToDelete(null);
              }}
              autoFocus
            />
            <Button
              label="No"
              icon="pi pi-times"
              severity="secondary"
              onClick={() => setDeleteDialogVisible(false)}
              outlined
            />
          </div>
        }
      >
        <div className="inline-flex items-baseline gap-2">
          <i className="pi pi-exclamation-triangle mt-3 text-black text-3xl" />
          <p className="text-gray-600 font-light text-base">Are you sure you want to delete it?</p>
        </div>
      </Dialog>
      <div style={tableStyles.wrapper}>
        {loading ? (
          <div className="p-2">
            <SkeletonRow />
            <SkeletonRow />
            <SkeletonRow />
          </div>
        ) : (
          <DataTable
            value={parentDocuments}
            expandedRows={expandedRows}
            onRowToggle={(e) => setExpandedRows(e.data)}
            onRowExpand={onRowExpand}
            onRowCollapse={onRowCollapse}
            rowExpansionTemplate={rowExpansionTemplate}
            dataKey="id"
            header={header}
            className="p-datatable-sm"
            scrollable
            scrollHeight="400px"
            tableStyle={{ minWidth: "50rem" }}
            rowClassName={rowClassName}
            selectionMode="single"
            onRowMouseEnter={(e) => setHoveredRowId(e.data.id)}
            onRowMouseLeave={() => setHoveredRowId(null)}
          >
            <Column expander={allowExpansion} style={{ width: "3em" }} />
            <Column body={fileNameTemplate} header="File Name" headerStyle={tableStyles.header} />
            <Column header="Source" body={sourceBodyTemplate} headerStyle={tableStyles.header} />
            <Column header="Summary" body={summaryBodyTemplate} headerStyle={tableStyles.header} />
            <Column
              header="Document Category"
              body={documentCategoryTemplate}
              headerStyle={tableStyles.header}
            />
            <Column
              header="Indexing Status"
              body={statusBodyTemplate}
              headerStyle={tableStyles.header}
            />
            {/* <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '8rem' }} /> */}
          </DataTable>
        )}
        <DocumentPreviewDialog />
      </div>
    </>
  );
};

const DottedSpinner = () => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="7.99937" cy="1.9359" r="1.4359" fill="#2D00F7"/>
    <circle cx="7.99937" cy="15.0643" r="1.4359" fill="#BFBFBF"/>
    <circle cx="1.4359" cy="8.50014" r="1.4359" transform="rotate(-90 1.4359 8.50014)" fill="#BFBFBF"/>
    <circle cx="14.5638" cy="8.50014" r="1.4359" transform="rotate(-90 14.5638 8.50014)" fill="#BFBFBF"/>
    <circle cx="3.35781" cy="3.8584" r="1.4359" transform="rotate(-45 3.35781 3.8584)" fill="#BFBFBF"/>
    <circle cx="12.641" cy="13.1416" r="1.4359" transform="rotate(-45 12.641 13.1416)" fill="#BFBFBF"/>
    <circle cx="12.6416" cy="3.8583" r="1.4359" transform="rotate(45 12.6416 3.8583)" fill="#BFBFBF"/>
    <circle cx="3.3584" cy="13.1415" r="1.4359" transform="rotate(45 3.3584 13.1415)" fill="#BFBFBF"/>
  </svg>
);

const DataContent = () => {
  const [selectedCategory, setSelectedCategory] = useState("all");
  const { spaceId } = useParams();
  const [documents, setDocuments] = useState([]);
  const { getFilesFolders } = useSpaces();
  const toast = useRef(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        setLoading(true);
        const data = await getFilesFolders(spaceId);
        setDocuments(data);
      } catch (error) {
        console.error("Error fetching documents:", error);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Failed to fetch documents",
          life: 3000,
        });
      } finally {
        setLoading(false);
      }
    };

    if (spaceId) {
      fetchDocuments();
    }
  }, [spaceId]);

  const filteredDocuments =
    selectedCategory === "all"
      ? documents
      : documents.filter((doc) => {
          // Keep parent folders that have matching children
          if (doc.isFolder) {
            const hasMatchingChildren = documents.some(
              (child) =>
                child.parentFolder === doc.id && child.documentCategory.includes(selectedCategory),
            );
            return hasMatchingChildren;
          }
          // Keep files that match the category
          return doc.documentCategory.includes(selectedCategory);
        });

  return (
    <div className="grid">
      <Toast ref={toast} />
      <div className="col-2 data-content-category-list">
        <CategoryList selectedCategory={selectedCategory} onCategorySelect={setSelectedCategory} />
      </div>
      <div className="col-10 data-content-table">
        <DocumentTable 
          documents={filteredDocuments} 
          selectedCategory={selectedCategory} 
          setDocuments={setDocuments}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default DataContent;