import { useSharedState } from "context/ContextWrapper";
import useSDAIDashboard from "hooks/useSDAIDashboard";
import moment from "moment";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { SelectButton } from "primereact/selectbutton";
import { Skeleton } from "primereact/skeleton";
import { Toast } from "primereact/toast";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./index.css";

const ONE_DAY_RANGE = `${moment().format("YYYY-MM-DD")},${moment().format("YYYY-MM-DD")}`;
const SEVEN_DAY_RANGE = `${moment().subtract(7, "days").format("YYYY-MM-DD")},${moment().format("YYYY-MM-DD")}`;

/**
 * Dashboard component displays the main dashboard view.
 * It shows statistics and tables based on user role.
 */
const Dashboard = () => {
  const { sharedState } = useSharedState();
  const { getDashboardStats, getAdminDashboardStats, getUsersDashboardStats } = useSDAIDashboard();
  const [dashboardStats, setDashboardStats] = useState({});
  const toastRef = React.useRef(null);

  const [usersDateRange, setUsersDateRange] = useState(SEVEN_DAY_RANGE);
  const [selectedUsersRange, setSelectedUsersRange] = useState("7D");

  const [dashboardDateRange, setDashboardDateRange] = useState(SEVEN_DAY_RANGE);
  const [selectedDashboardRange, setSelectedDashboardRange] = useState("7D");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        if (sharedState === "admin") {
          getAdminDashboardStats(dashboardDateRange).then((data) => {
            setDashboardStats((prev) => ({
              ...prev,
              ...data.results,
            }));
            setLoading(false);
          });
          getUsersDashboardStats(usersDateRange).then((data) => {
            setDashboardStats((prev) => ({
              ...prev,
              user_stats: data.results,
            }));
            setLoading(false);
          });
        } else {
          getDashboardStats(dashboardDateRange).then((data) => {
            setDashboardStats(data.results);
            setLoading(false);
          });
        }
      } catch (e) {
        console.error(e);
        toastRef.current.show({
          severity: "error",
          summary: "Error",
          detail: "Failed to fetch dashboard data",
        });
      }
    })();
  }, [sharedState, dashboardDateRange, usersDateRange]);

  return (
    <div className={`text-800 workspaces px-5 dashboard-container`}>
      <Toast ref={toastRef} />
      <div className={`col-12 pr-2 pt-4 overflow-y-auto layout-container`}>
        <section className="text-sm px-3 h-full">
          <AdminHeader
            dateRange={dashboardDateRange}
            setDateRange={setDashboardDateRange}
            selectedRange={selectedDashboardRange}
            setSelectedRange={setSelectedDashboardRange}
            title="Dashboard"
          />
          <>
            <div className="flex tasks-container gap-4 mt-4">
              <StatsCard
                number={dashboardStats?.total_jobs || 0}
                text="Total No. of tasks"
                type="all"
                loading={loading}
              />
              <StatsCard
                number={dashboardStats?.completed_jobs || 0}
                text="Tasks Completed"
                type="Completed"
                loading={loading}
              />
              <StatsCard
                number={dashboardStats?.terminated_jobs || 0}
                text="Tasks Terminated"
                type="Terminated"
                loading={loading}
              />
              <StatsCard
                number={dashboardStats?.error_jobs || 0}
                text="Task with Error"
                type="Error"
                loading={loading}
              />
            </div>
            {sharedState === "admin" && (
              <div className="flex flex-row border-round-md mt-5 gap-3">
                <div className="w-full p-3 shadow-none surface-border border-1 bg-white border-round-md">
                  <AdminHeader
                    dateRange={usersDateRange}
                    setDateRange={setUsersDateRange}
                    selectedRange={selectedUsersRange}
                    setSelectedRange={setSelectedUsersRange}
                    title="Active Users"
                  />
                  <DataTable
                    value={dashboardStats?.user_stats || []}
                    className="mt-4 h-20rem"
                    emptyMessage="No users found"
                    loading={loading}
                    scrollable
                    scrollHeight="flex"
                  >
                    <Column field="username" header="User Name" style={{ width: "65%" }} sortable />
                    <Column field="total_tasks" header="Tasks Performed" sortable />
                  </DataTable>
                </div>
                {/* Todo */}
                {/* <div className="w-full p-3 shadow-none surface-border border-1 bg-white border-round-md">
                <AdminHeader
                  selectedRange={selectedRange}
                  setSelectedRange={setSelectedRange}
                  title="Credit Usage"
                />

                <DataTable
                  value={[
                    {
                      user_name: "Thomas Anderson",
                      credit_used: 60,
                    },
                    {
                      user_name: "Christopher Moore",
                      credit_used: 210,
                    },
                    {
                      user_name: "Daniel Taylor",
                      credit_used: 40,
                    },
                    {
                      user_name: "Patricia Smith",
                      credit_used: 90,
                    },
                  ]}
                  className="mt-4"
                >
                  <Column field="user_name" header="User Name" style={{ width: "65%" }} />
                  <Column field="credit_used" header="Credit Used" />
                </DataTable>
              </div> */}
              </div>
            )}
          </>
          {/* )} */}
        </section>
      </div>
    </div>
  );
};

/**
 * AdminHeader component for displaying a header with a select button.
 * @param {Object} props - Component props
 * @param {string} props.dateRange - Currently selected date range
 * @param {Function} props.setDateRange - Function to update selected date range
 * @param {string} props.selectedRange - Currently selected range option
 * @param {Function} props.setSelectedRange - Function to update selected range option
 * @param {string} props.title - Title of the header
 */
const AdminHeader = ({ dateRange, setDateRange, title, selectedRange, setSelectedRange }) => {
  const [showCalendar, setShowCalendar] = React.useState(false);
  const [customDateRange, setCustomDateRange] = React.useState(null);
  let prevSelectedRange = "";
  return (
    <div className="flex flex-row justify-content-between">
      <div className="flex flex-row align-items-center gap-2">
        <h2 className="font-semibold m-0 text-lg">{title}</h2>
        {/* <span className="pi pi-cog cursor-pointer"></span> */}
      </div>
      <SelectButton
        pt={{
          button: {
            className: "text-500 font-semibold text-sm",
          },
        }}
        size={"small"}
        value={selectedRange}
        onChange={(e) => {
          if (e.value === null) {
            return;
          }
          prevSelectedRange = selectedRange;
          if (e.value === "custom") {
            setSelectedRange("custom");
            setShowCalendar(true);
          } else {
            setCustomDateRange(null);
            setSelectedRange(e.value);
            setShowCalendar(false);
            if (e.value === "1D") {
              setDateRange(ONE_DAY_RANGE);
            }
            if (e.value === "7D") {
              setDateRange(SEVEN_DAY_RANGE);
            }
          }
        }}
        options={[
          {
            label: "1D",
            value: "1D",
          },
          {
            label: "7D",
            value: "7D",
          },
          { icon: "pi pi-calendar", value: "custom", onClick: () => setShowCalendar(true) },
        ]}
        itemTemplate={(option) =>
          option?.icon ? <i className="pi pi-calendar" /> : <span>{option.label}</span>
        }
      />
      <Dialog
        className="w-4"
        visible={showCalendar}
        onHide={() => setShowCalendar(false)}
        pt={{
          content: { className: "p-0" },
        }}
        header={"Custom Date Range"}
      >
        <Calendar
          value={customDateRange}
          onChange={(e) => setCustomDateRange(e.value)}
          selectionMode="range"
          readOnlyInput
          showIcon
          className="credit w-full"
          dateFormat="y/m/d"
          inline
        />
        <div className="p-2 flex justify-content-end gap-2">
          <Button
            label="Cancel"
            outlined
            onClick={() => {
              setShowCalendar(false);
              setSelectedRange(prevSelectedRange);
              setCustomDateRange(null);
            }}
          />
          <Button
            label="Apply"
            onClick={() => {
              if (customDateRange) {
                if (customDateRange.length === 1) {
                  const formattedDate = moment(customDateRange[0]).format("YYYY-MM-DD");
                  setDateRange(`${formattedDate},${formattedDate}`);
                } else if (customDateRange.length === 2) {
                  const formattedDate = customDateRange.map((date) =>
                    moment(date).format("YYYY-MM-DD"),
                  );
                  setDateRange(formattedDate.join(","));
                }
                setShowCalendar(false);
              }
            }}
          />
        </div>
      </Dialog>
    </div>
  );
};

AdminHeader.propTypes = {
  selectedRange: PropTypes.string,
  setSelectedRange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  dateRange: PropTypes.string.isRequired,
  setDateRange: PropTypes.func.isRequired,
};

/**
 * StatsCard component for displaying a statistic card.
 * @param {Object} props - Component props
 * @param {number} props.number - Number to display
 * @param {string} props.text - Description text
 * @param {string} props.type - Type of the card
 * @param {boolean} props.loading - Loading state
 */
const StatsCard = ({ number, text, type, loading }) => {
  const navigate = useNavigate();
  if (loading) {
    return <Skeleton height="10rem" width="100%"></Skeleton>;
  }
  return (
    <div className="flex flex-column flex-1 p-5 border-round-md">
      <h2 className="m-0 text-3xl">{number}</h2>
      <p className="flex justify-content-between m-0 align-items-center">
        <p className="text-sm m-0">{text}</p>{" "}
        <Button
          size="small"
          icon="pi pi-chevron-right"
          className="bg-white p-2 text-500 border-round-md border-200"
          onClick={() => {
            navigate(`/intelliapp/workspaces${type !== "all" ? `?type=${type}` : ""}`);
          }}
        ></Button>
      </p>
    </div>
  );
};

StatsCard.propTypes = {
  number: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default Dashboard;
