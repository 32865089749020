import { Routes, Route } from "react-router-dom";
import NotAccessible from "./NotAccessible";
import Workspace from "pages/SDAI/Workspaces/Workspace";
import Templates from "pages/SDAI/Templates/Templates";
import IntelliappLayout from "layouts/IntelliappLayout";
import CreditSummary from "pages/Admin/CreditSummary/CreditSummary";
import CreditSummaryList from "pages/Admin/CreditSummary/CreditSummaryList";
import CreditsHistoryList from "pages/Admin/CreditSummary/CreditsHistoryList";
import UserManagement from "pages/Admin/Usermangement/Usermanagment";
import UserProfile from "components/DocumentEngine/UserManagement/UserProfile";
import NotFound from "pages/404";
import Dashboard from "pages/SDAI/Dashboard/index";
import { useSharedState } from "context/ContextWrapper";
const SDAIRoutes = ({ applicationAccess }) => {
  const { sharedState } = useSharedState();
  if (applicationAccess?.includes("documentengine")) {
    return (
      <Routes>
        <Route exact path="/" element={<IntelliappLayout />}>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="templates" element={<Templates />} />
          <Route path="workspaces" element={<Workspace />} />
          {/* <Route path="settings/credits" element={<CreditSummary />} />
          <Route path="settings/credits-list" element={<CreditSummaryList />} />
          <Route path="settings/credits-org-history" element={<CreditsHistoryList />} /> */}
          {sharedState === "admin" && (
            <>
              <Route path="settings/usersmanagement" element={<UserManagement />} />
              <Route path="settings/user/:id" element={<UserProfile />} />
            </>
          )}
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    );
  } else {
    return <NotAccessible />;
  }
};

export default SDAIRoutes;