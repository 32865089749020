import React, { useState, useEffect, useRef, Fragment } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { Chip } from "primereact/chip";
import "../../DocumentEngine/TemplateSection/newTemplate.css";
import { TieredMenu } from "primereact/tieredmenu";
import { Toast } from "primereact/toast";
import moment from "moment";
import GetUsersList from "hooks/getUsers";
import { Dialog } from "primereact/dialog";
import useMediaQuerry from "hooks/useMediaQuerry";
import { useNavigate } from "react-router-dom";
import { Paginator } from "primereact/paginator";
import { useDebounce } from "primereact/hooks";
import { useRefresh } from "context/ContextWrapper";

export default function UserPanels() {
  const toast = useRef(null);
  const menu = useRef(null);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [expandedRows, setExpandedRows] = useState({});
  const [selectedUsers, setSelectedUsers] = useState(null);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const { deactivateUser, loading, getUsersList } = GetUsersList();
  const [usersPagination, setUsersPagination] = useState(null);
  const [email, debouncedEmail, setEmail] = useDebounce("", 400);
  const { refresh, setRefresh } = useRefresh();

  const fetchUsers = async () => {
    try {
      const data = await getUsersList(
        currentPage,
        rowsPerPage,
        debouncedEmail,
        sortField,
        sortOrder,
      );
      if (data.success && data.results && data.results.data) {
        setData(data.results.data);
        setUsersPagination(data.results.pagination);
      }
    } catch (err) {
      console.error(err);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Please try again later",
        life: 3000,
      });
    }
  };

  function handleOnSort(event) {
    const { sortField, sortOrder } = event;
    setSortField(sortField);
    const order = sortOrder === 1 ? "desc" : "asc";
    setSortOrder(order);
    getUsersList(currentPage, rowsPerPage, debouncedEmail, sortField, order);
  }

  useEffect(() => {
    fetchUsers();
  }, [currentPage, rowsPerPage, debouncedEmail, refresh]);

  const renderHeader = () => {
    return (
      <div className="flex items-center w-full">
        <div className="flex-1 items-center">
          <span className="text-lg font-bold">{usersPagination?.total || 0} Users</span>
        </div>
        <div className="flex-2 flex justify-end">
          <IconField iconPosition="left">
            <InputIcon className="pi pi-search text-sm" />
            <InputText
              value={email}
              className="text-sm"
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Search Email"
            />
          </IconField>
        </div>
      </div>
    );
  };

  const header = renderHeader();

  const handleExpandClick = (rowIndex) => {
    setExpandedRows((prev) => ({
      ...prev,
      [rowIndex]: !prev[rowIndex],
    }));
  };

  const associatedGroupsTemplate = (rowData) => {
    const { groups, id } = rowData;
    const accessToLength = groups && groups.length > 1;
    const isExpanded = expandedRows[id];

    if (isExpanded && accessToLength) {
      return groups.map((groupName, index) => (
        <Chip key={index} label={groupName} className="accessto text-sm" />
      ));
    } else {
      return (
        <>
          <Chip label={accessToLength ? groups[0] : groups?.[0]} className="accessto text-lg" />
          {accessToLength && (
            <Chip
              label={`+${groups.length - 1}`}
              className="accessto cursor-pointer text-lg"
              onClick={() => handleExpandClick(id)}
            />
          )}
        </>
      );
    }
  };

  const handleDeactivateUser = async () => {
    try {
      const result = await deactivateUser(selectedUsers?.id);

      if (result?.status) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "User deactivated successfully!",
        });
        setShowDeactivateModal(false);
        fetchUsers();
      }
    } catch (e) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: e?.response?.data?.message || "Something went wrong. Please try again later.",
      });
    }
  };
  const handlePreviewClick = (rowData) => {
    navigate(`/user/${rowData.id}`);
  };

  const actionTemplate = (rowData) => {
    const items = [
      {
        label: "Deactivate",
        command: () => {
          setShowDeactivateModal(true);
        },
      },
    ];
    const previewIcon = (
      <Button
        type="button"
        icon={"pi pi-eye"}
        style={{ color: "#3B82F6" }}
        onClick={() => {
          handlePreviewClick(rowData);
        }}
        className="p-button-rounded p-button-text p-button-secondary  w-2rem h-2rem"
      />
    );

    const moreMenu = (
      <div className="card flex justify-content-center">
        <TieredMenu
          model={items}
          popup
          ref={menu}
          breakpoint="767px"
          className="max-w-10rem text-sm"
        />
        <Button
          type="button"
          icon={"pi pi-ellipsis-v"}
          onClick={(e) => {
            menu.current.toggle(e);
            setTimeout(() => {
              menu?.current
                ?.getElement()
                ?.children?.[0]?.children?.[0]?.classList?.remove("p-focus");
            }, 100);
            setSelectedUsers(rowData);
          }}
          className="p-button-rounded p-button-text p-button-secondary w-2rem h-2rem"
        />
      </div>
    );

    return (
      <div className="text-right inline-flex">
        {previewIcon} {moreMenu}
      </div>
    );
  };

  const onBoardedOnTemplate = (rowData) => {
    const { created_at } = rowData;
    const timestamp = moment(created_at).format("DD/MM/YYYY");

    const userOnboardedOn = (
      <div className="flex flex-column">
        <span className="text-xs text-color-secondary mt-1">{timestamp}</span>
      </div>
    );
    return userOnboardedOn;
  };

  const getStatusColor = (status) => {
    return status ? "bg-green-500" : "bg-yellow-500";
  };

  const statusTemplate = (rowData) => {
    const is_active = rowData.is_active;
    return (
      <Chip
        label={
          <div className="flex align-items-center">
            <span
              style={{
                width: "8px",
                height: "8px",
                borderRadius: "50%",
                backgroundColor: getStatusColor(is_active),
                display: "inline-block",
              }}
              className={`mr-2 ${getStatusColor(is_active)}`}
            ></span>
            {is_active ? "Active" : "Inactive"}
          </div>
        }
        className="text-xs"
      />
    );
  };

  const handlePageChange = (e) => {
    setCurrentPage(e.page + 1);
    setRowsPerPage(e.rows);
  };

  return (
    <Fragment>
      <Toast ref={toast} />
      <div className="card my-5 px-3 templateTable" style={{ height: "60vh" }}>
        <DataTable
          value={data}
          rowsPerPageOptions={[5, 10, 20]}
          onPage={handlePageChange}
          first={(currentPage - 1) * rowsPerPage}
          loading={loading}
          inSort={false}
          tableStyle={{ minWidth: "50rem" }}
          header={header}
          sortField={sortField}
          sortOrder={sortOrder === "desc" ? 1 : -1}
          onSort={handleOnSort}
          emptyMessage="No users found"
          className="w-full"
          scrollable
          scrollHeight="flex"
          selection={selectedUsers}
          onSelectionChange={(e) => setSelectedUsers(e.value)}
          dataKey="id"
          selectionMode={"checkbox"}
        >
          <Column field="email" header="User Email" sortable style={{ width: "27.5%" }}></Column>
          <Column
            field="groups"
            header="Associated Groups"
            body={associatedGroupsTemplate}
            style={{ width: "27.5%" }}
          ></Column>
          <Column
            field="created_at"
            header="Onboarded on"
            body={onBoardedOnTemplate}
            style={{ width: "20%" }}
            sortable
          ></Column>
          <Column
            field="status"
            header="Status"
            style={{ width: "20%" }}
            body={statusTemplate}
          ></Column>
          <Column
            field="actions"
            header="Actions"
            style={{ width: "5%" }}
            className="w-full"
            body={(rowData) => actionTemplate(rowData)}
          ></Column>
        </DataTable>
        <Paginator
          first={(currentPage - 1) * rowsPerPage || 0}
          rows={rowsPerPage}
          totalRecords={usersPagination?.total || 0}
          className="text-sm"
          template={{
            layout:
              "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
          }}
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          rowsPerPageOptions={[10, 20, 30]}
          onPageChange={handlePageChange}
        ></Paginator>
      </div>
      <DeactivateUserDialog
        visible={showDeactivateModal}
        setIsVisible={setShowDeactivateModal}
        handleOnClick={handleDeactivateUser}
      />
    </Fragment>
  );
}

export const DeactivateUserDialog = ({ visible, setIsVisible, handleOnClick }) => {
  const { isMobile } = useMediaQuerry();
  return (
    <Dialog
      visible={visible}
      onHide={() => setIsVisible(false)}
      header="Deactivate User"
      style={{ width: isMobile ? "80vw" : "37vw" }}
      modal
      footer={
        <div className="flex justify-content-end">
          <Button
            label="Yes"
            icon="pi pi-check"
            severity="danger"
            onClick={handleOnClick}
            autoFocus
          />
          <Button
            label="No"
            icon="pi pi-times"
            severity="secondary"
            onClick={() => setIsVisible(false)}
            outlined
          />
        </div>
      }
    >
      <div className="inline-flex  items-baseline gap-2	">
        <i className="pi pi-exclamation-triangle mt-3 text-black text-3xl" />
        <p className="text-gray-600 font-light text-base deactivate-content">
          Deactivating this user will revoke all access to the app. Any remaining credits will be
          rolled back to the organization's account. Are you sure you want to proceed?
        </p>
      </div>
    </Dialog>
  );
};
