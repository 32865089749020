import { API_BASE_NEIO, API_URL } from "../constants";
import useApi from "services/api";

const useAliasConfiguration = () => {
  const { get, post, put } = useApi();

  const getAliasConfiguration = async () => {
    const data = await get(`${API_URL}/zealert/v2/alias-configurations`);
    return data;
  };

  const createAliasConfiguration = async (data) => {
    const response = await post(`${API_URL}/zealert/v2/alias-configurations`, {
      namespace_alias: data.namespace_alias || "",
      asset_alias: data.asset_alias || "",
    });
    return response;
  };

  const updateAliasConfiguration = async (data) => {
    const response = await put(`${API_URL}/zealert/v2/alias-configurations`, {
      namespace_alias: data.namespace_alias || "",
      asset_alias: data.asset_alias || "",
    });
    return response;
  };

  return { getAliasConfiguration, createAliasConfiguration, updateAliasConfiguration };
};

export default useAliasConfiguration;
