import useApi from "services/api";
import { API_URL } from "../constants";

export const usePulseApi = () => {
  const { get, post, del, patch, loading, error } = useApi();

  const getPulseSourceList = async (params) => {
    try {
      const [sortField, sortOrder] = (params.sort || "").split(" ");
      const serverSortableFields = ["name", "created_at"];

      const queryParams = {
        page: params.page?.toString() || "1",
        limit: params.limit?.toString() || "10",
        sortOrder: sortOrder?.toUpperCase() || "ASC",
        status: params.status || "",
        connectionType: params.connectionType || "",
        createdAtStartDate: params.createdAtStartDate || "",
        createdAtEndDate: params.createdAtEndDate || "",
      };
      if (sortField && serverSortableFields.includes(sortField)) {
        queryParams.sortField = sortField;
      }
      const queryString = new URLSearchParams(queryParams).toString();
      const response = await get(`${API_URL}/zealert/v2/data-sources/stream?${queryString}`);
      return response;
    } catch (error) {
      console.error("Error fetching Pulse source list:", error);
      return {
        success: false,
        error: error?.response?.data?.message || error.message,
      };
    }
  };

  const getPulseSourceDetailList = async (params) => {
    try {
      const [sortField, sortOrder] = (params.sort || "").split(" ");
      const serverSortableFields = ["stream_id", "lastAlertRaisedAt", "rulesCount", "alertCounts"];
      const queryParams = {
        page: params.page?.toString() || "1",
        limit: params.limit?.toString() || "10",
        sortOrder: sortOrder?.toUpperCase() || "ASC",
        status: params.status || "",
        connectionType: params.connectionType || "",
        createdAtStartDate: params.createdAtStartDate || "",
        createdAtEndDate: params.createdAtEndDate || "",
      };
      if (params.filters) {
        queryParams.filters = JSON.stringify(params.filters);
      }
      if (sortField && serverSortableFields.includes(sortField)) {
        queryParams.sortField = sortField;
      }
      const queryString = new URLSearchParams(queryParams).toString();
      const response = await get(
        `${API_URL}/zealert/v2/data-sources/stream/${params.dataSourceId}?${queryString}`,
      );
      return response;
    } catch (error) {
      console.error("Error fetching Pulse source detail list:", error);
      return {
        success: false,
        error: error?.response?.data?.message || error.message,
      };
    }
  };

  const getSourceRuleList = async (params) => {
    const { active, asset_id, namespace_id } = params;
    try {
      const queryParams = {
        namespace_id: namespace_id || "",
        asset_id: asset_id || "",
        active: active !== undefined ? active : "", // Ensure active can be false
      };

      const response = await get(
        `${API_URL}/zealert/rule?namespace_id=${namespace_id}&asset_id=${asset_id}&active=${active}`,
      );
      return response;
    } catch (error) {
      console.error("Error fetching source rule list:", error);
      return {
        success: false,
        error: error?.response?.data?.message || error.message,
      };
    }
  };

  const updatedRuleStatus = async (data) => {
    try {
      const response = await patch(`${API_URL}/zealert/rule/status`, data);

      if (response.status === 404 || response.status === 500) {
        return { success: false, error: "Something went wrong" };
      }

      // Return response data
      return response;
    } catch (error) {
      // Log and return error details
      console.error("Error updating rule status:", error);
      return {
        success: false,
        error: error?.response?.data?.message || error.message,
      };
    }
  };

  const patchRulesFor = async (rulesBody) => {
    try {
      // Send a PATCH request with JSON data
      const response = await patch(`${API_URL}/zealert/rule`, rulesBody);

      // Handle specific error status codes
      if (response.status === 404 || response.status === 500) {
        return { success: false, error: "Something went wrong" };
      }

      // Return response data
      return response;
    } catch (error) {
      // Log and return error details
      console.error("Error patching rules:", error);
      return {
        success: false,
        error: error?.response?.data?.message || error.message,
      };
    }
  };

  const postRulesFor = async (rulesBody) => {
    try {
      // Send a POST request with JSON data
      const response = await post(`${API_URL}/zealert/rule`, rulesBody);

      // Handle specific error status codes
      if (response.status === 404 || response.status === 500) {
        return { success: false, error: "Something went wrong" };
      }

      // Log the API response for debugging
      // console.log("API create response:", response);

      // Return response data
      return response;
    } catch (error) {
      // Log and return error details
      console.error("Error posting rules:", error);
      return {
        success: false,
        error: error?.response?.data?.message || error.message,
      };
    }
  };

  const getPulseAlertList = async (data) => {
    const { namespace_id, asset_id } = data;

    try {
      // Build query parameters directly into the URL
      const queryParams = new URLSearchParams({
        namespace_id: namespace_id || "",
        asset_id: asset_id || "",
        sort_by: "created_at",
        sort_by: "id",
        order: "desc",
      }).toString();

      // Make the GET request using the useApi's `get` method
      const response = await get(`${API_URL}/zealert/rule/feed/all?${queryParams}`);

      // Return the API response directly
      return response;
    } catch (error) {
      console.error("Error fetching pulse alert detail:", error);
      return {
        success: false,
        error: error?.response?.data?.message || error.message,
        status: error?.response?.status,
      };
    }
  };

  const getPulseAlertDetail = async (params) => {
    try {
      const url = new URL(`${API_URL}/zealert/rule/feed`);
      url.searchParams.append("feed_id", params.feed_id);

      // Use `get` from the `useApi` hook to make the GET request
      const response = await get(url.toString());

      // Return the API response directly
      return response;
    } catch (error) {
      console.error("Error fetching pulse alert detail:", error);
      return {
        success: false,
        error: error?.response?.data?.message || error.message,
        status: error?.response?.status,
      };
    }
  };

  const getPulseAlertMilestones = async (params) => {
    try {
      const url = new URL(`${API_URL}/zealert/milestones`);
      url.searchParams.append("feed_id", params.feed_id);

      // Use `get` from the `useApi` hook to make the GET request
      const response = await get(url.toString());

      // Return the API response directly
      return response;
    } catch (error) {
      console.error("Error fetching pulse alert detail:", error);
      return {
        success: false,
        error: error?.response?.data?.message || error.message,
        status: error?.response?.status,
      };
    }
  };

  const getRulesForNotification = async (
    assetId,
    namespaceID,
    ruleQuerySearch,
    alertRuleId = 0,
  ) => {
    try {
      // Construct the query parameters based on the input conditions
      let ruleUrl = `${API_URL}/zealert/definitions?asset_id=${assetId}&namespace_id=${namespaceID}`;

      if (ruleQuerySearch) {
        ruleUrl += `&rule_name=${ruleQuerySearch}`;
      } else if (alertRuleId > 0) {
        ruleUrl += `&alert_rule_id=${alertRuleId}`;
      }

      // Use the `get` method from the `useApi` hook to make the GET request
      const response = await get(ruleUrl);

      // Return the API response directly
      return response;
    } catch (error) {
      console.error("Error fetching rules for notification:", error);
      return { success: false, error: error?.message || "Unknown error" };
    }
  };

  const postRulesForNotification = async (rulesBody) => {
    try {
      const response = await post(`${API_URL}/zealert/definitions`, rulesBody);
      return response;
    } catch (error) {
      console.error("Error posting rules for notification:", error);
      return { success: false, error: error?.message || "Unknown error" };
    }
  };

  const patchRulesForNotification = async (rulesBody) => {
    try {
      const response = await patch(`${API_URL}/zealert/definitions`, rulesBody);
      return response;
    } catch (error) {
      console.error("Error patching rules for notification:", error);
      return { success: false, error: error?.message || "Unknown error" };
    }
  };

  const deleteRule = async (ruleId) => {
    try {
      const response = await del(`${API_URL}/zealert/rule?rule_id=${ruleId}`);
      return response;
    } catch (error) {
      console.error("Error deleting rule:", error);
      return { success: false, error: error?.message || "Unknown error" };
    }
  };

  return {
    getPulseSourceList,
    getPulseSourceDetailList,
    getSourceRuleList,
    updatedRuleStatus,
    patchRulesFor,
    postRulesFor,
    getPulseAlertList,
    getPulseAlertDetail,
    getPulseAlertMilestones,
    getRulesForNotification,
    postRulesForNotification,
    patchRulesForNotification,
    deleteRule,
    loading,
    error,
  };
};
