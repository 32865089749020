import React, { useState, useCallback } from 'react';
import Category from './Category';
import './index.css';

const DataPanel = ({ toast }) => {
    const [visible, setVisible] = useState(false);
    const handleSetVisible = useCallback((value) => {
        setVisible(value);
    }, []);

    return (
        <div className="card flex data-panel">
            <a className='cursor-pointer text-blue-500' onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleSetVisible(true);
            }}>Add/Edit Category</a>
            <Category toast={toast} visible={visible} setVisible={handleSetVisible} className="category-panel" />
        </div>
    );
}

export default DataPanel;