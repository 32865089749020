import React, { useState, useEffect, useRef, Fragment } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import "../../DocumentEngine/TemplateSection/newTemplate.css";
import { TieredMenu } from "primereact/tieredmenu";
import { Toast } from "primereact/toast";
import { FilterMatchMode } from "primereact/api";
import GroupsList from "hooks/useGroupsTask";
import { Dialog } from "primereact/dialog";
import useMediaQuerry from "hooks/useMediaQuerry";
import moment from "moment";
import { Tooltip } from "primereact/tooltip";
import { Paginator } from "primereact/paginator";
import { Dropdown } from "primereact/dropdown";

export default function GroupPanels({
  setVisible,
  setSelectedGroups,
  selectedGroups,
  setSidebarTitle,
  data,
  setData,
}) {
  const toast = useRef(null);
  const menu = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { deleteGroup } = GroupsList();
  const isMobile = useMediaQuerry();
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const { getGroupDetails, loading } = GroupsList();
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [tooltipContent, setTooltipContent] = useState("");
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [showTooltip, setShowTooltip] = useState(false);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);

  const fetchGroups = async () => {
    try {
      const data = await getGroupDetails(sortOrder, sortField);
      if (data.success && data.results && data.results.data) {
        setData(data.results.data);
      }
    } catch (err) {
      console.error(err);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Please try again later",
        life: 3000,
      });
    }
  };

  function handleOnSort(event) {
    const { sortField: newSortField, sortOrder: newSortOrder } = event;
    setSortField(newSortField);
    setSortOrder(newSortOrder === 1 ? "desc" : "asc");
    getGroupDetails(newSortOrder === 1 ? "desc" : "asc", newSortField);
  }

  useEffect(() => {
    fetchGroups();
  }, [currentPage, rowsPerPage]);

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const handleDeleteGroup = async () => {
    try {
      const result = await deleteGroup(selectedGroups?.id);

      if (result?.status) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Group deleted successfully.",
        });
        setDeleteDialogVisible(false);
        fetchGroups();
      }
    } catch (e) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: e?.response?.data?.detail || "Something went wrong. Please try again later.",
      });
    }
  };

  const renderHeader = () => {
    return (
      <div className="flex items-center w-full">
        <div className="flex-1 items-center">
          <span className="text-lg font-bold">{data?.length || 0} Groups</span>
        </div>
        <div className="flex-2 flex justify-end">
          <IconField iconPosition="left">
            <InputIcon className="pi pi-search text-sm" />
            <InputText
              value={globalFilterValue}
              className="text-sm"
              onChange={onGlobalFilterChange}
              placeholder="Search Group Name"
            />
          </IconField>
        </div>
      </div>
    );
  };

  const header = renderHeader();

  const actionTemplate = (rowData) => {
    const items = [
      {
        label: "Edit",
        template: (item) => (
          <a className="flex align-items-center gap-2 p-2">
            <i className="pi pi-pencil"></i>
            <span>{item.label}</span>
          </a>
        ),
        command: () => {
          setVisible(true);
          setSidebarTitle("Edit Group");
        },
      },
      {
        label: "Delete",
        icon: "pi pi-trash",
        template: (item) => (
          <a className="flex align-items-center gap-2 p-2 text-red-500 hover:text-red-700">
            <i className="pi pi-trash"></i>
            <span>{item.label}</span>
          </a>
        ),
        command: () => {
          setDeleteDialogVisible(true);
        },
      },
    ];

    const previewIcon = (
      <Button
        type="button"
        icon={"pi pi-eye"}
        style={{ color: "#3B82F6" }}
        onClick={() => { }}
        className="p-button-rounded p-button-text p-button-secondary  w-2rem h-2rem"
      />
    );

    const moreMenu = (
      <div className="card flex justify-content-center">
        <TieredMenu
          model={items}
          popup
          ref={menu}
          breakpoint="767px"
          className="max-w-10rem text-sm"
        />
        <Button
          type="button"
          icon={"pi pi-ellipsis-v"}
          onClick={(e) => {
            menu.current.toggle(e);
            setTimeout(() => {
              menu?.current
                ?.getElement()
                ?.children?.[0]?.children?.[0]?.classList?.remove("p-focus");
            }, 100);
            setSelectedGroups(rowData);
          }}
          className="p-button-rounded p-button-text p-button-secondary w-2rem h-2rem"
        />
      </div>
    );

    return (
      <div className="text-right inline-flex">
        {previewIcon}
        {moreMenu}
      </div>
    );
  };

  const handlePageChange = (e) => {
    setCurrentPage(e.page + 1);
    setRowsPerPage(e.rows);
  };

  const handleMouseEnter = (e, content) => {
    const rect = e.target.getBoundingClientRect();
    setTooltipPosition({
      x: rect.left,
      y: rect.top - 30,
    });
    setTooltipContent(content);
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const createdGroupDate = (rowData) => {
    const { created_at } = rowData;
    const timestamp = moment(created_at).format("DD/MM/YYYY");

    const groupCreatedOn = (
      <div className="flex flex-column">
        <span className="text-xs text-color-secondary mt-1">{timestamp}</span>
      </div>
    );
    return groupCreatedOn;
  };

  return (
    <Fragment>
      {showTooltip && (
        <div
          style={{
            position: "fixed",
            left: tooltipPosition.x,
            top: tooltipPosition.y,
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            color: "white",
            padding: "4px 8px",
            borderRadius: "4px",
            zIndex: 1000,
            maxWidth: "300px",
            wordWrap: "break-word",
          }}
        >
          {tooltipContent}
        </div>
      )}
      <Tooltip
        target="[data-pr-tooltip]"
        showDelay={300}
        hideDelay={200}
        mouseTrack={false}
        position="top"
        showOnDisabled={false}
        autoHide={true}
        autoZIndex={true}
        className="tooltip-custom text-sm"
        style={{
          maxWidth: "300px",
          wordBreak: "break-word",
        }}
      />
      <Toast ref={toast} />
      <div className="card my-5 px-3 templateTable" style={{ height: "60vh" }}>
        <DataTable
          value={data}
          rowsPerPageOptions={[5, 10, 20]}
          rows={rowsPerPage}
          paginator
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          onFilter={(e) => setFilters(e.filters)}
          filters={filters}
          onPage={handlePageChange}
          first={(currentPage - 1) * rowsPerPage}
          loading={loading}
          inSort={false}
          tableStyle={{ minWidth: "50rem" }}
          header={header}
          sortField={sortField}
          sortOrder={sortOrder === "desc" ? 1 : -1}
          onSort={handleOnSort}
          emptyMessage="No group found."
          className="w-full"
          scrollable
          scrollHeight="flex"
          selection={selectedGroups}
          onSelectionChange={(e) => setSelectedGroups(e.value)}
          dataKey="id"
          selectionMode={"checkbox"}
        >
          <Column
            field="name"
            header="Group Name"
            body={(rowData) => {
              const truncatedName =
                rowData.name?.length > 20 ? `${rowData.name.slice(0, 25)}...` : rowData.name;
              return (
                <div
                  className="description-cell" // Add this class
                  data-pr-tooltip={rowData.name}
                  data-pr-position="top"
                >
                  {truncatedName}
                </div>
              );
            }}
            sortable
            style={{ width: "25.5%" }}
          ></Column>
          <Column
            field="description"
            header="Description"
            body={(rowData) => {
              const truncatedText =
                rowData.description?.length > 50
                  ? `${rowData.description.slice(0, 40)}...`
                  : rowData.description;
              return (
                <div
                  className="description-cell" // Add this class
                  data-pr-tooltip={rowData.description}
                  data-pr-position="top"
                >
                  {truncatedText}
                </div>
              );
            }}
            style={{ width: "25.5%" }}
          />
          <Column
            field="user_count"
            header="Number of users"
            sortable
            style={{ width: "20%" }}
          ></Column>
          <Column
            field="created_at"
            header="Created date"
            style={{ width: "20%" }}
            body={createdGroupDate}
            sortable
          ></Column>
          <Column
            field="actions"
            header="Actions"
            style={{ width: "5%" }}
            className="w-full"
            body={(rowData) => actionTemplate(rowData)}
          ></Column>
        </DataTable>
      </div>
      {/* <Paginator
        first={(currentPage - 1) * rowsPerPage || 0}
        rows={rowsPerPage}
        totalRecords={data?.length || 0}
        className="text-sm"
        template={{
          layout:
            "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
        }}
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        rowsPerPageOptions={[10, 20, 30]}
        onPageChange={handlePageChange}
      ></Paginator> */}
      <Dialog
        visible={deleteDialogVisible}
        onHide={() => setDeleteDialogVisible(false)}
        header="Delete Group"
        modal
        style={{ width: "50vw" }}
        footer={
          <div className="flex justify-content-end">
            <Button
              label="Yes"
              icon="pi pi-check"
              severity="danger"
              onClick={handleDeleteGroup}
              autoFocus
            />
            <Button
              label="No"
              icon="pi pi-times"
              severity="secondary"
              onClick={() => setDeleteDialogVisible(false)}
              outlined
            />
          </div>
        }
      >
        <div className="inline-flex  items-baseline gap-2	">
          <i className="pi pi-exclamation-triangle mt-3 text-black text-3xl" />
          <p className="text-gray-600 font-light text-base deactivate-content">
            Are you sure you want to delete the group?
          </p>
        </div>
      </Dialog>
    </Fragment>
  );
}
