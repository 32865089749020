import { BreadCrumb } from "primereact/breadcrumb";
import { Divider } from "primereact/divider";
import { MegaMenu } from "primereact/megamenu";
import { ProgressSpinner } from "primereact/progressspinner";
import { Steps } from "primereact/steps";
import { useNavigate, useParams } from "react-router-dom";
import useSourceData from "./useSourceData";
import { isMobile } from "react-device-detect";
import InnerSidebar from "layouts/InnerSidebar";
import { SourceConfigBreadcrumb } from "components/SourceConfigBreadcrumb";
import CustomStepper from "../CustomStepper";
import "./index.css";
import { useState, useEffect } from "react";
import SourceCredentials from "./SourceCredentials";
import ApplyRules from "../ApplyRules";
import SetFrequency from "../SetFrequency";
import { useSourceApi } from "hooks/sourceConfig";


export default function EditSource() {
  const { step, id } = useParams();
  const {
    loading,
    sourceFields,
    fieldRules,
    processingRules,
    contractDetails,
  } = useSourceData(id, step);
  const [minimized, setMinimized] = useState(false);
  const [imported, setImported] = useState(false);
  const [appliedRules, setAppliedRules] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [actualStep, setActualStep] = useState(null);
  const [sourceDetails, setSourceDetails] = useState({});
  const [finishSetup, setFinishSetup] = useState(false);
  const navigate = useNavigate();
  const { getSourceById } = useSourceApi();

  const stepItems = [
    {
      label: "Select Source",
      disabled: id === "add",
    },
    {
      label: "Apply Rules",
      disabled: !sourceFields || !sourceFields.length,
    },
    {
      label: "Set Frequency",
      disabled: !fieldRules || !fieldRules.length,
    },
  ];
  useEffect(() => {
    if (appliedRules) {
      setCurrentStep(3);
    } else if (imported) setCurrentStep(2);
    else setCurrentStep(1);
  }, [imported, appliedRules]);

  useEffect(() => {
    const fetchSourceData = async () => {
      try {
        const response = await getSourceById(id);
        const { source } = response;
        setActualStep(source.step);
        await localStorage.setItem("sourceDetails", JSON.stringify(source));
        localStorage.setItem(
          "fieldsMapping",
          JSON.stringify(response?.fieldsMapping)
        );
        switch (source.step) {
          case 1:
            setImported(false);
            setAppliedRules(false);
          case 2:
            setImported(true);
            setAppliedRules(false);
            break;
          case 3:
            // localStorage.setItem('sourceDefinition',JSON.stringify(source?.definition))
            setImported(true);
            setAppliedRules(true);
        }
        await setSourceDetails(source);
      } catch (error) {
        console.error("Error fetching source data: ", error);
      }
    };
    fetchSourceData();
  }, [id]);

  const start = (
    <div className="inline-flex align-items-center">
      <span className="font-bold text-xl text-black-alpha-90">{sourceDetails?.name}</span>
    </div>
  );

  const megaMenu = () => {
    return (
      <>
        <MegaMenu
          start={start}
          className="bg-transparent border-none border-noround pb-3 ml-5"
        />
        <CustomStepper
          stepItems={stepItems}
          activeStep={currentStep}
          setImported={setImported}
          setAppliedRules={setAppliedRules}
          actualStep={actualStep}
        />
      </>
    );
  };

  if (loading) {
    return (
      <>
        {/* {megaMenu()} */}
        <div
          className="flex justify-center align-center"
          style={{ height: "80vh" }}
        >
          <div className="block text-center">
            <ProgressSpinner />
          </div>
        </div>
      </>
    );
  }

  const renderStepComponent = () => {
    // switch (step) {
    //   case '1':
    //     return <SourceBasicDetails />;
    //   case '2':
    //     return <SourceMapFields />;
    //   case '3':
    //     return <SourceApplyRules />;
    //   case '4':
    //     return <Processing />;
    //   case '5':
    //     return <SourceContract />;
    //   default:
    //     return null;
    // }
    return <></>;
  };

  if (loading) {
    return (
      <div className="flex justify-content-center align-items-center h-29rem">
        <div className="block text-center">
          <ProgressSpinner />
        </div>
      </div>
    );
  }

  return (
    <div className="new-source-setup">
      <div className="w-full h-screen overflow-hidden bg-sidebar flex">
        {loading ? (
          <div
            style={{ height: isMobile && "100%" }}
            className="flex flex-col items-around transition-all duration-500 relative md:rounded-[26px] bg-main-gradient w-full h-full overflow-y-scroll"
          >
            <div className="new-source-setup">
              {/* <SourceConfigBreadcrumb sourceConfig="Structured Sources" />
              <div
                style={{ borderBottom: "2px solid #DFE7EF", margin: "4px 0" }}
              ></div> */}
              {/* <div className="flex mt-2 justify-between">
                {megaMenu()}
            </div>
            <Divider />
            {renderStepComponent()} */}
              <div className="w-full h-full flex justify-center items-center">
                {/* <PreLoader /> */}
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{ height: isMobile && "100%" }}
            className="flex flex-col items-around transition-all duration-500 relative md:rounded-[26px] bg-main-gradient w-full h-full overflow-y-scroll"
          >
            <div className="new-source-setup">
              {/* <SourceConfigBreadcrumb sourceConfig="Structured Sources" />
              <div
                style={{ borderBottom: "2px solid #DFE7EF", margin: "4px 0" }}
              ></div> */}
              <div className="flex mt-2 justify-between">{megaMenu()}</div>
              <Divider />
              {renderStepComponent()}
              <div
                style={{
                  maxHeight: "60vh", // Adjust as needed
                  overflowY: "auto",
                }}
              >
                {!imported ? (
                  <SourceCredentials
                    setImported={setImported}
                    sourceDetails={sourceDetails}
                  />
                ) : !appliedRules ? (
                  <ApplyRules
                    setAppliedRules={setAppliedRules}
                    setImported={setImported}
                  />
                ) : !finishSetup ? (
                  <SetFrequency
                    setAppliedRules={setAppliedRules}
                    setFinishSetup={setFinishSetup}
                  />
                ) : (
                  navigate("/source-configuration/source")
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
