import React, { useCallback, useEffect, useRef, useState } from "react";
import { Sidebar } from "primereact/sidebar";
import { Calendar } from "primereact/calendar";
import { MultiSelect } from "primereact/multiselect";
// import Feed from "@/models/feed";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import "./index.css";
import { Timeline } from "primereact/timeline";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { convertDateTimeWithSeconds } from "utils/helper";
import { usePulseApi } from "hooks/pulseConfig";
import { useWorkspaceApi } from "hooks/workspaceConfig";
import EscalationLevel from "./EscalationLevel";
import { MdInfo } from "react-icons/md";
import { Tooltip } from "primereact/tooltip";

const convertFlatDatesToRanges = (flatDates) => {
  if (!flatDates || !flatDates.length) return [];

  // Sort the dates in ascending order
  const sortedDates = [...flatDates].sort((a, b) => a - b);

  const ranges = [];
  let currentRange = {
    start_date: sortedDates[0],
    end_date: sortedDates[0],
  };

  for (let i = 1; i <= sortedDates.length; i++) {
    if (i === sortedDates.length || sortedDates[i] !== sortedDates[i - 1] + 1) {
      // End of sequence found
      ranges.push({ ...currentRange });
      if (i < sortedDates.length) {
        currentRange = {
          start_date: sortedDates[i],
          end_date: sortedDates[i],
        };
      }
    } else {
      currentRange.end_date = sortedDates[i];
    }
  }

  return ranges;
};

const ScheduleTypeDropdown = ({ formData, onChange, isValidationEnable }) => {
  const scheduleOptions = [
    { label: "Specific Date Range", value: "date_range" },
    { label: "Occurs Every Week", value: "weekly" },
    { label: "Occurs Every Month", value: "monthly" },
    { label: "No End Date", value: "infinite" },
  ];

  return (
    <div className="space-y-1">
      <label htmlFor="frequency_type" className="block text-sm font-normal text-title-black-light">
        Schedule Type
      </label>
      <Dropdown
        id="frequency_type"
        value={formData.frequency_type}
        options={scheduleOptions}
        onChange={(e) => onChange("frequency_type", e.value)}
        placeholder="Select Schedule Type"
        // className="w-full border rounded-md h-12"
        className={`w-full mt-2 border rounded-md h-12 focus:outline-none focus:ring-2 placeholder:font-normal text-[#6C757D] opacity-60 ${
          !formData.frequency_type && isValidationEnable ? "border-red-500" : "border-gray-400"
        }`}
      />
      <div className="flex flex-col">
        {!formData.frequency_type && isValidationEnable && (
          <p className="text-red-500 text-xs" style={{ marginTop: "2px", marginBottom: "2px" }}>
            Please select the schedule type
          </p>
        )}
      </div>
      {formData.frequency_type === "date_range" && (
        <>
          <div
            className={`schedule-type-date-range mt-4 flex gap-4 items-center border ${!(formData?.date_range?.start_date && formData?.date_range?.end_date && formData?.date_range?.start_date < formData?.date_range?.end_date) && isValidationEnable ? "border-red-500" : ""}`}
          >
            <i className="pi pi-calendar ml-3" style={{ color: "#A6ADB3" }}></i>
            <Calendar
              value={formData?.date_range?.start_date}
              onChange={(e) =>
                onChange("date_range", {
                  ...formData.date_range,
                  start_date: e.value,
                })
              }
              placeholder="Start Date"
              className="w-1/2"
            />
            <span>-</span>
            <Calendar
              value={formData?.date_range?.end_date}
              onChange={(e) =>
                onChange("date_range", {
                  ...formData.date_range,
                  end_date: e.value,
                })
              }
              placeholder="End Date"
              className="w-1/2"
            />
          </div>
          <div className="flex flex-col mt-1">
            {!(formData?.date_range?.start_date && formData?.date_range?.end_date) &&
              isValidationEnable && (
                <p
                  className="text-red-500 text-xs"
                  style={{ marginTop: "2px", marginBottom: "2px" }}
                >
                  Please select {formData?.date_range?.start_date ? "end date" : "start date"}
                </p>
              )}
            {formData?.date_range?.start_date &&
              formData?.date_range?.end_date &&
              formData?.date_range?.start_date > formData?.date_range?.end_date &&
              isValidationEnable && (
                <p
                  className="text-red-500 text-xs"
                  style={{ marginTop: "2px", marginBottom: "2px" }}
                >
                  Start date must be before end date
                </p>
              )}
          </div>
        </>
      )}

      {formData.frequency_type === "weekly" && (
        <>
          <div className="schedule-type-weekly mt-4 flex gap-2">
            {["S", "M", "T", "W", "T", "F", "S"].map((day, index) => {
              const actualValue = index === 0 ? 6 : index - 1;
              return (
                <Button
                  key={index}
                  className={`schedule-type-weekly-button p-0 ${formData.weekly_days.length === 0 && isValidationEnable ? "border-red-500" : ""} ${
                    formData.weekly_days.includes(actualValue)
                      ? "bg-blue-500 text-white"
                      : "bg-white text-gray-700 border border-gray-300"
                  }`}
                  onClick={() => {
                    const days = formData.weekly_days.includes(actualValue)
                      ? formData.weekly_days.filter((d) => d !== actualValue)
                      : [...formData.weekly_days, actualValue];
                    onChange("weekly_days", days);
                  }}
                >
                  {day}
                </Button>
              );
            })}
          </div>

          <div className="flex flex-col mt-1">
            {!formData.weekly_days.length && isValidationEnable && (
              <p className="text-red-500 text-xs" style={{ marginTop: "2px", marginBottom: "2px" }}>
                Please select at least one day
              </p>
            )}
          </div>
        </>
      )}

      {formData.frequency_type === "monthly" && (
        <div className="mt-4 space-y-4">
          {formData.monthly_dates.map((dateRange, index) => (
            <div key={index}>
              <div className="schedule-type-monthly flex items-center gap-4">
                <Dropdown
                  placeholder="Day of the month"
                  value={dateRange.start_date}
                  className={`${!dateRange.start_date && isValidationEnable ? "border-red-500" : ""}`}
                  pt={{
                    input: {
                      className: "w-12rem",
                    },
                  }}
                  options={Array.from({ length: 31 }, (_, i) => i + 1)}
                  onChange={(e) => {
                    const newDates = [...formData.monthly_dates];
                    newDates[index].start_date = e.value;
                    onChange("monthly_dates", newDates);
                  }}
                />
                <span>till</span>
                <Checkbox
                  checked={!!dateRange.end_date}
                  onChange={(e) => {
                    const newDates = [...formData.monthly_dates];
                    newDates[index].end_date = e.checked ? dateRange.start_date || 1 : null;
                    onChange("monthly_dates", newDates);
                  }}
                />
                {dateRange.end_date && (
                  <Dropdown
                    placeholder="Day of the month"
                    value={dateRange.end_date}
                    // className={`${dateRange.start_date > dateRange.end_date && isValidationEnable ? "border-red-500" : ""}`}
                    pt={{
                      input: {
                        className: "w-12rem",
                      },
                    }}
                    options={Array.from({ length: 31 }, (_, i) => i + 1)}
                    onChange={(e) => {
                      const newDates = [...formData.monthly_dates];
                      newDates[index].end_date = e.value;
                      onChange("monthly_dates", newDates);
                    }}
                  />
                )}
              </div>
              {isValidationEnable && (
                <>
                  {!dateRange.start_date && (
                    <p
                      className="text-red-500 text-xs mt-1"
                      style={{ marginTop: "2px", marginBottom: "2px" }}
                    >
                      Please select Start Date
                    </p>
                  )}
                  {dateRange.start_date &&
                    dateRange.end_date &&
                    dateRange.start_date > dateRange.end_date && (
                      <p
                        className="text-red-500 text-xs mt-1"
                        style={{ marginTop: "2px", marginBottom: "2px" }}
                      >
                        Start date must be before end date
                      </p>
                    )}
                </>
              )}
            </div>
          ))}
          {formData.monthly_dates.length === 0 && isValidationEnable && (
            <p
              className="text-red-500 text-xs mt-1"
              style={{ marginTop: "2px", marginBottom: "2px" }}
            >
              Please select at least one date
            </p>
          )}
          <Button
            label={formData.monthly_dates.length === 0 ? "+ Add  date" : "+ Add another date"}
            className="p-button-text text-blue-500"
            onClick={() => {
              onChange("monthly_dates", [
                ...formData.monthly_dates,
                { start_date: null, end_date: null },
              ]);
            }}
          />
        </div>
      )}
    </div>
  );
};

const RuleNotificationSidebar = ({
  visible,
  setVisible,
  allUserData,
  onSuccess,
  allRuleData,
  updateSidebarData,
  setUpdateSidebarData,
  getNamespaceAsset,
}) => {
  // Local state for notification form data
  const [formDataNotification, setFormDataNotification] = useState({
    title: "",
    alert_message: "",
    notification_channels: ["pulse"],
    alert_rule_ids: "",
    definition_id: 0,
    knowledge_space: "",
    detail_levels: [
      {
        level: 1,
        users: [],
        escalation_time: 0,
      },
    ],
    frequency_type: "",
    date_range: {
      start_date: null,
      end_date: null,
    },
    weekly_days: [],
    monthly_dates: [
      {
        start_date: null,
        end_date: null,
      },
    ],
    phone_numbers: {},
  });

  const [isValidationEnable, setIsValidationEnable] = useState(false);
  const [showSecondarySpoc, setShowSecondarySpoc] = useState(false);
  const [showTertiarySpoc, setShowTertiarySpoc] = useState(false);
  const { postRulesForNotification, patchRulesForNotification } = usePulseApi();
  const [submitLoading, setSubmitLoading] = useState(false);
  const { getTenantId } = useWorkspaceApi();

  useEffect(() => {
    if (updateSidebarData) {
      // Convert old format to new if needed
      const detailLevels = updateSidebarData?.detail_levels
        ? updateSidebarData?.detail_levels?.map((item) => ({
            ...item,
            id: item.id || null,
            escalation_time: convertDateTimeWithSeconds(item.escalation_time || 0),
          }))
        : [
            {
              level: 1,
              users: updateSidebarData?.primary_spoc?.map((item) => item.value) || [],
              escalation_time: 0,
            },
            updateSidebarData?.secondary_spoc?.users?.length > 0 && {
              level: 2,
              users: updateSidebarData?.secondary_spoc?.users?.map((item) => item.value) || [],
              escalation_time: convertDateTimeWithSeconds(
                updateSidebarData?.secondary_spoc?.escalation_time || 0,
              ),
            },
            updateSidebarData?.tertiary_spoc?.users?.length > 0 && {
              level: 3,
              users: updateSidebarData?.tertiary_spoc?.users?.map((item) => item.value) || [],
              escalation_time: convertDateTimeWithSeconds(
                updateSidebarData?.tertiary_spoc?.escalation_time || 0,
              ),
            },
          ].filter(Boolean);
      let monthlyDates = [];
      if (updateSidebarData?.frequency_type?.toLowerCase() === "monthly") {
        monthlyDates = convertFlatDatesToRanges(updateSidebarData.config_schedule.days_of_month);
      }
      setFormDataNotification({
        alert_message: updateSidebarData?.alert_message || "",
        alert_rule_ids: updateSidebarData?.alert_rules?.map((item) => item.id) || [],
        definition_id: updateSidebarData?.id,
        knowledge_space: updateSidebarData?.knowledge_space?.map((item) => item.value) || [],
        primary_spoc: updateSidebarData?.primary_spoc?.map((item) => item.value) || [],
        secondary_spoc: {
          escalation_time: convertDateTimeWithSeconds(
            updateSidebarData?.secondary_spoc?.escalation_time || 0,
          ),
          users: updateSidebarData?.secondary_spoc?.users?.map((item) => item.value) || [],
        },
        tertiary_spoc: {
          escalation_time: convertDateTimeWithSeconds(
            updateSidebarData?.tertiary_spoc?.escalation_time || 0,
          ),
          users: updateSidebarData?.tertiary_spoc?.users?.map((item) => item.value) || [],
        },
        title: updateSidebarData?.title || "",
        notification_channels: updateSidebarData?.notification_channels || [],
        date_range:
          updateSidebarData?.frequency_type?.toLowerCase() === "date_range"
            ? {
                start_date: new Date(updateSidebarData?.config_schedule?.valid_from),
                end_date: new Date(updateSidebarData?.config_schedule?.valid_to),
              }
            : null,
        weekly_days: updateSidebarData?.config_schedule?.days_of_week || [],
        monthly_dates: monthlyDates,
        frequency_type: updateSidebarData?.frequency_type?.toLowerCase(),
        phone_numbers: updateSidebarData?.phone_numbers || {},
        detail_levels: detailLevels,
      });

      // Update visibility of levels
      setShowSecondarySpoc(detailLevels.some((level) => level.level === 2));
      setShowTertiarySpoc(detailLevels.some((level) => level.level === 3));
    }
  }, [updateSidebarData]);

  // Handle MultiSelect change for primary, secondary, tertiary, and knowledge_space
  const handleSelectChange = (e, field) => {
    setFormDataNotification((prev) => ({
      ...prev,
      [field]: e.value,
    }));
  };

  const handleCancelForm = () => {
    setVisible(false);
    setFormDataNotification({
      title: "",
      alert_message: "",
      notification_channels: [],
      alert_rule_ids: "",
      definition_id: 0,
      knowledge_space: "",
      primary_spoc: [],
      secondary_spoc: { escalation_time: "", users: [] },
      tertiary_spoc: { escalation_time: "", users: [] },
      frequency_type: "",
      date_range: {
        start_date: null,
        end_date: null,
      },
      weekly_days: [],
      monthly_dates: [
        {
          start_date: null,
          end_date: null,
        },
      ],
      phone_numbers: {},
      detail_levels: [
        {
          level: 1,
          users: [],
          escalation_time: 0,
        },
      ],
    });
    setUpdateSidebarData(null);
    setIsValidationEnable(false);
  };

  // Handle form submission
  const handleSubmit = async () => {
    setIsValidationEnable(true);
    let namespace_id = `${getNamespaceAsset?.namespace_id}`;
    let asset_id = `${getNamespaceAsset?.asset_id}`;

    const isAlertMessageValid = formDataNotification.alert_message !== "";
    // const isTitleValid = formDataNotification.title !== "";
    const isChannelValid = formDataNotification.notification_channels.length > 0;

    const isLevelsValid =
      formDataNotification.detail_levels.length === 1
        ? formDataNotification.detail_levels[0].users.length > 0
        : formDataNotification.detail_levels
            .filter((_, index) => index < formDataNotification.detail_levels.length)
            .every((level) => {
              if (level.level === 1) {
                return level.users.length > 0;
              }
              return level.users.length > 0;
            });

    let isScheduleValid = false;
    if (formDataNotification.frequency_type === "date_range") {
      isScheduleValid =
        formDataNotification?.date_range?.start_date &&
        formDataNotification?.date_range?.end_date &&
        formDataNotification?.date_range?.start_date < formDataNotification?.date_range?.end_date;
    }

    if (formDataNotification.frequency_type === "weekly") {
      isScheduleValid = formDataNotification.weekly_days.length > 0;
    }

    if (formDataNotification.frequency_type === "monthly") {
      isScheduleValid = formDataNotification.monthly_dates.some(
        (date) =>
          date.start_date &&
          (!date.end_date ||
            (typeof date.end_date === "number" && date.start_date <= date.end_date)),
      );
    }

    if (formDataNotification.frequency_type === "infinite") {
      isScheduleValid = true;
    }

    if (
      isAlertMessageValid &&
      // isTitleValid &&
      isChannelValid &&
      isLevelsValid &&
      isScheduleValid
    ) {
      try {
        setSubmitLoading(true);
        const payload = {
          title: formDataNotification.title,
          notification_channels: formDataNotification.notification_channels,
          description: "Notification CREATION",
          alert_message: formDataNotification.alert_message,
          detail_levels: formDataNotification.detail_levels.map((level) => {
            return {
              id: level.id || null,
              level: level.level,
              users: level.users,
              emails: level.users.map(
                (user) => allUserData?.userList?.find((item) => item.value === user)?.email,
              ),
              escalation_time: level.escalation_time
                ? convertDateTimeWithSeconds(level.escalation_time)
                : 0,
            };
          }),
          additional_info: {},
          frequency_type: formDataNotification?.frequency_type?.toUpperCase() || "INFINITE",
          config_schedule: {
            days_of_week:
              formDataNotification?.frequency_type === "weekly"
                ? formDataNotification.weekly_days
                : null,
            days_of_month:
              formDataNotification?.frequency_type === "monthly"
                ? formDataNotification.monthly_dates
                    .map((e) =>
                      e.end_date
                        ? Array.from(
                            { length: e.end_date - e.start_date + 1 },
                            (_, i) => e.start_date + i,
                          )
                        : [e.start_date],
                    )
                    .flat()
                : null,
            valid_from:
              formDataNotification?.frequency_type === "date_range"
                ? new Date(
                    formDataNotification.date_range.start_date.getTime() + 24 * 60 * 60 * 1000,
                  )
                    .toISOString()
                    .split("T")[0]
                : null,
            valid_to:
              formDataNotification?.frequency_type === "date_range"
                ? new Date(formDataNotification.date_range.end_date.getTime() + 24 * 60 * 60 * 1000)
                    .toISOString()
                    .split("T")[0]
                : null,
            active: true,
          },
          asset_id: asset_id,
          namespace_id: namespace_id,
          status: "ACTIVE",
          knowledge_space: formDataNotification.knowledge_space || [],
          alert_rule_ids: formDataNotification.alert_rule_ids || [],
          region: "westus",
          tenant_id: await getTenantId(),
        };
        const ruleNotificationData =
          formDataNotification?.definition_id === 0
            ? await postRulesForNotification(payload)
            : await patchRulesForNotification({
                ...payload,
                definition_id: formDataNotification?.definition_id,
              });

        if (ruleNotificationData?.status === 200) {
          setVisible(false);
          if (onSuccess) {
            onSuccess(ruleNotificationData?.message);
            setFormDataNotification({
              alert_message: "",
              alert_rule_id: "",
              definition_id: 0,
              knowledge_space: "",
              primary_spoc: [],
              secondary_spoc: { escalation_time: "", users: [] },
              tertiary_spoc: { escalation_time: "", users: [] },
            });
          }
          setUpdateSidebarData(null);
          setShowSecondarySpoc(false);
          setShowTertiarySpoc(false);
        }
      } catch (error) {
        if (onSuccess) {
          onSuccess(false);
        }
      } finally {
        setSubmitLoading(false);
      }
    }
  };

  const handleChangeNotification = (e) => {
    const { name, value } = e.target;
    if ((name === "alert_message" || name === "title") && value.length <= 160) {
      setFormDataNotification((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleUserChange = (e, level) => {
    const levelNumber = level === "primary" ? 1 : level === "secondary" ? 2 : 3;
    setFormDataNotification((prev) => {
      const newDetailLevels = [...prev.detail_levels];
      const levelIndex = newDetailLevels.findIndex((l) => l.level === levelNumber);
      newDetailLevels[levelIndex] = {
        ...newDetailLevels[levelIndex],
        users: level === "primary" ? e : e.value,
      };
      return { ...prev, detail_levels: newDetailLevels };
    });
  };

  const handleTimeChange = (e, type) => {
    const levelNumber = type === "primary" ? 1 : type === "secondary" ? 2 : 3;
    const newTime = e.value;
    setFormDataNotification((prev) => {
      const newDetailLevels = [...prev.detail_levels];
      const levelIndex = newDetailLevels.findIndex((l) => l.level === levelNumber);
      newDetailLevels[levelIndex] = {
        ...newDetailLevels[levelIndex],
        escalation_time: newTime,
      };
      return { ...prev, detail_levels: newDetailLevels };
    });
  };

  const handleDelete = (level) => {
    const levelNumber = level === "primary" ? 1 : level === "secondary" ? 2 : 3;
    setFormDataNotification((prev) => ({
      ...prev,
      detail_levels: prev.detail_levels.filter((l) => l.level !== levelNumber),
    }));

    if (level === "secondary") {
      setShowSecondarySpoc(false);
    } else if (level === "tertiary") {
      setShowTertiarySpoc(false);
    }
  };

  const customMarker = ({ events }) => (
    <div className="flex flex-col items-center" style={{ color: "#ABC9FB" }}>
      {/* Circle marker */}
      <span className="w-1 h-2 bg-[#1581ED] border-[#1581ED] rounded-full"></span>
      {/* Conditionally show a longer line if there's only one event */}
      {events?.length === 1 ? (
        <span className="single-event-line h-24 w-px bg-[#ABC9FB]"></span>
      ) : (
        <span className="multi-event-line  w-px bg-[#ABC9FB]"></span>
      )}
    </div>
  );

  const handleChannelChange = (e) => {
    let selectedChannels = [...formDataNotification.notification_channels];
    if (e.checked) selectedChannels.push(e.value);
    else selectedChannels = selectedChannels.filter((channel) => channel !== e.value);
    setFormDataNotification((prev) => ({
      ...prev,
      notification_channels: selectedChannels,
    }));
  };

  const handleScheduleChange = (field, value) => {
    setFormDataNotification((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handlePhoneNumberChange = (userId, value) => {
    setFormDataNotification((prev) => ({
      ...prev,
      phone_numbers: {
        ...prev.phone_numbers,
        [userId]: value,
      },
    }));
  };

  const characterCount = formDataNotification.alert_message?.length;

  return (
    <Sidebar
      visible={visible}
      position="right"
      className="p-sidebar-md rule-notification-sidebar"
      dismissable={false}
      onHide={() => handleCancelForm()}
      content={({ closeIconRef, hide }) => (
        <div style={{ overflowY: "auto" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "12px",
              paddingTop: "16px",
              position: "sticky",
              top: 0,
              backgroundColor: "white",
              zIndex: 10,
              height: "8.5vh",
            }}
            className="rule-notification-sidebar-header"
          >
            <span
              style={{
                fontSize: "1.25rem",
                fontWeight: "700",
                color: "#495057",
                paddingLeft: "20px",
              }}
              className="heading"
            >
              {updateSidebarData
                ? "Edit Notification Configuration"
                : "Add Notification Configuration"}
            </span>
            <button
              type="button"
              onClick={() => handleCancelForm()}
              ref={closeIconRef}
              className="p-2 rounded-full transition-colors"
              style={{ backgroundColor: "white", border: "0px", marginRight: "2vw" }}
              aria-label="Close"
            >
              <i className="pi pi-times text-[#6b7280] hover:text-[#212121] text-lg font-light"></i>
            </button>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1.5rem",
              padding: "1.5rem",
              backgroundColor: "white",
              borderRadius: "0.5rem",
              // marginTop:'4vh'
            }}
          >
            {/* Template Title */}

            {/* <div className="space-y-1">
              <label htmlFor="title" className="block text-sm font-normal text-title-black-light">
                Template Title
              </label>

              <InputText
                id="title"
                name="title"
                placeholder="Define Template Title"
                className={`w-full border rounded-md py-2 px-3 mt-2 focus:outline-none ${
                  formDataNotification.title === "" && isValidationEnable
                    ? "border-red-500"
                    : "border-gray-300"
                }`}
                value={formDataNotification.title}
                onChange={handleChangeNotification}
                style={{ height: "6vh", border: "1px solid #d8d4dc" }}
              />

              <div className="flex flex-col">
                {formDataNotification.title === "" && isValidationEnable && (
                  <p
                    className="text-red-500 text-xs"
                    style={{ marginTop: "2px", marginBottom: "2px" }}
                  >
                    Template Title field is required
                  </p>
                )}
              </div>

              <div className="text-left text-gray-500 text-sm mr-auto" style={{ marginTop: "2px" }}>
                Character limit : {formDataNotification.title?.length || 0}/60
              </div>
            </div> */}

            <div className="space-y-1">
              <label
                htmlFor="alert_message"
                className="block text-sm font-normal text-title-black-light"
              >
                Notification Message
              </label>
              <InputText
                id="alert_message"
                name="alert_message"
                className={`w-full border rounded-md py-2 px-3 mt-2 focus:outline-none ${
                  formDataNotification.alert_message === "" && isValidationEnable
                    ? "border-red-500"
                    : "border-gray-300"
                }`}
                value={formDataNotification.alert_message}
                onChange={handleChangeNotification}
                style={{ height: "6vh", border: "1px solid #d8d4dc" }}
              />
              <div className="flex flex-col">
                {formDataNotification.alert_message === "" && isValidationEnable && (
                  <p
                    className="text-red-500 text-xs"
                    style={{ marginTop: "2px", marginBottom: "2px" }}
                  >
                    Notification Message field is required
                  </p>
                )}
              </div>
              <div className="text-left text-gray-500 text-sm mr-auto" style={{ marginTop: "2px" }}>
                Character limit : {characterCount}/160
              </div>
            </div>

            {/* Notification Channel */}

            <div className="space-y-1">
              <label
                htmlFor="notification_channel"
                className="block text-sm font-normal text-title-black-light"
              >
                Notification Channel
              </label>

              <div className="flex flex-row gap-4 mt-2">
                <div className="flex align-items-center">
                  <Checkbox
                    inputId="pulse"
                    name="channel"
                    value="pulse"
                    onChange={handleChannelChange}
                    checked={
                      (formDataNotification?.notification_channels || []).includes("pulse") || true
                    }
                    disabled={true}
                  />

                  <label htmlFor="pulse" className="ml-2 text-sm text-gray-600">
                    Pulse
                  </label>
                </div>

                <div className="flex align-items-center">
                  <Tooltip target=".upload-target-icon" />
                  <Checkbox
                    inputId="email"
                    name="channel"
                    value="email"
                    onChange={handleChannelChange}
                    checked={(formDataNotification?.notification_channels || []).includes("email")}
                  />

                  <label htmlFor="email" className="ml-2 text-sm text-gray-600">
                    Email
                  </label>
                  <MdInfo
                    className="upload-target-icon text-gray-400 ml-2"
                    size={22}
                    data-pr-tooltip="Email addresses registered by users will be used to send notifications."
                    data-pr-position="top"
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>

              <div className="flex flex-col">
                {formDataNotification.notification_channels.length === 0 && isValidationEnable && (
                  <p
                    className="text-red-500 text-xs"
                    style={{ marginTop: "2px", marginBottom: "2px" }}
                  >
                    Please select at least one notification channel
                  </p>
                )}
              </div>
            </div>

            <div className="sidebar-tab flex justify-start items-start">
              <Timeline
                value={[
                  {
                    label: "L1 Respondent",
                    content: (
                      <EscalationLevel
                        level="primary"
                        formData={formDataNotification}
                        onTimeChange={handleTimeChange}
                        onUserChange={handleUserChange}
                        onDelete={() => {}}
                        showNextLevel={showSecondarySpoc}
                        onAddNextLevel={() => {
                          setShowSecondarySpoc(true);
                          setFormDataNotification((prev) => ({
                            ...prev,
                            detail_levels: [
                              ...prev.detail_levels,
                              { level: 2, users: [], escalation_time: 0 },
                            ],
                          }));
                        }}
                        allUserData={allUserData}
                        isValidationEnable={isValidationEnable}
                        convertDateTimeWithSeconds={convertDateTimeWithSeconds}
                        isPrimary={true}
                        phoneNumbers={formDataNotification.phone_numbers}
                        onPhoneNumberChange={handlePhoneNumberChange}
                      />
                    ),
                  },
                  showSecondarySpoc && {
                    label: "L2 Escalation",
                    content: (
                      <EscalationLevel
                        level="secondary"
                        formData={formDataNotification}
                        onTimeChange={handleTimeChange}
                        onUserChange={handleUserChange}
                        onDelete={handleDelete}
                        showNextLevel={showTertiarySpoc}
                        onAddNextLevel={() => {
                          setShowTertiarySpoc(true);
                          setFormDataNotification((prev) => ({
                            ...prev,
                            detail_levels: [
                              ...prev.detail_levels,
                              { level: 3, users: [], escalation_time: 0 },
                            ],
                          }));
                        }}
                        allUserData={allUserData}
                        isValidationEnable={isValidationEnable}
                        convertDateTimeWithSeconds={convertDateTimeWithSeconds}
                        phoneNumbers={formDataNotification.phone_numbers}
                        onPhoneNumberChange={handlePhoneNumberChange}
                      />
                    ),
                  },
                  showTertiarySpoc && {
                    label: "L3 Escalation",
                    content: (
                      <EscalationLevel
                        level="tertiary"
                        formData={formDataNotification}
                        onTimeChange={handleTimeChange}
                        onUserChange={handleUserChange}
                        onDelete={handleDelete}
                        showNextLevel={true}
                        onAddNextLevel={() => {}}
                        allUserData={allUserData}
                        isValidationEnable={isValidationEnable}
                        convertDateTimeWithSeconds={convertDateTimeWithSeconds}
                        phoneNumbers={formDataNotification.phone_numbers}
                        onPhoneNumberChange={handlePhoneNumberChange}
                      />
                    ),
                  },
                ].filter(Boolean)}
                align="left"
                content={(item) => item.content}
                className="flex justify-start items-stretch left-aligned"
                marker={(item) => (
                  <span
                    className={`w-2 h-2 border-2 rounded-full inline-block 
                     
                    `}
                  ></span>
                )}
              />
            </div>

            {/* Associate Rule (Opional) */}
            <div className="space-y-1">
              <label
                htmlFor="alert_rule_ids"
                className="block text-sm font-normal text-title-black-light"
              >
                Associated Rule (Optional)
              </label>
              <MultiSelect
                value={formDataNotification.alert_rule_ids}
                filter
                className={
                  "w-full border rounded-md h-12 placeholder:font-normal text-[#6C757D] opacity-60 border-gray-400"
                }
                options={allRuleData}
                placeholder="Select"
                selectedItemTemplate={() => null}
                onChange={(e) => handleSelectChange(e, "alert_rule_ids")}
                style={{ height: "6vh", marginTop: "1vh", border: "1px solid #d8d4dc" }}
              />
              <div className="mt-2 flex flex-wrap gap-2">
                {formDataNotification.alert_rule_ids?.length > 0 && (
                  <div className="flex flex-wrap gap-2 mt-2">
                    {formDataNotification.alert_rule_ids?.map((item, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "0.25rem",
                          padding: "0.25rem",
                          paddingLeft: "0.75rem",
                          paddingRight: "0.75rem",
                          border: "1px solid #E5E7EB",
                          backgroundColor: "#F3F4F6",
                          borderRadius: "1rem",
                          fontSize: "0.875rem",
                          fontWeight: "600",
                          color: "#1F2937",
                        }}
                      >
                        <span>{allRuleData?.find((u) => u.value === item)?.label}</span>
                        <button
                          type="button"
                          onClick={() => {
                            setFormDataNotification({
                              ...formDataNotification,
                              alert_rule_ids: formDataNotification.alert_rule_ids?.filter(
                                (spoc) => spoc !== item,
                              ),
                            });
                          }}
                          className="text-gray-600 hover:text-gray-700"
                          style={{
                            backgroundColor: "#F3F4F6",
                            border: "0px",
                            fontStyle: "normal",
                            fontWeight: "300",
                            cursor: "pointer",
                          }}
                        >
                          <i
                            style={{
                              fontSize: "0.7rem",
                              paddingTop: "0.25rem",
                            }}
                            className="pi pi-times"
                          ></i>{" "}
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            {/* Schedule Type Dropdown */}

            <ScheduleTypeDropdown
              formData={formDataNotification}
              onChange={handleScheduleChange}
              isValidationEnable={isValidationEnable}
            />

            {/* Select Knowledge Space */}
            <div className="space-y-1 mb-10" style={{ marginBottom: "5rem" }}>
              <label
                htmlFor="knowledge_space"
                className="block text-sm font-normal text-title-black-light"
              >
                Provide Context for Queries (Optional)
              </label>
              <MultiSelect
                value={formDataNotification.knowledge_space}
                filter
                selectedItemTemplate={() => null}
                className="w-full border rounded-md h-12 placeholder:font-normal text-[#6C757D] opacity-60 border-gray-400"
                options={allUserData?.knowledgeSpace}
                placeholder="Select"
                onChange={(e) => handleSelectChange(e, "knowledge_space")}
                style={{ height: "6vh", marginTop: "1vh" }}
              />
              {formDataNotification.knowledge_space.length > 0 && (
                <div className="flex flex-wrap gap-2 mt-2">
                  {formDataNotification.knowledge_space?.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "0.25rem",
                        padding: "0.25rem",
                        paddingLeft: "0.75rem",
                        paddingRight: "0.75rem",
                        border: "1px solid #E5E7EB",
                        backgroundColor: "#F3F4F6",
                        borderRadius: "1rem",
                        fontSize: "0.875rem",
                        fontWeight: "600",
                        color: "#1F2937",
                      }}
                    >
                      <span>
                        {allUserData?.knowledgeSpace.find((u) => u.value === item)?.label}
                      </span>
                      <button
                        type="button"
                        onClick={() => {
                          setFormDataNotification({
                            ...formDataNotification,
                            knowledge_space: formDataNotification.knowledge_space?.filter(
                              (spoc) => spoc !== item,
                            ),
                          });
                        }}
                        className="text-gray-600 hover:text-gray-700"
                        style={{
                          backgroundColor: "#F3F4F6",
                          border: "0px",
                          fontStyle: "normal",
                          fontWeight: "300",
                          cursor: "pointer",
                        }}
                      >
                        <i
                          style={{
                            fontSize: "0.7rem",
                            paddingTop: "0.25rem",
                          }}
                          className="pi pi-times"
                        ></i>
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>

            {/* Footer with Clear and Apply Buttons */}
            <div
              className="flex absolute bg-white bottom-0 left-0 ml-auto right-0 px-5 justify-end rule-notification-sidebar-footer"
              style={{ marginTop: "5vh", justifyContent: "end" }}
            >
              <div style={{ paddingTop: "1vh", paddingBottom: "0.5vh" }}>
                <Button
                  onClick={handleCancelForm}
                  className="h-12 px-3 mr-2 w-fit text-gray-500"
                  style={{ backgroundColor: "white", border: "0px" }}
                  label="Cancel"
                  type="button"
                />
                <Button
                  label={updateSidebarData ? "Update" : "Configure"}
                  className="border px-4 bg-blue-500 text-white"
                  onClick={handleSubmit}
                  loading={submitLoading}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    ></Sidebar>
  );
};

export default RuleNotificationSidebar;
