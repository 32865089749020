import axios from "axios";
import { useState, useRef } from "react";

function useUploaderApi() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const abortControllerRef = useRef(null);

  const uploadData = async (
    url,
    method = "PUT",
    body = null,
    headers = {},
    onUploadProgress = null
  ) => {
    abortControllerRef.current = new AbortController();

    try {
      setLoading(true);
      setError(null);

      // Remove any authorization headers
      const cleanHeaders = { ...headers };
      delete cleanHeaders.Authorization;
      delete cleanHeaders.authorization;

      cleanHeaders['Content-Type'] = 'application/octet-stream';

      const response = await fetch(url, {
        method,
        headers: cleanHeaders,
        // Send the raw binary data
        body: body instanceof File ? body : body,
        signal: abortControllerRef.current.signal,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Azure Blob Storage PUT returns no content
      setData({ status: response.status });
      return { status: response.status };
    } catch (err) {
      console.error('Upload failed:', err);
      setError(err);
      return Promise.reject(err);
    } finally {
      setLoading(false);
    }
  };

  const put = (url, body, headers, onUploadProgress) => 
    uploadData(url, "PUT", body, headers, onUploadProgress);

  const cancelUpload = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
  };

  return { data, loading, error, put, cancelUpload };
}

export default useUploaderApi; 