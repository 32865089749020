import React, { useState, useEffect, useRef, Fragment } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { FilterMatchMode } from "primereact/api";
import useTemplateList from "hooks/useTemplateList";
import { Chip } from "primereact/chip";
import "../TemplateSection/newTemplate.css";
import { TieredMenu } from "primereact/tieredmenu";
import { Toast } from "primereact/toast";
import { ConfirmDialog } from "primereact/confirmdialog";
import moment from "moment";
import NewTemplates from "./NewTemplate";
import PDFViewerComponent from "components/DocumentEngine/FileViewer/FileViewerComponent";


export default function TemplatePanels({
  currentTab,
  fetchData,
  setVisible,
  visible,
  setFetchData,
}) {
  const toast = useRef(null);
  const menu = useRef(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [templateTitle, setTemplateTitle] = useState("New Template");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    templateName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const { getMyTemplates, deleteTemplate } = useTemplateList();
  const [expandedRows, setExpandedRows] = useState({});
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [selectedTemplates, setSelectedTemplates] = useState(null);
  const [selectedPdfUrl, setSelectedPdfUrl] = useState(null);
  const [selectedFileName, setselectedFileName] = useState(null);

  const fetchTemplates = async () => {
    setLoading(true);
    try {
      const data = await getMyTemplates(currentTab === "sharedTemplate");
      if (data.success && data.results && data.results.Templates) {
        setData(data.results.Templates);
      }
    } catch (err) {
      console.error(err);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Please try again later",
        life: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTemplates();
  }, [currentTab, fetchData]);

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const closeOverlay = () => {
    setSelectedPdfUrl(null);
    setselectedFileName(null);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        {/* <Button
          type="button"
          icon="pi pi-filter"
          label={`${!isMobile ? "Filter" : ""}`}
          className={"p-button-filter mr-3 px-4 border-1 text-sm text-blue-500"}
          outlined
          iconPos="right"
        /> */}
        <IconField iconPosition="left">
          <InputIcon className="pi pi-search text-sm" />
          <InputText
            value={globalFilterValue}
            className="text-sm"
            onChange={onGlobalFilterChange}
            placeholder="Search Template"
          />
        </IconField>
      </div>
    );
  };

  const header = renderHeader();

  const accept = () => {
    deleteTemplate(selectedTemplate.id)
      .then((res) => {
        if (res) {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Template deleted successfully!",
            life: 3000,
          });
          setDeleteConfirm(false);
          setSelectedTemplate(null);
        }
      })
      .catch((err) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Please try again later",
          life: 3000,
        });
      })
      .finally(() => {
        setDeleteConfirm(false);
        setSelectedTemplate(null);
        fetchTemplates();
      });
  };

  const handleExpandClick = (rowIndex) => {
    setExpandedRows((prev) => ({
      ...prev,
      [rowIndex]: !prev[rowIndex],
    }));
  };

  const statusTemplate = (rowData) => {
    const { status } = rowData;

    return (
      <>
        <Chip
          icon={
            <i
              className={`${status === "in_progress" ? "pi pi-clock text-blue-500" : "pi pi-check-circle text-green-500"}  mr-2`}
            />
          }
          label={status === "in_progress" ? "In Process" : "Completed"}
          className="accessto font-bold"
        />
      </>
    );
  };

  const accessToTemplate = (rowData) => {
    const { accessTo, id } = rowData;
    const accessToLength = accessTo && accessTo.length > 0;
    const isExpanded = expandedRows[id];
    if (isExpanded && accessToLength) {
      return accessTo.map((user, index) => <Chip key={index} label={user} className="accessto" />);
    } else {
      return (
        <>
          <Chip label={accessToLength && accessTo[0]} className="accessto" />
          {accessToLength && accessTo.length > 1 && (
            <Chip
              label={`+${accessToLength && accessTo.length - 1}`}
              className="accessto cursor-pointer"
              onClick={() => handleExpandClick(id)}
            />
          )}
        </>
      );
    }
  };

  const actionTemplate = (rowData) => {
    const items = [
      {
        label: "Manage",
        icon: "pi pi-users",
        command: () => {
          setTemplateTitle("Manage Template");
          setVisible(true);
        },
      },
      {
        separator: true,
      },
      {
        label: "Delete",
        icon: "pi pi-trash",
        className: "p-delete-menuitem",
        command: () => {
          setDeleteConfirm(true);
        },
      },
    ];
    const previewIcon = (
      <Button
        type="button"
        icon={"pi pi-eye"}
        style={{ color: "#3B82F6" }}
        onClick={() => {
          setSelectedPdfUrl(rowData?.template_file_url);
          setselectedFileName(rowData?.templateName);
        }}
        className="p-button-rounded p-button-text p-button-secondary  w-2rem h-2rem"
      />
    );

    const moreMenu = (
      <div className="card flex justify-content-center">
        <TieredMenu model={items} popup ref={menu} breakpoint="767px" className="max-w-10rem" />
        <Button
          type="button"
          icon={"pi pi-ellipsis-v"}
          onClick={(e) => {
            menu.current.toggle(e);
            setTimeout(() => {
              //remove default focus from first menu item
              menu?.current
                ?.getElement()
                ?.children?.[0]?.children?.[0]?.classList?.remove("p-focus");
            }, 100);
            setSelectedTemplate(rowData);
          }}
          className="p-button-rounded p-button-text p-button-secondary w-2rem h-2rem"
        />
      </div>
    );

    return (
      <div className="text-right inline-flex">
        {previewIcon} {currentTab === "myTemplate" && moreMenu}
      </div>
    );
  };

  const createdAtTemplate = (rowData) => {
    const { createdBy, createdAt } = rowData;
    const timestamp = moment(createdAt).format("DD/MM/YYYY, H:mm:ss");

    const template = (
      <div className="flex flex-column">
        <span className="text-sm">{createdBy}</span>
        <span className="text-xs text-color-secondary mt-1">{timestamp}</span>
      </div>
    );
    return template;
  };

  return (
    <Fragment>
      <Toast ref={toast} />
      <div className="card my-5 templateTable" style={{ height: "60vh" }}>
        <DataTable
          value={data}
          // paginator
          // rowsPerPageOptions={[5, 10, 20]}
          // rows={10}
          // paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          // currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          loading={loading}
          tableStyle={{ minWidth: "50rem" }}
          filters={filters}
          globalFilterFields={["templateName"]}
          header={header}
          emptyMessage="No template found"
          onFilter={(e) => setFilters(e.filters)}
          className="w-full"
          scrollable
          scrollHeight="flex"
          selection={selectedTemplates}
          onSelectionChange={(e) => setSelectedTemplates(e.value)}
          dataKey="id"
          selectionMode={"checkbox"}
        >
          {/* <Column field="id" selectionMode="multiple" style={{ width: "5%" }}></Column> */}
          <Column
            field="templateName"
            header="Template Name"
            sortable
            style={{ width: "25%" }}
          ></Column>
          <Column
            field="status"
            header="Status"
            sortable
            style={{ width: "15%" }}
            body={statusTemplate}
          ></Column>
          <Column
            field="description"
            header="Description"
            sortable
            style={{ width: "27.5%" }}
          ></Column>
          <Column
            field="accessTo"
            header="Access to"
            style={{ width: "20%" }}
            body={accessToTemplate}
          ></Column>
          <Column
            field="createdAt"
            header="Created at"
            sortable
            style={{ width: "20%" }}
            body={createdAtTemplate}
          ></Column>
          <Column
            field="actions"
            header="Actions"
            style={{ width: "5%" }}
            className="w-full"
            body={(rowData) => actionTemplate(rowData)}
          ></Column>
        </DataTable>
        {selectedPdfUrl && (
          <PDFViewerComponent
            fileUrl={selectedPdfUrl}
            fileName={selectedFileName}
            onClose={closeOverlay}
          />
        )}
      </div>
      <ConfirmDialog
        group="declarative"
        visible={deleteConfirm}
        onHide={() => setDeleteConfirm(false)}
        message="Are you sure that you want delete the selected items?"
        header="Confirmation"
        icon="pi pi-exclamation-triangle"
        accept={accept}
        acceptIcon={"pi pi-check"}
        rejectIcon={"pi pi-times"}
        reject={() => {
          setDeleteConfirm(false);
          setSelectedTemplate(null);
        }}
        acceptClassName="p-accept-button"
        rejectClassName="p-reject-button"
        style={{ width: "50vw" }}
        breakpoints={{ "1100px": "75vw", "960px": "100vw" }}
      />
      <NewTemplates
        visible={visible}
        setVisible={setVisible}
        fetchData={fetchData}
        setFetchData={setFetchData}
        selectedTemplate={selectedTemplate}
        templateTitle={templateTitle}
        setSelectedTemplate={setSelectedTemplate}
        setTemplateTitle={setTemplateTitle}
      />
    </Fragment>
  );
}
