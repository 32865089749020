import React from "react";
import PropTypes from "prop-types";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import DeleteExclamationWarningIcon from "../icons/DeleteExclamationWarningIcon";

const SourceDeleteModal = ({ source, onClose, visible, onAccept }) => {
  console.log(" source is ", source);
  return (
    <Dialog
      header="Confirmation"
      visible={visible}
      draggable={false}
      style={{ width: "30vw" }}
      onHide={onClose}
      onAccept={onAccept}
    >
      <div className="flex flex-row w-full max-md:max-w-full">
        <DeleteExclamationWarningIcon />
        <span className="ml-4">
          {" "}
          Are you sure that you want to delete this source?
        </span>
      </div>
      <div className="flex flex-row mr-5 mt-5" style={{justifyContent:'end'}}>
        <Button
          label="No"
          type="button"
          style={{
            marginTop: '1.25rem',
            backgroundColor: 'white',
            padding: '0.75rem',
            fontSize: '0.75rem',
            color: '#EF4444',
            marginRight: '1.25rem',
            border: '0px'
          }}
          onClick={onClose}
          icon={<i className="pi pi-times mr-2" />}
        />
        <Button
          label="Yes"
          type="button"
          style={{
            marginTop: '1.25rem',
            backgroundColor: '#EF4444',
            padding: '0.75rem',
            fontSize: '0.75rem',
            color: 'white',
            border: '0px'
          }}
          onClick={onAccept}
          icon={<i className="pi pi-check mr-2" />}
        />
      </div>
    </Dialog>
  );
};

SourceDeleteModal.propTypes = {
  source: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

export default SourceDeleteModal;
