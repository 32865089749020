import React, { useCallback, useEffect, useMemo } from "react";
import "./WorkspaceStyle.css";
import Filter from "pages/SDAI/Workspaces/Filter";
import { useState } from "react";
import { Chip } from "primereact/chip";
import { capitalizeFirstLetter, getTimeFromDate, getFormattedDate } from "../../../utils/utils";
import useMediaQuerry from "../../../hooks/useMediaQuerry";
import { ListBox } from "primereact/listbox";
import { parse } from "date-fns";
import TaskProgressBar from "./TaskProgressBar";
import PDFViewerComponent from "components/DocumentEngine/FileViewer/FileViewerComponent";
import moment from "moment";
import Pagination from "components/Pagination/Pagination";
import { useSearchParams } from "react-router-dom";

const TaskTab = ({
  taskList,
  setTaskList,
  taskCount,
  outputType,
  setSelectedTask,
  selectedTask,
  setTaskCount,
  taskUrl,
  pageNo,
  setPageNo,
  downloadUrl,
  onPdfViewerStateChange,
  handlePageChange,
  handleFilterApply,
  currentFilters,
}) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const { isMobile } = useMediaQuerry();

  const initialFilters = {
    dateRange: [],
    job_id: "",
    output_format: "",
    status: "",
  };

  const [filters, setFilters] = useState(initialFilters);
  const [appliedFilters, setAppliedFilters] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);

  useEffect(() => {
    if (currentFilters) {
      setFilters({
        dateRange: currentFilters.dateRange || [],
        jobId: currentFilters.job_id || "",
        outputFormat: currentFilters.output_format || "",
        status: currentFilters.status || "",
      });
    }
  }, [currentFilters]);

  const onFilterApply = (filters) => {
    setAppliedFilters(filters);
    const filtersList = {
      date_gt: filters.date_gt || "",
      date_lt: filters.date_lt || "",
      job_id: filters.jobId || "",
      output_format: filters?.outputFormat || "",
      status: filters.status || "",
    };
    handleFilterApply(filtersList);
  };

  const previewFile = () => {
    if (!taskUrl) {
      return;
    }

    setFileUrl(taskUrl);
    onPdfViewerStateChange?.(true);
  };

  const handleDownload = async () => {
    const fileUrl = downloadUrl;

    try {
      const response = await fetch(fileUrl);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const blob = await response.blob();
      const formattedDate = moment().format("MMDDYYYY");
      const formattedTime = moment().format("HH_mm");
      const fileName = `${formattedDate}_${formattedTime}_Template Name.docx`;
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const closeOverlay = () => {
    setFileUrl(null);
    onPdfViewerStateChange?.(false);
  };

  const resetFilters = useCallback(() => {
    if (appliedFilters) {
      const resetFilters = {
        dateRange:
          appliedFilters.date_gt || appliedFilters.date_lt
            ? [
              parse(appliedFilters.date_gt, "yyyy-MM-dd", new Date()),
              parse(appliedFilters.date_lt, "yyyy-MM-dd", new Date()),
            ]
            : [],
        jobId: appliedFilters.jobId || "",
        outputFormat: appliedFilters?.outputFormat || null,
        status: appliedFilters.status || null,
      };
      setFilters(resetFilters);
    }
  }, [appliedFilters]);

  useEffect(() => {
    if (!isFilterOpen) {
      resetFilters();
    }
  }, [isFilterOpen, resetFilters]);

  const taskTemplate = (task) => {
    return (
      <div
        onClick={() => setSelectedTask(task)}
        className={` px-2 py-3 cursor-pointer  ${selectedTask.id === task.id ? "selectedTask" : "task-container surface-0"}`}
      >
        <div className="flex justify-content-between mb-3">
          <div className="inline-flex align-items-center">
            <span
              className={`block text-700 text-sm ${selectedTask.id === task.id ? "font-semibold" : "font-normal"}  mr-2`}
            >
              ID {task.id}
            </span>
            <Chip
              label={task?.status && capitalizeFirstLetter(task?.status)}
              icon={`pi ${task?.status?.toLowerCase() === "ongoing" ? "pi-clock text-blue-500" : task?.status?.toLowerCase() === "completed" ? "pi-check-circle text-green-500" : task?.status?.toLowerCase() === "error" ? "pi-exclamation-circle text-red-500" : task?.status?.toLowerCase() === "terminated" ? "pi-times-circle text-red-500" : ""}`}
              className={`text-xs  font-semibold`}
            />
          </div>
          <div className="flex align-items-center justify-content-center text-xs text-400">
            {task.status?.toLowerCase() === "ongoing"
              ? moment(task.created_at).isSame(moment(), "day")
                ? moment(task.created_at).format("HH:mm")
                : moment.utc(task.created_at).format("MM/DD/YYYY")
              : moment.utc(task.updated_at).format("MM/DD/YYYY")}
          </div>
        </div>
        <div className={`text-xs ${selectedTask.id === task.id ? "inline-flex gap-2 w-full" : ""}`}>
          {task.status?.toLowerCase() === "ongoing" ? (
            <div className="text-500 mt-2 w-full inline-flex gap-3">
              Stages
              <TaskProgressBar
                task={task}
                style={{ height: "6px" }}
                className="w-4 mt-1"
              ></TaskProgressBar>
              {task.progress}/5 completed{" "}
            </div>
          ) : task.status?.toLowerCase() === "completed" ? (
            <span className="text-500 w-8">Task Completed</span>
          ) : task.status?.toLowerCase() === "error" ? (
            <span className="text-500 w-8">Unreadable Pages Detected</span>
          ) : task.status?.toLowerCase() === "terminated" ? (
            <span className="text-500 w-8">Task Terminated</span>
          ) : (
            <span className="text-500">Failed to get status</span>
          )}
          {selectedTask.id === task.id && task.status?.toLowerCase() === "completed" && (
            <span className="ml-3 inline-flex gap-3">
              <i
                onClick={() => previewFile()}
                className="pi pi-eye text-blue-500"
                style={{ fontSize: "1rem" }}
              ></i>
              <i
                onClick={() => handleDownload()}
                className="pi pi-download text-blue-500"
                style={{ fontSize: "1rem" }}
              ></i>
            </span>
          )}
        </div>
      </div>
    );
  };

  return (
    <section
      className={`surface-0 h-screen py-3  ${!isMobile && "border-round-top-left-12"}  taskTabListing`}
    >
      {fileUrl && (
        <PDFViewerComponent fileUrl={fileUrl} fileName="file.docx" onClose={() => closeOverlay()} />
      )}
      <div className="inline-flex align-items-center justify-content-between w-full mb-3 px-3">
        <h3 className="text-xl my-0">Tasks</h3>
        <h4
          className="text-500 my-0 font-semibold cursor-pointer"
          onClick={() => setIsFilterOpen(true)}
        >
          <i className="pi pi-sliders-h" /> Filter
        </h4>
      </div>
      {isFilterOpen ? (
        <Filter
          handleFilterApply={onFilterApply}
          onCloseFilters={() => {
            setIsFilterOpen(false);
          }}
          selectFormatOptions={outputType}
          filters={filters}
          setFilters={setFilters}
          initialFilters={initialFilters}
        />
      ) : (
        <>
          <ListBox
            options={taskList}
            optionLabel="name"
            itemTemplate={taskTemplate}
            emptyMessage="No jobs available"
            listStyle={{ maxHeight: isMobile ? "73vh" : "80vh", borderWidth: 0 }}
            pt={{
              item: "p-0 mx-3",
              list: "border-0",
              root: "border-0",
            }}
          />
          {taskList.length !== 0 && (
            <Pagination
              currentPage={pageNo}
              totalRecords={taskCount}
              onPageChange={handlePageChange}
            />
          )}
        </>
      )}
    </section>
  );
};

export default TaskTab;
